import {useEffect} from 'react';
import {ProjectAdminVM} from '@models/serverModels';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import AcademyProjectParticipantsDrawerWithRoutes from './AcademyProjectParticipantsDrawerWithRoutes.container';
import CustomProgramProjectsTable from '@components/admin/pages/programs/customPrograms/projects/CustomProgramProjectsTable';
import {useResetAcademyProjectCount} from '@hooks/apiEndpoints/permission/mutations';
import {i18n, k} from '@i18n/translate';
import {updatePageTitle} from '@hooks/useSetPageTitle';
import {useManagedAcademiesQueryCached} from '@generated/hooks/permission.get.hooks';
import {useProjectsQueryCached} from '@generated/hooks/academy.get.hooks';
import {sortProjectByOrder} from '@utils/sort';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function AcademiesProjectsTableWithRoutesContainer({
  basePath,
  title,
}: {
  basePath: string;
  title?: string;
}) {
  const {academyId} = useParams();

  const {data: projects} = useProjectsQueryCached(
    {academyId},
    {select: (projects) => projects?.sort(sortProjectByOrder) || []}
  );

  const {invalidateExact: invalidateAcademyPermissions} =
    useManagedAcademiesQueryCached(null);

  const {mutate: resetAcademyProjectCount} = useResetAcademyProjectCount({
    onSuccess: invalidateAcademyPermissions,
  });

  useEffect(() => {
    updatePageTitle(title);
    resetAcademyProjectCount({payload: {academyId: parseInt(academyId)}});
  }, [academyId, resetAcademyProjectCount, title]);

  const navigate = useNavigate();

  const handleClickRow = (project: ProjectAdminVM) => {
    navigate([project?.id, 'submissions'].join('/'));
  };

  const handleClose = () => navigate(basePath);

  return (
    <>
      <div style={{marginTop: '16px'}}>
        <CustomProgramProjectsTable
          dataSource={projects}
          onClickRow={handleClickRow}
        />
      </div>
      <Routes>
        <Route
          path=":projectId/submissions/*"
          element={
            <AcademyProjectParticipantsDrawerWithRoutes
              title={i18n.t(k.SUBMISSION__PLURAL)}
              onClose={handleClose}
            />
          }
        />
      </Routes>
    </>
  );
}

export default AcademiesProjectsTableWithRoutesContainer;
