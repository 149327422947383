import {
  useAdminApprovalsUpdateUserRequestApprovalMutation,
  useManagerApprovalsUserRequestApprovalsLegacyQuery,
  useAdminApprovalsUserRequestApprovalsLegacyQuery,
  useManagerApprovalsUpdateUserRequestApprovalMutation,
} from '@generated/hooks';
import {ApprovalsDiscriminator, ApprovalStatus} from '@generated/enums';
import {UpdateApprovalRequest} from '@generated/interfaces';
import {notify} from '@components/user/notifications';

const useApprovals = (
  role: 'admin' | 'manager',
  options?: {
    onSuccessHandleApprovalRequest?: () => void;
    onErrorHandleApprovalRequest?: () => void;
  }
) => {
  /*
  |-----------------------------------------------------------
  |  Queries
  |-----------------------------------------------------------
  */

  const queryHook =
    role === 'admin'
      ? useAdminApprovalsUserRequestApprovalsLegacyQuery
      : useManagerApprovalsUserRequestApprovalsLegacyQuery;

  const {data: requestedProgramsData, refetch: refetchRequestedPrograms} =
    queryHook(
      {
        queryParams: {
          status: ApprovalStatus.Requested,
          type: ApprovalsDiscriminator.Program,
        },
      },
      {
        enabled: !!role,
      }
    );

  // Licenses
  const {data: requestedLicensesData, refetch: refetchRequestedLicenses} =
    queryHook(
      {
        queryParams: {
          status: ApprovalStatus.Requested,
          type: ApprovalsDiscriminator.License,
        },
      },
      {
        enabled: !!role,
      }
    );

  // Learning Resources
  const {data: requestedResourcesData, refetch: refetchRequestedResources} =
    queryHook(
      {
        queryParams: {
          status: ApprovalStatus.Requested,
          type: ApprovalsDiscriminator.LearningResource,
        },
      },
      {
        enabled: !!role,
      }
    );

  /*
  |-----------------------------------------------------------
  | Calculated Data
  |-----------------------------------------------------------
  */

  const allPendingApprovalsCount =
    (requestedProgramsData?.length || 0) +
    (requestedLicensesData?.length || 0) +
    (requestedResourcesData?.length || 0);

  const pendingProgramsTabCount =
    (requestedProgramsData?.length || 0) + (requestedLicensesData?.length || 0);

  const pendingResourcesTabCount = requestedResourcesData?.length || 0;

  /*
  |-----------------------------------------------------------
  | Handlers
  |-----------------------------------------------------------
  */

  const onRefetch = () => {
    refetchRequestedPrograms();
    refetchRequestedLicenses();
    refetchRequestedResources();
  };

  /*
  |-----------------------------------------------------------
  | Mutations
  |-----------------------------------------------------------
  */

  const mutationHook =
    role === 'admin'
      ? useAdminApprovalsUpdateUserRequestApprovalMutation
      : useManagerApprovalsUpdateUserRequestApprovalMutation;

  const approvalRequestMutation = mutationHook({
    onSuccess: async () => {
      options?.onSuccessHandleApprovalRequest?.();
      onRefetch();
    },
    onError: () => {
      options?.onErrorHandleApprovalRequest?.();
      notify.approvalUpdateError();
    },
  });

  const handleApprovalRequest = async (payload: UpdateApprovalRequest) => {
    // Update approval request
    const res = await approvalRequestMutation.mutateAsync({payload});
    if (!res) {
      return;
    }

    // UI Notification
    switch (payload.status) {
      case ApprovalStatus.Approved:
        notify.approveSuccessful();
        break;
      case ApprovalStatus.Rejected:
        notify.rejectSuccessful();
        break;
      default:
        break;
    }
  };

  return {
    allPendingApprovalsCount,
    pendingProgramsTabCount,
    pendingResourcesTabCount,
    handleApprovalRequest,
    onRefetch,
    isLoadingMutation: approvalRequestMutation.isLoading,
  };
};

export default useApprovals;
