import {DoubleLineOption} from '@components/reusable/Select/OptionItem.style';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {CompanyMembersForAdding} from './types';
import {getMemberCountText} from './utils';

const StyledEmployeeOption = styled.div`
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  padding: 10px;
`;

interface MemberOptionProps {
  data: CompanyMembersForAdding;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getStyles: Function;
  isFocused: boolean;
  innerProps: any;
}

export const MemberSelectOption = (props: MemberOptionProps) => {
  const {
    email,
    label: name,
    canShowEmployeeEmail,
    value,
    memberCount,
    privacyLevel,
    isGroup,
  } = props.data;

  return (
    <StyledEmployeeOption
      style={{
        ...props.getStyles('option', props),
        backgroundColor: props.isFocused ? COLORS.Neutral200 : COLORS.White, // Highlight the focused option
      }}
      tabIndex={0}
      name={`${name} - ${email}`}
      value={`${name} - ${email}`}
      {...props.innerProps}
      key={value}>
      {canShowEmployeeEmail && !isGroup ? (
        <DoubleLineOption>
          {name}
          <span>{email}</span>
        </DoubleLineOption>
      ) : memberCount ? (
        <DoubleLineOption>
          {name}
          <span>{`${privacyLevel}: ${memberCount} ${getMemberCountText(memberCount)}`}</span>
        </DoubleLineOption>
      ) : (
        name
      )}
    </StyledEmployeeOption>
  );
};
