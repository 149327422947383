// Do not modify directly
// Generated from file: learnin.core.api/Controllers/UserCompanyController/Responses/GroupSearchResultVM.cs

import {z} from 'zod';

export const GroupSearchResultVMSchema = z.object({
  id: z.number(),
  memberCount: z.number().nullable().optional(),
  name: z.string(),
  privacyLevel: z.string(),
});
