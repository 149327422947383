// Do not modify directly
// Generated from file: learnin.core.api/Controllers/UserCompanyController/Responses/GetUsersOrGroupsResponse.cs

import {z} from 'zod';
import {GroupSearchResultVMSchema} from './GroupSearchResultVMSchema.schema';
import {UserSearchResultVMSchema} from './UserSearchResultVMSchema.schema';

export const GetUsersOrGroupsResponseSchema = z.object({
  groups: z.array(GroupSearchResultVMSchema),
  users: z.array(UserSearchResultVMSchema),
});
