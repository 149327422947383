import {MouseEvent, useEffect} from 'react';
import {i18n, k} from '@i18n/translate';
import styled, {css} from 'styled-components';
import {getPlaceholderImgSrc, resizeIfCloudinary} from '@utils/image-utils';
import ContextMenuButton from '@blocks/ContextMenu';
import {ProviderLogo} from '@blocks/ProviderLogo';
import {MenuOutlined} from '@ant-design/icons';
import {useDragAndDrop} from '@hooks/useDragAndDrop';
import {COLORS} from '@utils/constants';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {AcademyStepType, CurrencyCode, LxpContentType} from '@generated/enums';
import {
  CalendarOutlined,
  ExperimentOutlined,
  MessageOutlined,
  PaperClipOutlined,
} from '@ant-design/icons/lib/icons';
import {DATE_FORMAT, parseToLocalMoment} from '@utils/timeUtils';
import {ProgramDetailsVM} from '@models/serverModels';
import {BodySpan} from '@components/reusable/Typography';
import {localizeDuration, localizeGeneric} from '@utils/l10nUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {getLicenseCost} from '@utils/licenseUtils';
import {AcademyStepVM} from '@generated/interfaces';
import {useGetLabelsByAcademyStepType} from '@hooks/useGetLabelsByAcademyStepType';
import {getDefaultContentImage} from '@components/reusable/ItemSearchCard/LxpContentCard/LxpContentCard.utils';

const ContentIcons: Record<AcademyStepType, any> = {
  [AcademyStepType.Discussion]: MessageOutlined,
  [AcademyStepType.EventContent]: CalendarOutlined,
  [AcademyStepType.ProjectContent]: ExperimentOutlined,
  [AcademyStepType.TextContent]: PaperClipOutlined,
  [AcademyStepType.MarketplaceProgram]: null,
  [AcademyStepType.CustomProgram]: null,
  [AcademyStepType.LxpContent]: null,
};

const ContentIconColors: Record<AcademyStepType, any> = {
  [AcademyStepType.Discussion]: {
    color: COLORS.Purple150,
    background: COLORS.Purple350,
  },
  [AcademyStepType.EventContent]: {
    color: COLORS.Blue800,
    background: COLORS.Blue50,
  },
  [AcademyStepType.ProjectContent]: {
    color: COLORS.Red500,
    background: COLORS.Red200,
  },
  [AcademyStepType.TextContent]: {
    color: COLORS.Green600,
    background: COLORS.Green200,
  },
  [AcademyStepType.MarketplaceProgram]: null,
  [AcademyStepType.CustomProgram]: null,
  [AcademyStepType.LxpContent]: {
    color: COLORS.Neutral50,
    background: COLORS.Blue50,
  },
};

export const ProgramStepContentContainer = styled.div`
  display: flex;
  justify-items: flex-start;
  flex: 2;
`;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

interface ProgramCardProps {
  smallImageUrl?: string;
  id: number;
  providerLogoUrl: string;
  providerName: string;
  title: string;
  description: string;
  type: AcademyStepType;
  duration: string;
  programType: string;
  metadata: string;
  usersCurrency: CurrencyCode;
  exchangeRate: number;
  step: AcademyStepVM;
  contentUploadPercent?: number;
}
function ProgramCard({
  smallImageUrl,
  id,
  providerLogoUrl,
  providerName,
  title,
  description,
  type,
  duration,
  programType,
  metadata,
  usersCurrency,
  exchangeRate,
  step,
  contentUploadPercent,
}: ProgramCardProps) {
  const {
    isFeatureFlagOn: {DurationStrings},
  } = useFeatureFlags();
  return (
    <ProgramStepContentContainer>
      <ProgramImageContainer>
        <ProgramImage
          aria-hidden="true"
          src={
            !!smallImageUrl
              ? resizeIfCloudinary(smallImageUrl, null, 307)
              : getPlaceholderImgSrc(id)
          }
        />
      </ProgramImageContainer>
      <ContentContainer>
        <CardHeader>
          <ProviderLogo
            border={false}
            providerLogoSrc={providerLogoUrl}
            size={77}
          />
          <CardSubheader>
            <div>
              <Subtitle>{providerName}</Subtitle>
              <Title id="step-card-title">{title || description}</Title>
            </div>
            {type !== AcademyStepType.CustomProgram && (
              <div>
                <Subtitle>{i18n.t(k.MONEY__TOTAL_COST)}</Subtitle>
                <Title>
                  {getLicenseCost(step, usersCurrency, exchangeRate)}
                </Title>
              </div>
            )}
          </CardSubheader>
        </CardHeader>
        <DescriptionTitle>
          {localizeGeneric(programType)} {programType && duration && '|'}{' '}
          {localizeDuration(duration, DurationStrings)}
        </DescriptionTitle>
        <DescriptionWrapper>
          <Description>
            {title ? (
              description
            ) : (
              <i style={{color: COLORS.Neutral600}}>
                {i18n.t(k.GENERIC__DESCRIPTION__NONE)}
              </i>
            )}
          </Description>
        </DescriptionWrapper>
        {!!metadata?.length && (
          <ProgramMetaData style={{marginTop: '12px'}}>
            {metadata}
          </ProgramMetaData>
        )}
      </ContentContainer>
      {/* The upload progress doesn't refresh by itself, so the upload percent is omitted since it gets stale quickly */}
      {contentUploadPercent !== undefined && (
        <div>{i18n.t(k.FILE__UPLOAD__IN_PROGRESS)}</div>
      )}
    </ProgramStepContentContainer>
  );
}
const Container = styled.div<{
  isDraggingContentHigher: boolean;
  isDraggingContentLower: boolean;
  isOver: boolean;
}>`
  width: 100%;
  max-width: 955px;
  /* negative margin to offset the step card max width.
   * I wanted the card to line up with the controls at the top
  */
  margin-left: -55px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  .draggable-indicator {
    display: none;
  }
  &:hover,
  &:focus {
    .draggable-indicator {
      display: inline-block;
    }
    .order-number-display {
      display: none;
    }
  }
  ${({isOver, isDraggingContentHigher, isDraggingContentLower}) => {
    if (isOver) {
      if (isDraggingContentHigher) {
        return css`
          border-top: 4px solid ${COLORS.Blue200};
        `;
      }
      if (isDraggingContentLower) {
        return css`
          border-bottom: 4px solid ${COLORS.Blue200};
        `;
      }
    }
  }}
`;

const IndexRule = styled.div`
  border-left: 1px solid ${COLORS.Neutral200};
  width: 1px;
  height: 100%;
  position: absolute;
  left: 15px;
  z-index: 10;
`;

const IndexRuleTop = styled(IndexRule)`
  height: 0;
`;

const IndexRuleBottom = styled(IndexRule)`
  top: 20px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 1rem;
  color: ${COLORS.Neutral950};
  line-height: 1.5rem;
`;

const INDEX_SIZE = 16;
const Index = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.Neutral200};
  border-radius: 100%;
  color: ${COLORS.Neutral600};
  font-weight: 500;
  font-size: 1rem;
  margin: 20px 16px 0 8px;
  width: ${INDEX_SIZE}px;
  height: ${INDEX_SIZE}px;
  background: ${COLORS.Neutral200};
  z-index: 20;
  padding: 7px;
  cursor: grab;
`;

const CardContainer = styled.button<{
  first: boolean;
  last: boolean;
  isDragging: boolean;
  isStepReorderLoading: boolean;
}>`
  background: ${COLORS.White};
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  text-align: left;
  width: 100% !important;
  cursor: grab;
  transition: 0.3s opacity;

  &:hover,
  &:focus {
    background: ${COLORS.Neutral50};
  }

  margin-bottom: 25px;
  box-shadow: ${COLORS.BoxShadowStandard};
  border-radius: 10px;

  ${({isDragging, isStepReorderLoading}) => {
    if (isDragging || isStepReorderLoading) {
      return css`
        opacity: 0.35;
      `;
    }
  }}
`;

const Subtitle = styled.h5`
  color: ${COLORS.Neutral600};
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
`;
const DescriptionTitle = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${COLORS.Neutral950};
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${COLORS.Neutral950};
`;

const DescriptionWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CustomContentCardContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 24px;
`;

const ContentContainer = styled.div`
  padding: 24px;
  width: 100%;
`;

const CardHeader = styled.div`
  align-items: center;
  column-gap: 12px;
  display: flex;
  margin: 0 2rem 2rem 0;
`;

const CardSubheader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
`;

const ProgramImageContainer = styled.div`
  min-width: 210px;
  position: relative;
  width: 210px;
`;

const ProgramImage = styled.img`
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
`;

const ContentMetaData = styled(BodySpan)`
  color: ${COLORS.Neutral900};
`;

const ProgramMetaData = styled(ContentMetaData)`
  display: block;
  margin-top: 16px;
`;

const ProgramsIconContainer = styled.div<{
  color: string;
  background: string;
}>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 16px;
  background-color: ${({background}) => background};
  color: ${({color}) => color};
  & :first-child {
    margin: 3px;
    font-size: 1.1875rem;
  }
`;
/*
|--------------------------------------------------------------------------
| Card Component
|--------------------------------------------------------------------------
*/

interface StepCardProps {
  id: number;
  idx: number;
  step: AcademyStepVM & Omit<ProgramDetailsVM, 'currency'>;
  cardCount: number;
  title: string;
  smallImageUrl: string;
  order: number;
  onClickEdit: (e: MouseEvent) => void;
  onClickDuplicate: (e: MouseEvent) => void;
  onClickAddContentAbove?: (e: MouseEvent) => void;
  onClickAddContentBelow?: (e: MouseEvent) => void;
  onClickMoveToTop: (e: MouseEvent) => void;
  onClickMoveToBottom: (e: MouseEvent) => void;
  onClickDelete: (e: MouseEvent) => void;
  onClickMoveToLevel: (e: MouseEvent) => void;
  onClickEditProgram: (step: AcademyStepVM) => void;
  setStepReorderIdx: () => void;
  reorderStepItem: () => void;
  isDraggingStepOrder: number | null;
  setIsDraggingStepOrder: () => void;
  isStepReorderLoading: boolean;
  onClick: () => void;
  contentUploadPercent?: number | undefined;
  providerLogoUrl: string;
  providerName: string;
  description: string;
  cost: number;
  programType: string;
  duration: string;
  type: AcademyStepType;
  date?: string;
  eventTimeZone?: string;
  exchangeRate: number;
  blockingTitle?: string;
  onClickUpdateRequirement: () => void;
  showRequirementContextMenuOption: boolean;
  usersCurrency: CurrencyCode;
  onClickToggleAllowSkip: () => void;
  lxpContentType?: LxpContentType;
}

function StepCard({
  blockingTitle,
  cardCount = 1,
  contentUploadPercent,
  date,
  description,
  duration,
  eventTimeZone,
  exchangeRate,
  id,
  idx = 0,
  isDraggingStepOrder,
  isStepReorderLoading,
  onClick,
  onClickDelete,
  onClickDuplicate,
  onClickEdit,
  onClickEditProgram,
  onClickMoveToBottom,
  onClickMoveToLevel,
  onClickMoveToTop,
  onClickUpdateRequirement,
  order,
  programType,
  providerLogoUrl,
  providerName,
  reorderStepItem,
  setIsDraggingStepOrder,
  setStepReorderIdx,
  showRequirementContextMenuOption,
  smallImageUrl,
  step,
  title,
  type,
  usersCurrency,
  lxpContentType,
  onClickToggleAllowSkip,
}: StepCardProps) {
  const ContentIcon = ({
    contentType,
    lxpContentType,
  }: {
    contentType: AcademyStepType;
    lxpContentType: LxpContentType;
  }) => {
    let Icon = ContentIcons[contentType];
    const IconColors = ContentIconColors[contentType];

    if (contentType === AcademyStepType.LxpContent) {
      const previewImage = getDefaultContentImage(lxpContentType);
      // eslint-disable-next-line react/display-name
      Icon = () => (
        <img
          src={previewImage.icon}
          style={{height: 14, width: 14}}
          role="presentation"
          alt=""
        />
      );
    }

    if (!Icon) return null;
    return (
      <ProgramsIconContainer {...IconColors}>
        <Icon />
      </ProgramsIconContainer>
    );
  };
  const getEventTimeDisplay = ({
    startDateUtc,
    timeZone,
  }: {
    startDateUtc: string;
    timeZone: string;
  }): string | null => {
    const startTime = !!startDateUtc
      ? parseToLocalMoment(startDateUtc)
          ?.zone(timeZone)
          ?.format(DATE_FORMAT.TIME_12_HR)
      : null;

    const date = !!startDateUtc
      ? parseToLocalMoment(startDateUtc)
          ?.zone(timeZone)
          ?.format(DATE_FORMAT.MONTH_LONG_DAY_YEAR)
      : null;

    if (!date || !startTime) {
      return undefined;
    }
    return `${date} at ${startTime}`;
  };

  const eventTimeDisplay =
    type === AcademyStepType.EventContent
      ? getEventTimeDisplay({
          startDateUtc: date,
          timeZone: eventTimeZone,
        })
      : undefined;

  const requirementDisplay = !!blockingTitle && `Required: ${blockingTitle}`;
  const metadata = [eventTimeDisplay, requirementDisplay]
    .filter(Boolean)
    .join('•');

  const showProgramCard = [
    AcademyStepType.MarketplaceProgram,
    AcademyStepType.CustomProgram,
  ].includes(step.type);

  const {isFeatureFlagOn} = useFeatureFlags();
  const first = idx === 0;
  const last = idx === cardCount - 1;
  const item = {
    id,
    order,
    title,
    dragPreviewComponent: (
      <div style={{maxWidth: '915px'}}>
        <CardContainer>
          {showProgramCard ? (
            <ProgramCard
              smallImageUrl={smallImageUrl}
              id={id}
              providerLogoUrl={providerLogoUrl}
              providerName={providerName}
              title={title}
              description={description}
              type={type}
              duration={duration}
              programType={programType}
              metadata={metadata}
              usersCurrency={usersCurrency}
              exchangeRate={exchangeRate}
              step={step}
              contentUploadPercent={contentUploadPercent}
            />
          ) : (
            <CustomContentCardContainer>
              <ContentIcon contentType={type} lxpContentType={lxpContentType} />
              <div>
                <Title style={{marginBottom: 0}}>{title}</Title>
                <ContentMetaData>{metadata}</ContentMetaData>
              </div>
            </CustomContentCardContainer>
          )}
        </CardContainer>
      </div>
    ),
  };
  const {drag, drop, isOver, isDragging, preview} = useDragAndDrop({
    item,
    reorderItem: reorderStepItem,
    setReorderIdx: setStepReorderIdx,
    setIsDraggingOrder: setIsDraggingStepOrder,
  });

  useEffect(() => {
    preview(getEmptyImage(), {captureDraggingState: true});
  }, []);

  const isDraggingContentHigher = isDraggingStepOrder > order;
  const isDraggingContentLower = isDraggingStepOrder < order;

  const {editLabel, deleteLabel} = useGetLabelsByAcademyStepType(type);

  const duplicateLabel = (() => {
    switch (type) {
      case AcademyStepType.TextContent:
        return i18n.t(k.CONTENT__DUPLICATE__VERB);
      case AcademyStepType.EventContent:
        return i18n.t(k.EVENT__DUPLICATE__VERB);
      case AcademyStepType.ProjectContent:
        return i18n.t(k.PROJECT__DUPLICATE__VERB);
      case AcademyStepType.Discussion:
        return i18n.t(k.DISCUSSION__DUPLICATE__VERB);
      default:
        return i18n.t(k.PROGRAM__DUPLICATE__VERB);
    }
  })();

  const stepElId = `admin-step-${step.id}`;

  return (
    <Container
      id={stepElId}
      isOver={isOver}
      ref={(node: any) => drag(drop(node))}
      isDraggingContentHigher={isDraggingContentHigher}
      isDraggingContentLower={isDraggingContentLower}
      aria-labelledby="step-card-title">
      {!first && cardCount > 1 && <IndexRuleTop />}
      {!last && cardCount > 1 && <IndexRuleBottom />}
      <Index>
        <span
          className="draggable-indicator"
          aria-label={i18n.t(k.CTA__DRAG_AND_DROP)}>
          <MenuOutlined aria-hidden="true" />
        </span>
      </Index>
      <CardContainer
        first={first}
        last={last}
        onClick={onClick}
        isOver={isOver}
        isStepReorderLoading={isStepReorderLoading}
        isDragging={isDragging}
        isDraggingContentHigher={isDraggingContentHigher}
        isDraggingContentLower={isDraggingContentLower}>
        {showProgramCard ? (
          <ProgramCard
            smallImageUrl={smallImageUrl}
            id={id}
            providerLogoUrl={providerLogoUrl}
            providerName={providerName}
            title={title}
            description={description}
            type={type}
            duration={duration}
            programType={programType}
            metadata={metadata}
            usersCurrency={usersCurrency}
            exchangeRate={exchangeRate}
            step={step}
            contentUploadPercent={contentUploadPercent}
          />
        ) : (
          <CustomContentCardContainer>
            <ContentIcon contentType={type} lxpContentType={lxpContentType} />
            <div>
              <Title style={{marginBottom: 0}}>{title}</Title>
              <ContentMetaData>{metadata}</ContentMetaData>
            </div>
          </CustomContentCardContainer>
        )}
        <div
          style={{
            top: 0,
            right: 0,
            position: 'absolute',
            padding: '21px',
          }}>
          <ContextMenuButton
            title={step.title}
            popupContainerId={stepElId}
            itemId={step.id}
            size="middle"
            overlayPlacement="bottomRight"
            menuItems={[
              (type === AcademyStepType.TextContent ||
                type === AcademyStepType.EventContent) && {
                label: editLabel,
                noBottomBorder: true,
                onClick: onClickEdit,
              },
              (type === AcademyStepType.TextContent ||
                type === AcademyStepType.EventContent) && {
                label: duplicateLabel,
                onClick: onClickDuplicate,
              },
              {
                label: i18n.t(k.CTA__MOVE_TO_TOP),
                noBottomBorder: true,
                onClick: onClickMoveToTop,
              },
              {
                label: i18n.t(k.CTA__MOVE_TO_BOTTOM),
                noBottomBorder: true,
                onClick: onClickMoveToBottom,
              },
              {
                label: i18n.t(k.SECTION__MOVE_TO),
                onClick: onClickMoveToLevel,
                noBottomBorder: type === AcademyStepType.CustomProgram,
              },
              type === AcademyStepType.CustomProgram && {
                label: i18n.t(k.PROGRAM__EDIT),
                noBottomBorder: type !== AcademyStepType.CustomProgram,
                onClick: () => onClickEditProgram(step),
              },
              showRequirementContextMenuOption && {
                label: blockingTitle
                  ? i18n.t(k.LEVEL__REQUIREMENT__EDIT)
                  : i18n.t(k.LEVEL__REQUIREMENT__ADD),
                onClick: onClickUpdateRequirement,
                noBottomBorder: true,
              },
              isFeatureFlagOn.SkipAcademyStep &&
                step.isCompletable && {
                  label: step.allowSkip
                    ? i18n.t(k.LABEL__DO_NOT_ALLOW_SKIP)
                    : i18n.t(k.LABEL__ALLOW_SKIP),
                  onClick: onClickToggleAllowSkip,
                  noBottomBorder: true,
                },
              {
                label: (
                  <span style={{color: COLORS.Red800}}>{deleteLabel}</span>
                ),
                onClick: onClickDelete,
              },
            ]}
          />
        </div>
      </CardContainer>
    </Container>
  );
}

export default StepCard;
