// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/Responses/AcademyUsersResponse.cs

import {z} from 'zod';
import {AcademyUserVMSchema} from './AcademyUserVMSchema.schema';

export const AcademyUsersResponseSchema = z.object({
  pageNumber: z.number(),
  pageSize: z.number(),
  results: z.array(AcademyUserVMSchema),
  totalCount: z.number(),
  totalPages: z.number(),
});
