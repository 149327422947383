import {AcademyStatus} from './../enums';
import {AcademyResponse} from './../interfaces';
import {
  IAcademyBasicInfoMockData,
  getIAcademyBasicInfoMockData,
} from './IAcademyBasicInfo.mock';
import {
  IAcademyBrandBasicInfoMockData,
  getIAcademyBrandBasicInfoMockData,
} from './IAcademyBrandBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyResponseMockData(
  args?: Partial<AcademyResponse>
): AcademyResponse {
  return {
    ...getIAcademyBasicInfoMockData(),
    ...getIAcademyBrandBasicInfoMockData(),
    academyProfileImageUrl: faker.image.imageUrl(),
    backgroundColor: faker.internet.color(), // optional
    backgroundWelcomeColor: faker.internet.color(), // optional
    buttonDefaultWelcomeColor: faker.internet.color(), // optional
    buttonHoverWelcomeColor: faker.internet.color(), // optional
    degreedId: String(faker.datatype.number()), // optional
    description: faker.lorem.lines(),
    discussionId: String(faker.datatype.number()), // optional
    duration: faker.datatype.number(), // optional
    endDate: String(faker.date.past()), // optional
    groupId: String(faker.datatype.number()), // optional
    id: faker.datatype.number(),
    invitationEmail: faker.datatype.boolean(),
    isDefaultTheme: faker.datatype.boolean(),
    itemDefaultTextNavColor: faker.internet.color(), // optional
    itemDefaultTextWelcomeColor: faker.internet.color(), // optional
    itemHoverBackgroundNavColor: faker.internet.color(), // optional
    itemHoverTextNavColor: faker.internet.color(), // optional
    itemSelectedBackgroundNavColor: faker.internet.color(), // optional
    itemSelectedTextNavColor: faker.internet.color(), // optional
    joinId: String(faker.datatype.number()),
    memberCount: faker.datatype.number(),
    name: faker.name.fullName(),
    primaryBrandColor: faker.internet.color(), // optional
    restrictedAccess: faker.datatype.boolean(),
    secondaryBrandColor: faker.internet.color(), // optional
    startDate: String(faker.date.past()), // optional
    status: AcademyStatus.Draft,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyResponseMockData: AcademyResponse = {
  ...IAcademyBasicInfoMockData,
  ...IAcademyBrandBasicInfoMockData,
  academyProfileImageUrl: 'https://loremflickr.com/640/480',
  backgroundColor: '#FFD7DD', // optional
  backgroundWelcomeColor: '#FFD7DD', // optional
  buttonDefaultWelcomeColor: '#FFD7DD', // optional
  buttonHoverWelcomeColor: '#FFD7DD', // optional
  degreedId: '1', // optional
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  discussionId: '1', // optional
  duration: 123, // optional
  endDate: '2023-03-29T22:17:19.738072', // optional
  groupId: '1', // optional
  id: 1,
  invitationEmail: true,
  isDefaultTheme: true,
  itemDefaultTextNavColor: '#FFD7DD', // optional
  itemDefaultTextWelcomeColor: '#FFD7DD', // optional
  itemHoverBackgroundNavColor: '#FFD7DD', // optional
  itemHoverTextNavColor: '#FFD7DD', // optional
  itemSelectedBackgroundNavColor: '#FFD7DD', // optional
  itemSelectedTextNavColor: '#FFD7DD', // optional
  joinId: '1',
  memberCount: 5,
  name: 'Bobby Johnson',
  primaryBrandColor: '#FFD7DD', // optional
  restrictedAccess: true,
  secondaryBrandColor: '#FFD7DD', // optional
  startDate: '2023-03-29T22:17:19.738072', // optional
  status: AcademyStatus.Draft,
};
