// Do not modify directly
// Generated from file: learnin.core.api/Controllers/LxpContent/Requests/RoleOrContributorLxpContentSearchRequest.cs

import {z} from 'zod';
import {LxpContentSearchRequestSchema} from './LxpContentSearchRequestSchema.schema';

const schema = z.object({
  academyId: z.number().nullable().optional(),
});

export const RoleOrContributorLxpContentSearchRequestSchema =
  LxpContentSearchRequestSchema.merge(schema);
