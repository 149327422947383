import {useState, ChangeEvent, ReactNode} from 'react';
import {FormInstance} from 'antd/es/form';
import {Moment} from 'moment';
import {Form, Checkbox} from 'antd';
import {i18n, k} from '@i18n/translate';
import ContentTypeIcon from '@blocks/ContentTypeIcon';
import {WhenLoaded} from '@utils/typeUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import styled from 'styled-components';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {COLORS} from '@utils/constants';
import {DeleteOutlined} from '@ant-design/icons';
import {AcademyStepContentModalPayload} from '@models/clientModels';
import {ContentType} from '@generated/enums';
import {TimeZoneVM} from '@models/serverModels';
import {
  disableDatesInThePast,
  getSimplifiedTimeZoneList,
  formatTimeZoneForDisplay,
  formatUtcTime,
  DATE_FORMAT,
  matchTimeZoneFromList,
} from '@utils/timeUtils';
import {InputLabel, StyledLearnInInput} from '@components/reusable/LearnInForm';
import {RichTextEditor} from '@components/reusable/RichTextEditor';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {LearnInDatePicker} from '@components/reusable/LearnInDatePicker';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import Grid from '@blocks/Grid';

export const LabelAndIconContainer = styled.div<{marginBottom: string}>`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  margin-bottom: ${({marginBottom}) => marginBottom};
`;
const DatePickerContainer = styled(LearnInDatePicker)`
  border-color: ${COLORS.Neutral600} !important;
  border-radius: 4px !important;
  width: 275px;
  .ant-picker-input > input::placeholder,
  .ant-picker-suffix {
    color: ${COLORS.Neutral600};
    font-size: 1rem;
  }
`;
const TimezoneSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  .ant-select-selection-item {
    color: ${COLORS.Blue800};
  }
  .ant-select-selection-search {
    width: 5rem;
  }
`;
const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
`;
const DueDateContainer = styled.div`
  column-gap: 30px;
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  & .ant-picker {
    height: 40px;
  }
  & .ant-picker ::placeholder {
    font-size: 1rem;
  }
`;

interface AddDisucssionStepsModalProps {
  handleClose: () => void;
  onOk: (payload: AcademyStepContentModalPayload) => void;
  companyId: number;
  form: FormInstance<{requiredId: number}>;
  timeZones?: WhenLoaded<TimeZoneVM[]>;
  initialValues?: AcademyStepContentModalPayload;
  isEditingExistingStep?: boolean;
  isLoadingSteps?: boolean;
  allowSkip?: boolean;
  primaryButtonDisabled?: boolean;
  showAllowSkip?: boolean;
  stepRequirementComponent?: ReactNode;
}

export function AddDiscussionStepModal({
  handleClose,
  timeZones,
  stepRequirementComponent,
  onOk,
  initialValues,
  isEditingExistingStep,
  form,
  allowSkip,
  companyId,
  primaryButtonDisabled,
  showAllowSkip,
}: AddDisucssionStepsModalProps) {
  const formattedTimezones = getSimplifiedTimeZoneList(timeZones);
  const {isFeatureFlagOn} = useFeatureFlags();

  const [title, setTitle] = useState(initialValues?.title || '');
  const [_allowSkip, setAllowSkip] = useState(allowSkip || false);
  const [description, setDescription] = useState(
    initialValues?.description || ''
  );
  const [dueDate, setDueDate] = useState<Moment | undefined>(
    initialValues?.dueDate
      ? formatUtcTime(initialValues?.dueDate, initialValues?.dueDateTimeZone)
      : undefined
  );
  const [timeZone, setTimeZone] = useState(() => {
    if (initialValues?.dueDateTimeZone) {
      return timeZones?.find((i) => i.id === initialValues?.dueDateTimeZone)
        ?.displayName;
    }
    return matchTimeZoneFromList(timeZones).displayName;
  });
  const [timeZoneId, setTimeZoneId] = useState(() => {
    if (initialValues?.dueDateTimeZone) {
      return timeZones?.find((i) => i.id === initialValues?.dueDateTimeZone)
        ?.id;
    }
    return matchTimeZoneFromList(timeZones).id;
  });
  const [dueTime, setDueTime] = useState<string>(() => {
    if (initialValues?.dueDate) {
      const dueDateTime = dueDate?.format(DATE_FORMAT.TIME_24_HR_PADDED);
      return dueDateTime;
    }
    return undefined;
  });

  const okButtonText = i18n.t(
    isEditingExistingStep ? k.DISCUSSION__UPDATE : k.DISCUSSION__SAVE
  );
  return (
    <LearnInModal
      visible={true}
      width={800}
      okButtonChild={okButtonText}
      buttonDisabled={primaryButtonDisabled}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      tag={ModalTags.MoneyIncentive}
      onOk={() => {
        onOk({
          title,
          description,
          dueDate: dueDate ? dueDate.format('YYYY-MM-DD') : undefined,
          dueDateTimeZone: timeZoneId,
          dueTime: dueTime ? dueTime : undefined,
          allowSkip: _allowSkip,
          contentType: ContentType['Discussion'],
        });
      }}
      onCancel={() => handleClose()}
      ariaLabel={i18n.t(k.DISCUSSION)}>
      <Form layout="vertical" initialValues={initialValues || {}} form={form}>
        <div style={{marginBottom: '24px'}}>
          <LabelAndIconContainer>
            <ContentTypeIcon contentType={ContentType['Discussion']} />{' '}
            <ModalTitle>{i18n.t(k.DISCUSSION)}</ModalTitle>
          </LabelAndIconContainer>
        </div>
        <Grid.Container>
          <Grid.Box>
            <InputLabel
              htmlFor="title-input"
              label={i18n.t(k.GENERIC__TITLE)}
              required
            />
            <Form.Item name="title" rules={[{required: true}]}>
              <StyledLearnInInput
                value={title}
                id="title"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setTitle(e.target.value)
                }
              />
            </Form.Item>
          </Grid.Box>
          <Grid.Box>
            <InputLabel
              htmlFor="description-input"
              label={i18n.t(k.GENERIC__DESCRIPTION)}
            />
            <Form.Item name="description" rules={[{required: false}]}>
              <RichTextEditor
                id="description-input"
                value={description}
                onChange={(e: string) => {
                  setDescription(e);
                }}
                companyId={companyId}
              />
            </Form.Item>
          </Grid.Box>
          <DueDateContainer>
            <div>
              <InputLabel
                htmlFor="due-date-input"
                label={i18n.t(k.DATE__DUE_DATE)}
              />
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Form.Item
                  valuePropName="dueDate"
                  name="dueDate"
                  style={{marginBottom: 0}}>
                  <DatePickerContainer
                    id="due-date-input"
                    value={dueDate}
                    setValue={(e: Moment) => {
                      setDueDate(e);
                    }}
                    disabledDate={disableDatesInThePast}
                    placeholder={i18n.t(k.DATE)}
                  />
                </Form.Item>
                <LearnInButton
                  icon={<DeleteOutlined aria-hidden="true" />}
                  aria-label={i18n.t(k.CTA__DELETE)}
                  tag={ButtonTags.DestructiveRemovedBorder}
                  onClick={() => {
                    setDueDate(undefined);
                    setDueTime(undefined);
                  }}
                />
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <InputLabel htmlFor="due-time-input" label={i18n.t(k.TIME)} />
                <Form.Item valuePropName="dueTime" name="dueTime">
                  <StyledLearnInInput
                    type="time"
                    id="due-time-input"
                    value={dueTime}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setDueTime(e.target.value)
                    }
                    style={{
                      width: '275px',
                      borderColor: `${COLORS.Neutral600} !important`,
                      borderRadius: '4px !important',
                    }}
                  />
                </Form.Item>
              </div>
              <TimezoneSelect style={{marginTop: '10px'}}>
                <LearnInSelect
                  aria-label={i18n.t(k.VALIDATION__SELECT_TIMEZONE)}
                  showSearch
                  bordered={false}
                  showArrow={false}
                  value={formatTimeZoneForDisplay(timeZone)}
                  filterOption={(input, {value}) => {
                    return value
                      ?.toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase());
                  }}
                  dropdownMatchSelectWidth={false}
                  onChange={(value, timeZoneData) => {
                    setTimeZone(value || '');
                    setTimeZoneId(timeZoneData.key || '');
                  }}>
                  {formattedTimezones?.map((timeZone) => (
                    <LearnInSelectOption
                      key={timeZone.id}
                      data-testid={`${timeZone.id}-select-option`}
                      value={timeZone.displayName}>
                      {timeZone.displayName}
                    </LearnInSelectOption>
                  ))}
                </LearnInSelect>
              </TimezoneSelect>
            </div>
          </DueDateContainer>
          {stepRequirementComponent}
          {isFeatureFlagOn.SkipAcademyStep && showAllowSkip && (
            <div style={{marginBottom: '24px'}}>
              <Checkbox
                onChange={() => setAllowSkip(!_allowSkip)}
                checked={_allowSkip}
                value="allowSkipAcademyStep">
                {i18n.t(k.ACADEMY_STEP_CHECKBOX_LABEL_ALLOW_SKIP_STEP)}
              </Checkbox>
            </div>
          )}
        </Grid.Container>
      </Form>
    </LearnInModal>
  );
}
