import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import LearnInDrawer, {
  FooterButtonsContainer,
} from '@components/reusable/LearnInDrawer';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import RequestForm from '../RequestForm/RequestForm.container';
import RequestMenu from '../RequestMenu/RequestMenu.view';
import {RequestMenuButtonAndDrawerStyles} from '../RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.styled';
import RequestProgramFinderBanner from '../RequestProgramFinderBanner/RequestProgramFinderBanner.view';
import {REQUEST_PROGRAM_FINDER_BANNER_TEST_ID} from './RequestMenuDrawer.constants';
import {RequestMenuDrawerViewProps} from './RequestMenuDrawer.interfaces';
import WhatsNext from '@components/WhatsNext';
import styled from 'styled-components';

export const FormFooterButtonsContainer = styled.div`
  background: ${COLORS.White};
  align-items: center;
  display: flex;
  // height: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid rgb(163, 181, 189); // Unsure of the palette color
  height: 72px;
  min-height: 72px;
  box-sizing: border-box;
  padding: 16px 20px;
  justify-content: flex-end;
  flex-grow: 1;
  display: flex;
  width: 100%;
  left: 0;
  right: 0;
  button {
    margin-left: 16px;
  }
`;

function RequestMenuDrawer({
  form,
  formVisible,
  handleOnClickMarketplaceLink,
  handleOnClickOption,
  handleOnClickProgramFinder,
  handleOnClickReset,
  handleOnCloseSuccess,
  isSubmitButtonDisabled,
  isSubmitButtonLoading,
  marketplaceLinkText,
  requestType,
  requestTypeOptions,
  rerequestResource,
  reset,
  selectionIndex,
  setIsSubmitButtonLoading,
  setRequestType,
  setTotal,
  showFindProgramOption,
  showFooter,
  total,
}: RequestMenuDrawerViewProps) {
  // This Footer is used for the RequestForm
  const renderFooter = (onSubmit = () => {}) => {
    return (
      <>
        <WhatsNext />
        <FormFooterButtonsContainer>
          <LearnInButton
            tag={ButtonTags.SecondaryBlackRemovedBorder}
            onClick={handleOnClickReset}>
            {i18n.t(k.CTA__CANCEL)}
          </LearnInButton>
          <LearnInButton
            tag={ButtonTags.DrawerFooterPrimary}
            disabled={isSubmitButtonDisabled}
            loading={isSubmitButtonLoading}
            onClick={onSubmit}
            data-cy="requestSubmitButton">
            {i18n.t(k.CTA__SUBMIT)}
          </LearnInButton>
        </FormFooterButtonsContainer>
      </>
    );
  };

  return (
    <LearnInDrawer
      closeButtonPlacement={'right'}
      title={i18n.t(k.FUND__REQUEST__NEW)}
      footerStyle={{borderTopColor: COLORS.Neutral400}}
      onClose={reset}
      bodyStyle={RequestMenuButtonAndDrawerStyles}
      footer={
        // Footer only used for Request Options Menu
        !formVisible && (
          <FooterButtonsContainer>
            <LearnInButton
              tag={ButtonTags.SecondaryBlackRemovedBorder}
              onClick={handleOnClickReset}>
              {i18n.t(k.CTA__CANCEL)}
            </LearnInButton>
            <LearnInButton
              tag={ButtonTags.DrawerFooterPrimary}
              disabled={isSubmitButtonDisabled}
              loading={isSubmitButtonLoading}
              onClick={() => {
                // This button is always disabled
              }}
              data-cy="requestSubmitButton">
              {i18n.t(k.CTA__SUBMIT)}
            </LearnInButton>
          </FooterButtonsContainer>
        )
      }
      visible={true}>
      {showFindProgramOption && (
        <RequestProgramFinderBanner
          onButtonClick={handleOnClickProgramFinder}
          dataTestId={REQUEST_PROGRAM_FINDER_BANNER_TEST_ID}
        />
      )}

      {/* This becomes the option's header once an option is selected */}
      <RequestMenu
        marketplaceLinkText={marketplaceLinkText}
        handleOnClickMarketplaceLink={handleOnClickMarketplaceLink}
        handleOnClickOption={handleOnClickOption}
        requestTypeOptions={requestTypeOptions}
        selectionIndex={selectionIndex}
        showFooter={showFooter}
      />

      {/* Application form */}
      {formVisible && (
        <RequestForm
          form={form}
          handleOnCloseSuccess={handleOnCloseSuccess}
          requestType={requestType}
          rerequestResourceType={rerequestResource?.resourceType}
          setIsSubmitButtonLoading={setIsSubmitButtonLoading}
          setRequestType={setRequestType}
          setTotal={setTotal}
          total={total}
          userPlanResourceId={rerequestResource?.id}
          renderFooter={renderFooter}
        />
      )}
    </LearnInDrawer>
  );
}

export default RequestMenuDrawer;
