import {
  LxpContentSearchResponse,
  RoleOrContributorLxpContentSearchRequest,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {RoleOrContributorLxpContentSearchRequestSchema} from '../zodSchemas/RoleOrContributorLxpContentSearchRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/LxpContent/LxpContentController.cs

/*
|--------------------------------------------------------------------------
|  search 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useLxpcontentSearchMutation} from '@generated/hooks';
 *
 *  const lxpcontentSearch = useLxpcontentSearchMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  lxpcontentSearch.mutate({
 *    payload: {
 *      ...RoleOrContributorLxpContentSearchRequest
 *    },
 *  })
 *
 */

export const useLxpcontentSearchMutation = mutationHookFactory<
  RoleOrContributorLxpContentSearchRequest, // payload
  null,
  LxpContentSearchResponse // return type
>('useLxpcontentSearchMutation', 'post', () => `lxpcontent/search`, {
  payloadSchema: RoleOrContributorLxpContentSearchRequestSchema,
});
