import {i18n, k} from '@i18n/translate';
import {CustomProgramStatus} from '@generated/enums';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {GlobalOutlined, LockOutlined} from '@ant-design/icons';
import {debounce} from 'lodash';

export const getPermissionTypeDisplay = (restrictedAccess) => {
  return !restrictedAccess ? (
    <>
      {' '}
      <GlobalOutlined aria-hidden="true" style={{marginRight: '6px'}} />
      {i18n.t(k.ACCESS__PUBLIC)}
    </>
  ) : (
    <>
      {' '}
      <LockOutlined aria-hidden="true" style={{marginRight: '6px'}} />
      {i18n.t(k.ACCESS__RESTRICTED)}
    </>
  );
};

export const renderStatusColumn = (status) => {
  const isPublished = status === CustomProgramStatus.Published;
  return (
    <LearnInTag
      label={
        isPublished
          ? i18n.t(k.ACCESS__PUBLISHED__UPPERCASE)
          : i18n.t(k.ACCESS__DRAFT__UPPERCASE)
      }
      tagStyle={isPublished ? TagStyles.Green : TagStyles.LightGrey}
      style={{width: 'fit-content'}}
    />
  );
};

export const debounceSearch = debounce(
  (searchText: string, callBack: (value: string) => void) => {
    callBack(searchText);
  },
  300
);
