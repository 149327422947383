/** These strings aren't customer-facing: these don't need to be localized. */
export const PROGRAM_NOMINATION = {
  name: 'Dr. Hammond',
  nominationUrl: '/#fake-nomination-url',
  providerImageUrl:
    'https://static.wikia.nocookie.net/logopedia/images/3/35/Universal_Pictures_2021.svg/revision/latest/scale-to-width-down/300?cb=20220124004041',
  providerName: "We're Back!",
  programName: "A Dinosaur's Story",
  companyLogoUrl:
    'https://static.wikia.nocookie.net/logopedia/images/f/f3/Jurassic_Park_-_Logo.png/revision/latest/scale-to-width-down/185?cb=20230523215921',
};
