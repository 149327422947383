import styled, {DefaultTheme} from 'styled-components';
import {Breakpoints} from '@utils/responsiveUtils';
import {pxToRem} from '@utils/styleMixins';

const PROGRESSBAR_SIZE = 41;
export const Container = styled.button<{
  backgroundColor?: string;
  hoverBorderColor?: string;
  textColor?: string;
  borderColor?: string;
}>`
  width: 100%;
  align-items: center;
  background: ${({backgroundColor}) => backgroundColor};
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  min-height: 72px;
  max-height: 72px;
  .ant-progress-text {
    color: ${({textColor}) => textColor};
    font-size: 14px;
  }
  border: 1px solid
    ${({backgroundColor, borderColor}) => borderColor ?? backgroundColor};
  &:hover {
    border: 1px solid ${({hoverBorderColor}) => hoverBorderColor};
  }
  svg[data-icon='check'] {
    fill: ${({textColor}) => textColor} !important;
  }
  .ant-progress-inner {
    height: ${PROGRESSBAR_SIZE}px !important;
    width: ${PROGRESSBAR_SIZE}px !important;
  }

  @media (max-width: ${Breakpoints.Desktop}px) {
    min-width: 140px;
    margin-right: 8px;
    min-height: unset;
  }
  &:focus {
    outline: 2px solid ${(props: DefaultTheme) => props.itemDefaultTextNavColor};
  }
`;

export const Title = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 0;
  max-height: 100%;
  @media (max-width: ${Breakpoints.Desktop}px) {
    font-size: ${pxToRem(12, 16)};
    line-height: ${pxToRem(20, 16)};
  }
`;
