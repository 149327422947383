/** These strings aren't customer-facing: these don't need to be localized. */
export const EVENT_REMINDER_PROGRAM = {
  name: 'LeBron',
  type: 'program',
  title: 'Free-Throws For Dummies',
  eventUrl: '/#/event/some-event-id',
  googleEventUrl: 'https://events.google.com/?some-event-id',
  outlookEventUrl: 'https://events.outlook.com/?some-event-id',
  message: 'Wednesday, November 14th * 11am-12pm EST',
  companyLogoUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Los_Angeles_Lakers_logo.svg/582px-Los_Angeles_Lakers_logo.svg.png',
};
