import {sortBy} from 'lodash';
import {
  PermissionVM,
  ProjectSubmissionVM,
  AcademyUserVM,
  AttachmentVM,
  AcademiesCountVM,
  LinkVM,
} from '@models/serverModels';
import {AcademiesResponse, AcademyVM} from '@generated/interfaces';
import {AcademyPeopleUserVM} from '@models/api/academy/viewModels';
import {queryHookFactory} from './../utils';
import {
  AcademyLevelVM,
  AcademyWithMembershipStatusVM,
  AcademyStepVM,
} from '@generated/interfaces';
import {AcademyStatus} from '@generated/enums';
import {
  addQueryParams,
  queryHookFactory as queryHookFactoryV2,
} from '../../../api/utils';
import {AcademiesResponseSchema} from '@generated/zodSchemas/AcademiesResponseSchema.schema';
import {z} from 'zod';

const BASE_PATH = 'academies';

// TODO: Update generated queries to include this query hook in place of
// the existing get-academy query
export const useAcademiesWithPagination = queryHookFactoryV2<
  {
    queryParams?: {
      isRestricted?: boolean;
      searchText?: string;
      status?: AcademyStatus;
      pageSize?: number;
      pageNumber?: number;
      isAscending?: boolean;
      sortBy?: string;
    };
  },
  AcademiesResponse // return type
>(
  'useAcademiesWithPaginationQuery',
  ({queryParams}) => addQueryParams(() => BASE_PATH, null, queryParams),
  {responseSchema: AcademiesResponseSchema}
);

export const useAcademyProjectSubmissionsQuery = queryHookFactory<
  number,
  ProjectSubmissionVM[]
>((stepId) => `${BASE_PATH}/get-peer-viewable-academy-submissions/${stepId}`);

export const useGetAcademyPermissionVMsQuery = queryHookFactory<
  number,
  PermissionVM[]
>((academyId) => `${BASE_PATH}/get-user-permissions/${academyId}`);

export const useAcademyLevelVMsQuery = queryHookFactory<
  {academyId: number; excludeUserProgress?: boolean},
  AcademyLevelVM[]
>(({academyId, excludeUserProgress}) => {
  let path = `${BASE_PATH}/get-academy-levels/${academyId}`;
  if (excludeUserProgress) {
    path += '?excludeUserProgress=true';
  }
  return path;
});

export const useAcademyStepVMsQuery = queryHookFactory<number, AcademyStepVM[]>(
  (levelId) => `${BASE_PATH}/get-academy-level-step/${levelId}`
);

export const useAcademies = queryHookFactory<null, AcademyVM[]>(
  () => `${BASE_PATH}/get-academies`
);

export const useEnrolledAcademyVMsQuery = queryHookFactory<null, AcademyVM[]>(
  () => `${BASE_PATH}/get-enrolled-academies`
);

export const useAcademyEditorPeopleUsersQuery = queryHookFactory<
  number,
  AcademyPeopleUserVM[]
>((academyId) => `${BASE_PATH}/get-academy-editors/${academyId}`);

export const useAcademyProjectReviewerPeopleUsersQuery = queryHookFactory<
  number,
  AcademyPeopleUserVM[]
>((academyId) => `${BASE_PATH}/get-academy-project-reviewers/${academyId}`);

export const usePublishedAcademies = queryHookFactory<
  null,
  AcademyWithMembershipStatusVM[]
>(() => `${BASE_PATH}/get-published-academies`);

export const useAcademy = queryHookFactory<number, AcademyVM>(
  (academyId) => `${BASE_PATH}/get-academy/${academyId}`
);

export const useAcademyProjectSubmissionAttachmentUrl = queryHookFactory<
  number,
  string
>((submissionId) =>
  [BASE_PATH, 'get-project-submission-url', submissionId].join('/')
);

export const useAcademyUsers = queryHookFactory<number, AcademyUserVM[]>(
  (academyId) => `${BASE_PATH}/get-academy-users/${academyId}`
);

export const useAcademyStepAdminAttachments = queryHookFactory<
  number,
  AttachmentVM[]
>((levelId) => `${BASE_PATH}/get-academy-step-content-attachments/${levelId}`);

export const useAcademyLevel = queryHookFactory<number, AcademyLevelVM[]>(
  (levelId) => `${BASE_PATH}/get-academy-level-detail/${levelId}`
);

export const useAcademiesCounts = queryHookFactory<null, AcademiesCountVM>(
  () => `${BASE_PATH}/get-academies-counts`
);

export const useAcademyStepAttachmentUrl = queryHookFactory<number, string>(
  (attachmentId) => `${BASE_PATH}/get-content-attachment-url/${attachmentId}`
);

export const useAcademyStepLinks = queryHookFactory<number, LinkVM[]>(
  (attachmentId) => `${BASE_PATH}/get-content-attachment-url/${attachmentId}`
);

export const useAcademyPermissions = queryHookFactory<number, PermissionVM[]>(
  (academyId) => `${BASE_PATH}/get-user-permissions/${academyId}`
);

export const useAcademyUsersQuery = queryHookFactory<number, AcademyUserVM[]>(
  (academyId) => `${BASE_PATH}/get-academy-users/${academyId}`
);

export const useEnrolledAcademyUserQuery = queryHookFactory<
  {academyId: number; userCompanyId: number},
  AcademyUserVM
>(
  ({academyId, userCompanyId}) =>
    `${BASE_PATH}/${academyId}/user-company/${userCompanyId}`
);
