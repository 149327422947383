import {useEffect, useRef, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import {Select} from 'antd';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {AcademiesTableContainer} from './AcademiesTable/AcademiesTable.container';
import {useNavigate} from 'react-router-dom';
import {AdminPaths} from '@utils/ClientPaths';
import IcLxpChevronDown from '@assets/svg/ic-lxp-chevron-down.svg';
import styled from 'styled-components';
import {AcademyStatus} from '@generated/enums';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {AcademiesTableList} from './AcademiesTable/AcademiesTableList';
import {COLORS} from '@utils/constants';
import {debounceSearch} from './AcademiesTable/utils';

const LxpAdminTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto 40px;
  padding: 0 24px;
`;

const LxpAdminSearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
`;

const LxpAdminTitle = styled.h1`
  display: flex;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
`;

const LxpAdminButton = styled(LearnInButton)`
  border-radius: 8px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const SearchInput = styled(LearnInInput)`
  border-radius: 8px;
`;

const FilterSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
    padding: 0 14px !important;
  }

  .ant-select-selection-placeholder {
    color: ${COLORS.Black} !important;
  }
`;

const StyledCheckbox = styled.input`
  margin-right: 8px;
`;

const PAGE_SIZE = 50;

export type PermissionLevel = 'public' | 'restricted';

export const AcademiesAdminPage = () => {
  const navigate = useNavigate();
  const isLxpAdmin = useIsLxpAdmin();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<AcademyStatus>(undefined);
  const [permission, setPermission] = useState<PermissionLevel>(undefined);
  const {isFeatureFlagOn} = useFeatureFlags();

  const [searchText, setSearchText] = useState<string>();

  useEffect(() => {
    debounceSearch(search, setSearchText);

    return () => {
      debounceSearch.cancel();
    };
  }, [search]);

  const renderNewAcademyButton = () => {
    return (
      <LxpAdminButton
        icon={<PlusOutlined />}
        onClick={() => navigate(AdminPaths.NewAcademy)}
        tag={ButtonTags.Primary}>
        {i18n.t(k.ACADEMY__NEW)}
      </LxpAdminButton>
    );
  };

  const renderSearchBar = () => {
    return (
      <LxpAdminSearchBar>
        <SearchInput
          prefix={<SearchOutlined />}
          placeholder={i18n.t(k.GENERIC__SEARCH)}
          style={{maxWidth: '364px'}}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <FiltersContainer>
          <FilterSelect
            suffixIcon={<img src={IcLxpChevronDown} alt="" />}
            onSelect={(selectedPermission: PermissionLevel) => {
              const deselect = selectedPermission === permission;
              const newValue = deselect ? null : selectedPermission;
              setPermission(newValue);
            }}
            optionLabelProp="label"
            placeholder={i18n.t(k.PERMISSION__PLURAL)}
            dropdownMatchSelectWidth={148}
            value={permission}>
            <Select.Option value="public" label={i18n.t(k.ACCESS__PUBLIC)}>
              <StyledCheckbox
                type="checkbox"
                checked={permission === 'public'}
              />
              {i18n.t(k.ACCESS__PUBLIC)}
            </Select.Option>
            <Select.Option
              value="restricted"
              label={i18n.t(k.ACCESS__RESTRICTED)}>
              <StyledCheckbox
                type="checkbox"
                checked={permission === 'restricted'}
              />
              {i18n.t(k.ACCESS__RESTRICTED)}
            </Select.Option>
          </FilterSelect>
          <FilterSelect
            suffixIcon={<img src={IcLxpChevronDown} alt="" />}
            onSelect={(selectedStatus: AcademyStatus) => {
              const deselect = selectedStatus === status;
              const newValue = deselect ? null : selectedStatus;
              setStatus(newValue);
            }}
            optionLabelProp="label"
            placeholder={i18n.t(k.STATUS__STATUS)}
            dropdownMatchSelectWidth={124}
            value={status}>
            <Select.Option
              value={AcademyStatus.Draft}
              label={i18n.t(k.ACCESS__DRAFT)}>
              <StyledCheckbox
                type="checkbox"
                checked={status === AcademyStatus.Draft}
              />
              {i18n.t(k.ACCESS__DRAFT)}
            </Select.Option>
            <Select.Option
              value={AcademyStatus.Published}
              label={i18n.t(k.ACCESS__PUBLISHED)}>
              <StyledCheckbox
                type="checkbox"
                checked={status === AcademyStatus.Published}
              />
              {i18n.t(k.ACCESS__PUBLISHED)}
            </Select.Option>
          </FilterSelect>
        </FiltersContainer>
      </LxpAdminSearchBar>
    );
  };

  const renderTitleBar = (showNewButton = true) => {
    return isLxpAdmin ? (
      <LxpAdminTitleBar>
        <LxpAdminTitle>{i18n.t(k.ACADEMY__PLURAL)}</LxpAdminTitle>
        {showNewButton ? renderNewAcademyButton() : null}
      </LxpAdminTitleBar>
    ) : (
      <PageTitleBar
        fullWidth={true}
        jsxComponent={
          <LearnInButton
            onClick={() => navigate(AdminPaths.NewAcademy)}
            tag={ButtonTags.Primary}>
            {i18n.t(k.ACADEMY__NEW)}
          </LearnInButton>
        }
        title={i18n.t(k.ACADEMY__PLURAL)}
      />
    );
  };
  return (
    <>
      {isFeatureFlagOn.AcademyMemberDates ? (
        <AcademiesTableList
          permission={permission}
          renderNewAcademyButton={renderNewAcademyButton}
          renderSearchBar={renderSearchBar}
          renderTitleBar={renderTitleBar}
          searchText={searchText}
          status={status}
        />
      ) : (
        <AcademiesTableContainer
          renderTitleBar={renderTitleBar}
          renderSearchBar={renderSearchBar}
          search={searchText}
          status={status}
          permission={permission}
          showDevUtilSearch={!isLxpAdmin}
          renderNewAcademyButton={renderNewAcademyButton}
        />
      )}
    </>
  );
};
