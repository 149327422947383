import React from 'react';
import {
  BlockedScreenContainer,
  BlockedScreenMeta,
  BlockedScreenTitle,
  CalendarConnectCard,
  Container,
  Description,
  HeaderGroup,
  StepsContainer,
  Title,
  TitleCard,
} from './AcademyContentLevelScreen.styled';
import {i18n, k} from '@i18n/translate';
import {AcademyContentLevelScreenViewProps} from './AcademyContentLevelScreen.interfaces';
import {getBlockingTitle} from '@utils/requirementGating';
import {GatedCard} from '@components/user/pages/academy/AcademyContent/GatedLevelOrStepCard';
import {AcademyItemType} from '@models/clientEnums';
import StepCardContainer from './../AcademyContent/StepCard.container';
import {CalendarOutlined, LockOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {Skeleton} from 'antd';

function AcademyContentLevelScreenView({
  academy,
  addEventsToCalendarLoading,
  blockingTitle,
  isActivePlanItem,
  isExternalCalendarConnected,
  level,
  onClickAddDatesToCalendar,
  onClickConnectCalendar,
  plan,
  selectedContentId,
  showConnectCalendarCard,
  showGatedCardIfStepIsBlocked,
  steps,
  isLoading,
  top,
  onScroll,
  scrollRef,
}: AcademyContentLevelScreenViewProps) {
  if (!!blockingTitle) {
    return (
      <BlockedScreenContainer branding={academy?.branding}>
        <div>
          <LockOutlined style={{fontSize: '45px', marginBottom: '21px'}} />
        </div>
        <BlockedScreenTitle>{level?.title}</BlockedScreenTitle>
        <div>
          <BlockedScreenMeta
            dangerouslySetInnerHTML={{
              __html: i18n.t(k.ACADEMY__COMPLETE_TO_ACCESS__FORMAT, {
                requiredTitle: blockingTitle,
              }),
            }}
          />
        </div>
      </BlockedScreenContainer>
    );
  }

  if (isLoading) {
    return (
      <Container branding={academy?.branding} top={top}>
        <Skeleton active paragraph={{rows: 1}} />
        <Skeleton active paragraph={{rows: 1}} />
        <Skeleton active paragraph={{rows: 1}} />
      </Container>
    );
  }

  return (
    <Container
      isPreview={!isActivePlanItem}
      ref={scrollRef}
      branding={academy?.branding}
      top={top}
      onScroll={(ev: React.UIEvent<HTMLDivElement>) =>
        onScroll?.(ev.currentTarget.scrollTop)
      }>
      <StepsContainer>
        <HeaderGroup>
          <TitleCard branding={academy?.branding}>
            <Title branding={academy?.branding}>{level?.title}</Title>
            {!!level?.description && (
              <Description branding={academy?.branding}>
                {level?.description}
              </Description>
            )}
          </TitleCard>
          {showConnectCalendarCard && (
            <CalendarConnectCard>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <CalendarOutlined
                  aria-hidden="true"
                  style={{
                    color: COLORS.Blue800,
                    marginRight: '9px',
                    width: '18px',
                  }}
                />
                <p style={{marginBottom: '0px', fontSize: '16px'}}>
                  {i18n.t(k.LEVEL__ADD_TO_CALENDAR)}
                </p>
              </div>
              {isExternalCalendarConnected ? (
                <LearnInButton
                  tag={ButtonTags.Link}
                  color={COLORS.Blue800}
                  loading={addEventsToCalendarLoading}
                  onClick={onClickAddDatesToCalendar}>
                  {i18n.t(k.EVENT__ADD__PLURAL)}
                </LearnInButton>
              ) : (
                <LearnInButton
                  aria-label={i18n.t(k.CALENDAR__CONNECT_TO_EVENTS, {
                    item: level?.title,
                  })}
                  tag={ButtonTags.Link}
                  color={COLORS.Blue800}
                  onClick={onClickConnectCalendar}>
                  {i18n.t(k.CALENDAR__CONNECT)}
                </LearnInButton>
              )}
            </CalendarConnectCard>
          )}
        </HeaderGroup>
        {steps
          ?.sort((a, b) => a.order - b.order)
          .map((step, index) => {
            const stepsCounts = steps.length || 0;
            const blockingTitle = getBlockingTitle(step.requiredId, steps);
            if (!!blockingTitle && showGatedCardIfStepIsBlocked) {
              return (
                <GatedCard
                  style={{marginLeft: '0px'}}
                  academyItemType={AcademyItemType.Step}
                  key={step.id}
                  requiredTitle={blockingTitle}
                  title={step.title}
                  requirementType={step.requirementProperty}
                />
              );
            }

            return (
              <StepCardContainer
                academyId={academy?.id}
                isActivePlanItem={isActivePlanItem}
                index={index}
                shouldBeInFocus={
                  selectedContentId === step.id && isActivePlanItem
                }
                cardCount={stepsCounts}
                key={step.id}
                step={step}
                plan={plan}
                levelId={level?.id}
              />
            );
          })}
      </StepsContainer>
    </Container>
  );
}

export default AcademyContentLevelScreenView;
