/** These strings aren't customer-facing: these don't need to be localized. */
export const EVENT_REMINDER_ACADEMY = {
  name: 'LeBron',
  type: 'academy',
  parentTitle: 'All Things Basketball',
  title: 'Delivering Full-Court Passes',
  eventUrl: '/#/event/some-event-id',
  googleEventUrl: 'https://events.google.com/?some-event-id',
  outlookEventUrl: 'https://events.outlook.com/?some-event-id',
  message: 'Monday, November 12th * 3-4pm CST',
  companyLogoUrl:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Los_Angeles_Lakers_logo.svg/582px-Los_Angeles_Lakers_logo.svg.png',
};
