export const isTestRun = import.meta?.env?.NODE_ENV === 'test';
export const environment = import.meta.env.VITE_ENV;

// Local
export const isLocal = import.meta.env.VITE_ENV === 'development';
export const isLocalDB = import.meta.env.VITE_DB === 'local';

// Dev
export const isDevelopment = import.meta.env.VITE_ENV === 'developmentAzure';
export const isDevelopmentAny = import.meta.env.VITE_ENV === 'developmentAzure';

// Staging
export const isStaging = import.meta.env.VITE_ENV === 'stageAzure';
export const isStagingAny = import.meta.env.VITE_ENV === 'stageAzure';

// Beta
export const isBetaEU = import.meta.env.VITE_ENV === 'betaEU';
export const isBetaUS = import.meta.env.VITE_ENV === 'betaUS';
export const isBetaAny = isBetaEU || isBetaUS;

// Production
export const isProductionUS = import.meta.env.VITE_ENV === 'production';
export const isProductionEU = import.meta.env.VITE_ENV === 'productionEU';
export const isProductionCA = import.meta.env.VITE_ENV === 'productionCA';
export const isProductionCustomSubDomain =
  location.host.endsWith('.degreed.app');

export const isProductionAny =
  isProductionUS ||
  isProductionEU ||
  isProductionCA ||
  isProductionCustomSubDomain;

/** LXP LaunchDarkly environment assignment */
export const getLxpEnvironmentName = () => {
  if (isLocal) return 'Staging';
  if (isStagingAny) return 'Staging';
  if (isBetaUS) return 'Beta';
  if (isBetaEU) return 'EuropeBeta';
  if (isProductionEU) return 'Europe';
  if (isProductionCA) return 'Canada';
  if (isProductionUS) return 'Production';
  // Will return before this point - after this point is for fallthrough safety
  if (isProductionCustomSubDomain) return 'Production';
  return '';
};
