import {i18n, k} from '@i18n/translate';
import {useQuery} from 'react-query';
import {IncentivesCountVM} from '@models/serverModels';
import {getIncentivesCountRq} from '@store/apiEndpoints';
import {UserGroup} from '@assets/svg/UserGroup';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {YourPlanPaths, UserPaths, ManagerPaths} from '@utils/ClientPaths';
import {useLocation} from 'react-router-dom';
import {simpleQueryFn} from '@store/queryClient';
import useGetExploreSubMenuItems from '@hooks/useGetExploreSubMenuItems';
import {cacheForeverOptions} from '@store/queryClient';
import {SubMenuKeys, NavMenuItem} from '@blocks/appNav/AppNavMenu';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import AuditOutlined from '@ant-design/icons/lib/icons/AuditOutlined';
import MessageOutlined from '@ant-design/icons/lib/icons/MessageOutlined';
import HomeOutlined from '@ant-design/icons/lib/icons/HomeOutlined';
import FlagOutlined from '@ant-design/icons/lib/icons/FlagOutlined';
import {useCustomProgramPermissionVMsQuery} from './apiEndpoints/permission/queries';
import usePathInfo from './usePathInfo';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useManagedAcademiesQueryCached} from '@generated/hooks/permission.get.hooks';
import useApprovals from '@hooks/useApprovals';

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/

function useGetUserNavMenuItems(): NavMenuItem[] {
  // Get session user data
  const {user} = useAuth();
  const {pathname} = useLocation();
  const {isAdminPath} = usePathInfo();
  const incentivesCountRq = getIncentivesCountRq();
  const incentivesCountQuery = useQuery<IncentivesCountVM>(
    incentivesCountRq.queryKey,
    () => simpleQueryFn(incentivesCountRq.path),
    cacheForeverOptions
  );
  const isApprover = user?.isManager || user?.isAcademyApprover;
  const {isFeatureFlagOn} = useFeatureFlags();

  const {data: academyPermissions} = useManagedAcademiesQueryCached(null, {
    enabled: isFeatureFlagOn.Academies && !isAdminPath,
  });

  const {data: customProgramPermissions} = useCustomProgramPermissionVMsQuery(
    null,
    {
      enabled: !isAdminPath,
    }
  );

  const {
    allPendingApprovalsCount,
    pendingProgramsTabCount,
    pendingResourcesTabCount,
  } = useApprovals('manager');

  const showManageTab =
    customProgramPermissions?.length ||
    (academyPermissions?.length && isFeatureFlagOn.Academies);

  // Get User Nav Items
  const navMenuItems: NavMenuItem[] = [
    {
      label: isFeatureFlagOn.YourPlanNameChange
        ? i18n.t(k.GENERIC__HOME)
        : i18n.t(k.PLAN__YOUR),
      icon: <HomeOutlined aria-hidden="true" />,
      path: UserPaths.ManagePlan,
      isSelected: YourPlanPaths.some((path) => pathname.includes(path)),
    },
  ];

  const exploreSubMenu = useGetExploreSubMenuItems();
  if (!isFeatureFlagOn.HideMarketplaceFromLXP && exploreSubMenu.length) {
    navMenuItems.push({
      label: i18n.t(k[SubMenuKeys.Explore]),
      icon: <SearchOutlined aria-hidden="true" />,
      path: '#',
      subItems: exploreSubMenu,
    });
  }

  if (showManageTab) {
    navMenuItems.push({
      label: i18n.t(k.CTA__MANAGE),
      icon: <FlagOutlined aria-hidden="true" />,
      path: UserPaths.Permissions,
    });
  }

  // Determine which manager nav menu items to show based on counts and flags
  const showManagerProgramsNavMenuItem =
    (isApprover &&
      !!incentivesCountQuery.data
        ?.programIncentivesAvailableToUsersWithManagerApprovalCount) ||
    !!incentivesCountQuery.data?.academiesWithCurrentUserAsAssignedApprover;

  const showManagerResourcesNavMenuItem =
    isApprover &&
    !!incentivesCountQuery.data
      ?.resourcesAvailableToUsersWithManagerApprovalCount;

  // Get Manager Nav Menu Items
  const managerNavMenuItems: any[] = [];
  if (showManagerProgramsNavMenuItem) {
    managerNavMenuItems.push({
      label: i18n.t(k.PROGRAM__PLURAL),
      path: ManagerPaths.ApprovalsProgram,
      count: pendingProgramsTabCount,
    });
  }

  if (showManagerResourcesNavMenuItem) {
    managerNavMenuItems.push({
      label: i18n.t(k.RESOURCE__PLURAL),
      path: ManagerPaths.ApprovalsResource,
      count: pendingResourcesTabCount,
    });
  }

  isApprover &&
    !isFeatureFlagOn.DisableProgressUpdates &&
    managerNavMenuItems.unshift({
      label: i18n.t(k.PROGRESS),
      path: ManagerPaths.ApprovalsProgress,
    });

  if (!!managerNavMenuItems.length) {
    navMenuItems.push({
      label: i18n.t(k[SubMenuKeys.Approvals]),
      icon: <AuditOutlined aria-hidden="true" />,
      path: '#',
      subItems: managerNavMenuItems,
      count: allPendingApprovalsCount,
    });
  }

  if (user?.isManager && isFeatureFlagOn.TeamInsights) {
    navMenuItems.push({
      label: i18n.t(k[SubMenuKeys.TeamInsights]),
      icon: <UserGroup aria-hidden="true" style={{marginRight: '10px'}} />,
      path: ManagerPaths.TeamInsights,
      isSelected: pathname.includes(ManagerPaths.TeamInsights),
    });
  }

  if (user?.isBeta) {
    navMenuItems.push({
      label: i18n.t(k.FEEDBACK),
      icon: <MessageOutlined aria-hidden="true" />,
      path: UserPaths.Feedback,
    });
  }

  return navMenuItems;
}

export default useGetUserNavMenuItems;
