import {useState, useEffect} from 'react';
import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import {
  isProductionAny,
  isLocal,
  isStagingAny,
  isDevelopmentAny,
  environment,
  isBetaAny,
} from '@utils/environment-helpers';

import mixpanel from 'mixpanel-browser';
import {useAuth} from '@utils/oidc-auth-wrapper';

const AnalyticsInit = ({children}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const {user} = useAuth();

  useEffect(() => {
    mixpanel.init(getMixpanelToken(), {debug: false});

    if (!isLocal) {
      datadogLogs.init({
        clientToken: 'pubed5c1c8199631ef9b85ac957d97b757f',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sampleRate: 100,
        env: environment,
        service: 'reactapp',
        version: '1.0.0',
      });

      datadogRum.init({
        applicationId: '85c5c044-8ee4-4699-876c-41bc1ea6bd28',
        clientToken: 'pubab719f4db22560e73be1b56856a80b9d',
        site: 'datadoghq.com',
        service: 'reactapp',
        env: environment,
        version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [
          'https://web-stage.learnin2432.com',
          'https://web-development.learnin2432.com',
          'https://dev.degreed.dev',
          'https://staging.degreed.dev',
          'https://eu.betatest.degreed.com',
          'https://*.degreed.app',
        ],
      });

      datadogRum.startSessionReplayRecording();
      const onSuccessGetUser = () => {
        if (isInitialized) return;
        identifyUser(user?.companyId, user?.userCompanyId);
        setIsInitialized(true);
      };
      onSuccessGetUser();
    }
  }, []);

  return children;
};

const identifyUser = (companyId: number, userCompanyId: number) => {
  mixpanel.identify(userCompanyId.toString());
  mixpanel.people.set({'Company Id': companyId.toString()});
  mixpanel.people.set({$name: userCompanyId.toString()});
  mixpanel.track('User Active');
};

const getMixpanelToken = () => {
  if (isLocal || isDevelopmentAny) return 'cdce7278e0492adfe2761f193234dc9d';
  if (isStagingAny) return '908df8e5cd669e11d66e41a6d2de90a7';
  if (isBetaAny) return '17a598356dae06bb2c20c9a60c9b13e2';
  if (isProductionAny) return 'f1cd72cc0ce283c20338a9ef438ecc26';
  return '';
};

export default AnalyticsInit;
