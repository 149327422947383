import {COLORS} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';
import styled from 'styled-components';

export const ProgramCompletedWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

export const ProgramCompletedText = styled.span`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0 24px 0 8px;
`;

export const ContentCompleteButtonWrapper = styled.div`
  button,
  button:hover {
    border: none;
  }
`;

export const CardContainer = styled.article<{removeBottomMargin?: boolean}>`
  box-shadow: ${COLORS.BoxShadowStandard};
  margin-bottom: ${({removeBottomMargin}) =>
    removeBottomMargin ? '0px' : '24px'};
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  .nominated-marker {
    background-color: ${COLORS.Red500} !important;
    color: ${COLORS.White} !important;
    padding: 10px;
    font-weight: 800;
    font-size: 1.25rem;
    position: absolute;
    bottom: -1px;
    right: -1px;
    opacity: 0.8;
    border-bottom-right-radius: 8px;
  }
`;

export const InnerContainer = styled.div<{notAvailable?: boolean}>`
  display: flex;
  width: 100%;
  opacity: ${({notAvailable}) => notAvailable && '25%'};
`;

export const ContentContainer = styled.div`
  border-radius: 0px 10px 10px 0px;
  padding: 24px;
  width: 100%;
  background-color: ${COLORS.White};
  @media (max-width: ${Breakpoints.Mobile}px) {
    min-width: 150px;
    border-radius: 10px;
  }
`;

export const PreviewImgContainer = styled.div`
  min-width: 210px;
  width: 210px;
  position: relative;
  @media (max-width: ${Breakpoints.Tablet}px) {
    min-width: 150px;
    max-width: 150px;
  }
  @media (max-width: ${Breakpoints.Mobile}px) {
    display: none;
  }
`;

export const MetaData = styled.div`
  color: ${COLORS.Neutral950};
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  margin: 0 0 4px;
`;

export const Description = styled.div`
  color: ${COLORS.Neutral950};
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const FootContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.Mobile}px) {
    flex-wrap: wrap;
    gap: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  button {
    margin-right: 6px;
    flex-shrink: 0;
  }
`;

export const LxpPreviewImageContainer = styled.div`
  width: 100%;
  height: 100%;
  place-items: center;
  display: grid;
  border-radius: 8px 0 0 8px;
`;

const imageConfig = {
  icon: {
    width: 'auto',
    height: 'auto',
    objectFit: 'contain',
    borderRadius: null,
  },
  regular: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: null,
  },
  proxy: {
    width: '133px',
    height: '75px',
    objectFit: 'contain',
    borderRadius: null,
  },
};

export const LxpPreviewImage = styled.img<{
  imageType: keyof typeof imageConfig;
}>`
  width: ${({imageType}) => imageConfig[imageType].width};
  height: ${({imageType}) => imageConfig[imageType].height};
  object-fit: ${({imageType}) => imageConfig[imageType].objectFit};
  border-radius: ${({imageType}) => imageConfig[imageType].borderRadius};
`;
