import {Divider, Modal} from 'antd';
import {useDeleteAcademy} from '@hooks/apiEndpoints/academy/mutations';
import {i18n, k} from '@i18n/translate';

interface UseDeleteAcademyProps {
  onSuccess: () => void;
}

export const useDeleteAcademyWithConfirm = ({
  onSuccess,
}: UseDeleteAcademyProps) => {
  const deleteAcademyMutation = useDeleteAcademy({
    onSuccess,
  });

  const handleClickDeleteProgram = async (id: number) => {
    Modal.confirm({
      icon: false,
      content: (
        <>
          <p>{i18n.t(k.ACADEMY__TABLE__DELETE_CONFIRM)} </p>
          <Divider
            style={{
              position: 'absolute',
              left: 0,
              bottom: '2.75rem',
            }}
          />
        </>
      ),
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.ACADEMY__DELETE),
      okType: 'danger',
      bodyStyle: {borderRadius: '25px'},
      title: i18n.t(k.ACADEMY__DELETE),
      okButtonProps: {type: 'primary', shape: 'round'},
      cancelButtonProps: {type: 'primary', shape: 'round', ghost: true},
      onOk() {
        deleteAcademyMutation.mutate({
          pathVars: {academyId: id},
        });
      },
    });
  };

  return {handleClickDeleteProgram};
};
