import {ReactNode} from 'react';
import './styles.scss';

interface NotificationProps {
  type?: 'warning';
  icon?: ReactNode;
  children: ReactNode;
}

export const LocalNotification = ({
  type = 'warning',
  icon,
  children,
}: NotificationProps) => {
  return (
    <div
      role="alert"
      className={`local-notification local-notification--${type}`}>
      <span className="icon">{icon}</span>
      {children}
    </div>
  );
};
