import {AcademyUsersResponse, AcademyUserVM} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {AcademyUserVMSchema} from '../zodSchemas/AcademyUserVMSchema.schema';
import {AcademyUsersResponseSchema} from '../zodSchemas/AcademyUsersResponseSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyUsersController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/users/admin 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUsersAllAdminQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesUsersAllAdmin,
 *    isLoading: isAcademiesUsersAllAdminLoading,
 *    invalidateExact: invalidateAcademiesUsersAllAdmin,
 *  } = useAcademiesUsersAllAdminQuery({
 *    academyId,
 *      queryParams: {
 *        isAscending,
 *        pageNumber,
 *        pageSize,
 *        sortBy
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAcademiesUsersAllAdminQuery = queryHookFactory<
  {
    academyId: number;
    queryParams?: {
      isAscending?: boolean;
      pageNumber?: number;
      pageSize?: number;
      sortBy?: string;
    };
  }, // path variables
  AcademyUsersResponse // return type
>(
  'useAcademiesUsersAllAdminQuery',
  ({academyId, queryParams}) =>
    addQueryParams(
      ({academyId}) => `academies/${academyId}/users/admin`,
      {academyId},
      queryParams
    ),
  {responseSchema: AcademyUsersResponseSchema}
);

// CACHED
export const useAcademiesUsersAllAdminQueryCached = queryHookFactory<
  {
    academyId: number;
    queryParams?: {
      isAscending?: boolean;
      pageNumber?: number;
      pageSize?: number;
      sortBy?: string;
    };
  }, // path variables
  AcademyUsersResponse // return type
>(
  'useAcademiesUsersAllAdminQuery',
  ({academyId, queryParams}) =>
    addQueryParams(
      ({academyId}) => `academies/${academyId}/users/admin`,
      {academyId},
      queryParams
    ),
  {responseSchema: AcademyUsersResponseSchema},
  {staleTime: 600000}
);

/*
|--------------------------------------------------------------------------
|  {academyId}/users 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUsersQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academiesUsers,
 *    isLoading: isAcademiesUsersLoading,
 *    invalidateExact: invalidateAcademiesUsers,
 *  } = useAcademiesUsersQuery({
 *    academyId,
 *      queryParams: {
 *        incompleteStepId
 *    }
 *  })
 *
 */

export const useAcademiesUsersQuery = queryHookFactory<
  {academyId: number; queryParams?: {incompleteStepId?: number}}, // path variables
  AcademyUserVM[] // return type
>(
  'useAcademiesUsersQuery',
  ({academyId, queryParams}) =>
    addQueryParams(
      ({academyId}) => `academies/${academyId}/users`,
      {academyId},
      queryParams
    ),
  {responseSchema: z.array(AcademyUserVMSchema)}
);

// CACHED
export const useAcademiesUsersQueryCached = queryHookFactory<
  {academyId: number; queryParams?: {incompleteStepId?: number}}, // path variables
  AcademyUserVM[] // return type
>(
  'useAcademiesUsersQuery',
  ({academyId, queryParams}) =>
    addQueryParams(
      ({academyId}) => `academies/${academyId}/users`,
      {academyId},
      queryParams
    ),
  {responseSchema: z.array(AcademyUserVMSchema)},
  {staleTime: 600000}
);
