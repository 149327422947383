import {i18n, k} from '@i18n/translate';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {TablePaginationConfig} from 'antd';
import LearnInTable from '@components/reusable/LearnInTable';
import {useDuplicateAcademy} from './hooks/useDuplicateAcademy';
import {useDeleteAcademyWithConfirm} from './hooks/useDeleteAcademy';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';
import {useGetColumns} from './hooks/useGetColumns';
import LoadingSpinner from '@blocks/LoadingSpinner';
import EmptyDataText from '@blocks/NoDataText';
import {useAcademiesCountsQuery} from '@generated/hooks';
import {useAcademiesWithPagination} from '@hooks/apiEndpoints/academy/queries';
import {useEffect, useState} from 'react';
import {PermissionLevel} from '../AcademiesAdminPage';
import {AcademyStatus} from '@generated/enums';

interface AcademiesTableContainerProps {
  permission: PermissionLevel;
  renderNewAcademyButton: () => JSX.Element;
  renderSearchBar: () => JSX.Element;
  renderTitleBar: (academiesExist: boolean) => JSX.Element;
  searchText: string;
  status: AcademyStatus;
}

export const AcademiesTableList = ({
  permission,
  renderNewAcademyButton,
  renderSearchBar,
  renderTitleBar,
  searchText,
  status,
}: AcademiesTableContainerProps) => {
  const PAGE_SIZE = 50;
  const isLxpAdmin = useIsLxpAdmin();
  const [academiesExist, setAcademiesExist] = useState<boolean>(false);
  const [tableParams, setTableParams] = useState<{
    pageSize?: number;
    pageNumber?: number;
    sortBy?: string;
    isAscending?: boolean;
  }>({pageSize: PAGE_SIZE, pageNumber: 1, sortBy: 'name', isAscending: true});

  // Context Menu Mutation Helper Hooks
  const {handleDuplicateAcademy} = useDuplicateAcademy();
  const {handleClickDeleteProgram} = useDeleteAcademyWithConfirm({
    onSuccess: () => {
      invalidateAcademies();
      invalidateAcademiesCount();
    },
  });

  // Queries
  const {invalidateExact: invalidateAcademiesCount} = useAcademiesCountsQuery(
    null,
    {
      enabled: false,
    }
  );
  const {
    data: academies,
    isLoading,
    invalidateExact: invalidateAcademies,
    isFetched: isAcademiesFetched,
  } = useAcademiesWithPagination({
    queryParams: {
      ...tableParams,
      isRestricted: !!permission ? permission === 'restricted' : null,
      status: status ?? null,
      searchText,
    },
  });

  useEffect(() => {
    if (academies?.results?.length && !academiesExist) {
      setAcademiesExist(true);
    }
  }, [academies?.results?.length]);

  const showSearchBar = isLxpAdmin && academiesExist;

  // Columns
  const columns = useGetColumns(
    handleClickDeleteProgram,
    handleDuplicateAcademy
  );

  // Table Pagination, Filtering, Sorting
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters,
    sorter
  ) => {
    const {current: pageNumber} = pagination;
    const {field: sortBy, order} = sorter;

    setTableParams({
      pageSize: PAGE_SIZE,
      pageNumber: pageNumber || 1,
      sortBy,
      isAscending: order === 'ascend',
    });
  };

  const renderNotFoundImg = () => {
    return (
      <div style={{display: 'flex', justifyContent: 'center', padding: '24px'}}>
        <img
          src="https://res.cloudinary.com/dsmsrmuyr/image/upload/v1740759051/3_Cats_Curious_u2hvjc.png"
          style={{maxWidth: '100%', width: '600px'}}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (!isAcademiesFetched) {
      return (
        <div
          style={{display: 'flex', justifyContent: 'center', padding: '24px'}}>
          <LoadingSpinner center size="large" />
        </div>
      );
    }
    if (!academies?.results.length) {
      if (academiesExist) {
        return (
          <div>
            {renderNotFoundImg()}
            <EmptyDataText
              header={i18n.t(k.SEARCH__NO_EXACT_MATCHES)}
              subHeader={i18n.t(k.SEARCH__TRY_CHANGE_FILTERS)}
            />
          </div>
        );
      }
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {renderNotFoundImg()}
          <EmptyDataText
            style={{paddingBottom: '24px'}}
            header={i18n.t(k.ACADEMIES__NO_CREATED_ACADEMIES)}
            subHeader={i18n.t(k.ACADEMIES__CREATED_ACADEMIES_APPEAR_HERE)}
          />
          {renderNewAcademyButton?.() || null}
        </div>
      );
    }

    return (
      <LearnInTable
        dataSource={academies?.results}
        scroll={{x: 'max-content'}}
        columns={columns}
        pagination={true}
        isLoading={isLoading}
        onChange={handleTableChange}
        totalPages={academies?.totalPages}
        pageSize={academies?.pageSize}
        currentPage={tableParams?.pageNumber}
      />
    );
  };

  return (
    <PageContentLayout fullWidth>
      <div style={{}}>
        {renderTitleBar?.(academiesExist)}
        {!!showSearchBar ? (
          <div style={{marginBottom: '24px'}}>{renderSearchBar?.()}</div>
        ) : null}
        {renderContent()}
      </div>
    </PageContentLayout>
  );
};
