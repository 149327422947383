import './ManagerApprovalsList.scss';
import {useEffect, useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Col, Input, Row, Tabs, Typography} from 'antd';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {ArrowRightOutlined, WarningOutlined} from '@ant-design/icons';
import {
  applyLicenseCurrencyOrDefault,
  formatCurrency,
  getExchangeRateOfCurrencyPair,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import moment from 'moment';
import ManagerPreApprovalDrawer from '../ManagerPreApprovalDrawer/ManagerPreApprovalDrawer';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {
  AdminScreenApprovalStatus,
  ApprovalsDiscriminatorWithTime,
  ManagerApprovalsTabsName,
} from '@models/clientEnums';
import {COLORS} from '@utils/constants';
import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  ApprovalType,
  UserRequestType,
} from '@generated/enums';
import {useMutation, useQuery} from 'react-query';
import {
  ApprovalRequestDetailsVM,
  CostCenterOverridePayload,
  UserRequestApprovalVM,
  UserRequestDetailsVM,
} from '@models/serverModels';
import {
  parallelQueryResults,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import {notify} from '@components/user/notifications';
import NoDataText from '@blocks/NoDataText';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import LearnInTable, {
  ColumnAlignment,
  DefaultSortOrder,
  IColumn,
  stringSorter,
  TableCell,
} from '@components/reusable/LearnInTable';
import {ViewProgramLink} from '@components/admin/pages/reports/components/ViewProgramLink';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  LearnInButton,
  LinkStyledButton,
} from '@components/reusable/Button/Button.style';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useGetCostCentersVMQuery} from '@hooks/apiEndpoints/company/queries';
import useTrackIds from '@hooks/useTrackIds';
import {debounce} from 'lodash';
import {filterQuery} from '@utils/filterUtils';
import {useUpdateCostCenterAsManager} from '@hooks/apiEndpoints/managerApprovals/mutations';
import {getManagerUserRequestApprovalsRq} from '@store/apiEndpoints';
import {transformApprovalResponse} from '@utils/itemUtils';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import {DATE_FORMAT} from '@utils/timeUtils';
import {getUserRequestType} from '@utils/getUserRequestType';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {localizeResourceType} from '@utils/l10nUtils';
import {InlineSelect} from '@components/reusable/InlineSelect';
import {useGetCostCenterOptions} from '@components/reusable/CostCenterField/CostCenterField.hooks';
import Icon from '@blocks/Icon/Icon';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {AdminPaths} from '@utils/ClientPaths';

type ApprovalRequestRowVM = UserRequestApprovalVM & {hasLicense?: boolean};

export const ExceedsRemainingBudgetWarning = ({amount}: {amount: number}) => {
  return (
    <LearnInTooltip title={i18n.t(k.FUND__BUDGET__EXCEEDED__FORMAT, {amount})}>
      <WarningOutlined
        aria-hidden="true"
        style={{color: COLORS.Yellow500, marginLeft: '9px'}}
      />
    </LearnInTooltip>
  );
};

/*
|--------------------------------------------------------------------------
| Page Component
|--------------------------------------------------------------------------
*/

interface ManagerApprovalsProps {
  approvalItemType: ApprovalsDiscriminator;
  title?: string;
}

export default function ManagerApprovals({
  approvalItemType,
  title,
}: ManagerApprovalsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const openDrawerId = searchParams.get('drawer');
  const selectedTabName =
    searchParams.get('tab') || ApprovalStatus.Requested.toString();

  const toggleDrawer = (drawerId) =>
    drawerId
      ? setSearchParams({
          drawer: drawerId,
          tab: selectedTabName,
        })
      : setSearchParams({tab: selectedTabName});

  const toggleTab = (tabName) =>
    setSearchParams({tab: tabName, ...searchParams});

  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const {isFeatureFlagOn} = useFeatureFlags();
  const isCostCenterOn = isFeatureFlagOn.CostCenter;
  const isLearnInReimbursementsOn = isFeatureFlagOn.LearnInReimbursements;

  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState(
    ApprovalStatus.Requested
  );
  const [selectedApproval, setSelectedApproval] = useState(null);
  const [programSearchText, setProgramSearchText] = useState('');

  const isFinancePage = approvalItemType === ApprovalsDiscriminator.Incentive;
  const isTimePage =
    approvalItemType ===
    (ApprovalsDiscriminatorWithTime.Time as unknown as ApprovalsDiscriminator);
  const isProgramPage = approvalItemType === ApprovalsDiscriminator.Program;
  const isResourcePage =
    approvalItemType === ApprovalsDiscriminator.LearningResource;
  const {ids: loadingIds, updateIds: updateLoadingIds} = useTrackIds();

  // Fetch table data
  const managerApprovalsRq = getManagerUserRequestApprovalsRq(
    approvalItemType,
    selectedApprovalStatus
  );
  const managerResourceApprovalsRq = getManagerUserRequestApprovalsRq(
    ApprovalsDiscriminator.LearningResource,
    selectedApprovalStatus
  );

  const ApprovalRq = isResourcePage
    ? managerResourceApprovalsRq
    : managerApprovalsRq;
  const getManagerApprovalsQuery = useQuery<ApprovalRequestDetailsVM[]>(
    ApprovalRq.queryKey,
    () => simpleQueryFn(ApprovalRq.path),
    {}
  );
  const userRequestApprovalsRq = getManagerUserRequestApprovalsRq(
    ApprovalsDiscriminator.License,
    selectedApprovalStatus
  );
  const getManagerUserRequestApprovalsQuery = useQuery<UserRequestDetailsVM[]>(
    userRequestApprovalsRq.queryKey,
    () => simpleQueryFn(userRequestApprovalsRq.path, transformApprovalResponse)
  );

  const getCombinedManagerApprovalsQuery = parallelQueryResults({
    queries: [getManagerApprovalsQuery, getManagerUserRequestApprovalsQuery],
    sortBy: (a, b) =>
      new Date(a.requestedDate).getTime() - new Date(b.requestedDate).getTime(),
    filterBy: (request) => {
      if (isResourcePage) {
        return !request.hasLicense && !request?.requestedApproval?.hasLicense;
      }
      return true;
    },
  });

  const {data: costCentersList} = useGetCostCentersVMQuery(null, {
    enabled: isCostCenterOn,
  });

  const costCenters = costCentersList?.length ? [...costCentersList] : null;
  const isCostCenterAvailable = isCostCenterOn && costCenters;
  const costCenterOptions = useGetCostCenterOptions(costCenters);

  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: true,
  });
  const exchangeRatesList = getCurrencyExchangeQuery?.data;

  const UpdateCostCenterAsManagerMutation = useUpdateCostCenterAsManager({
    onSuccess: async () => {
      await getCombinedManagerApprovalsQuery.refetch();
    },
  });

  const updateCostCenter = async (
    costCenter: string,
    userRequestApprovalId: number,
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    if (onSuccess) onSuccess();
    const payload: CostCenterOverridePayload = {
      costCenter,
      userRequestApprovalId,
    };
    return await UpdateCostCenterAsManagerMutation.mutateAsync(payload, {
      onError, // rollback to original if update failed
    });
  };

  const commonRefetch = async () => {
    await getCombinedManagerApprovalsQuery.refetch();
  };

  const commonResetDrawer = async () => {
    await commonRefetch();
  };
  const closeDrawer = () => {
    toggleDrawer(null);
    setSelectedApproval(null);
    commonResetDrawer();
  };
  let columns: IColumn[] = [
    {
      title: <>{i18n.t(k.GENERIC__TITLE)}</>,
      dataIndex: 'title',
      key: 'title',
      render: (title, row: ApprovalRequestRowVM) => {
        return (
          <Row align="middle">
            <Col xs={24}>
              <button onClick={(e) => e.stopPropagation()}>
                {isProgramPage ? (
                  <ViewProgramLink id={row.itemId}>{title}</ViewProgramLink>
                ) : (
                  <Typography.Text className="program-title">
                    {title}
                  </Typography.Text>
                )}
              </button>
              <br />
            </Col>
          </Row>
        );
      },
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: i18n.t(k.NAME),
      dataIndex: 'requestedBy',
      key: 'requestedBy',
      sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy),
      render: (_, row: UserRequestApprovalVM) => {
        return (
          <TableCell>
            <Row align="middle">
              <Col xs={24} onClick={(e) => e.stopPropagation()}>
                <LinkStyledButton
                  onClick={() =>
                    navigate(`${AdminPaths.People}/${row.requestedById}`, {
                      state: {
                        from: `${location.pathname + location.search}`,
                      },
                    })
                  }>
                  {row.requestedBy}
                </LinkStyledButton>
              </Col>
            </Row>
          </TableCell>
        );
      },
    },
    {
      title: i18n.t(k.FUND__REQUEST__AMOUNT),
      align: ColumnAlignment.Right,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, row: ApprovalRequestRowVM) => {
        const itemExchangeRate = getExchangeRateOfCurrencyPair(
          exchangeRatesList,
          getUserQuery.data?.currency,
          applyLicenseCurrencyOrDefault(row)
        );
        return (
          <span data-cy="estimatedCost">
            {isTimePage ? (
              `${amount} ${row.amountType}`.toLocaleLowerCase()
            ) : (
              <>
                {formatCurrency(
                  amount,
                  getUserQuery.data?.currency,
                  itemExchangeRate,
                  {decimal: true}
                )}
                {!!row.amountOverLimit && (
                  <ExceedsRemainingBudgetWarning amount={row.balance} />
                )}
              </>
            )}
          </span>
        );
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: i18n.t(k.DATE__REQUESTED),
      dataIndex: 'requestedDate',
      key: 'requestedDate',
      sorter: (date1, date2) => {
        const dateObject1 = new Date(date1.requestedDate);
        const dateObject2 = new Date(date2.requestedDate);
        if (dateObject1 < dateObject2) {
          return -1;
        }
        if (dateObject1 > dateObject2) {
          return 1;
        }
        return 0;
      },
      defaultSortOrder: DefaultSortOrder.Descend,
      render: (requestedDate) =>
        moment(requestedDate).format(DATE_FORMAT.MONTH_DAY_YEAR),
    },
  ];

  const resourcesApprovalColumns: IColumn[] = [
    {
      title: <>{i18n.t(k.NAME)}</>,
      dataIndex: 'requestedBy',
      key: 'requestedBy',
      render: (_, row: UserRequestApprovalVM) => {
        return (
          <TableCell>
            <Row align="middle">
              <Col xs={24} onClick={(e) => e.stopPropagation()}>
                <LinkStyledButton
                  onClick={() =>
                    navigate(`${AdminPaths.People}/${row.requestedById}`, {
                      state: {
                        from: `${location.pathname + location.search}`,
                      },
                    })
                  }>
                  {row.requestedBy}
                </LinkStyledButton>
              </Col>
            </Row>
          </TableCell>
        );
      },
      sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy),
    },
    {
      title: i18n.t(k.DATE__REQUESTED),
      dataIndex: 'requestedDate',
      key: 'requestedDate',
      sorter: (date1, date2) => {
        const dateObject1 = new Date(date1.requestedDate);
        const dateObject2 = new Date(date2.requestedDate);
        if (dateObject1 < dateObject2) {
          return -1;
        }
        if (dateObject1 > dateObject2) {
          return 1;
        }
        return 0;
      },
      defaultSortOrder: DefaultSortOrder.Descend,
      render: (requestedDate) =>
        moment(requestedDate).format(DATE_FORMAT.MONTH_DAY_YEAR),
    },
    {
      title: i18n.t(k.RESOURCE),
      dataIndex: 'title',
      key: 'title',
      render: (_, row: ApprovalRequestRowVM) => {
        return localizeResourceType(row.title);
      },
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: i18n.t(k.FUND__REQUEST__AMOUNT),
      align: ColumnAlignment.Right,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, row: ApprovalRequestRowVM) => {
        return (
          <span data-cy="estimatedCost">
            {isTimePage ? (
              `${amount} ${row.amountType}`.toLocaleLowerCase()
            ) : (
              <>
                {formatCurrency(
                  amount,
                  getUserQuery.data?.currency,
                  exchangeRate,
                  {decimal: true}
                )}
                {!!row.amountOverLimit && (
                  <ExceedsRemainingBudgetWarning amount={row.balance} />
                )}
              </>
            )}
          </span>
        );
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    isLearnInReimbursementsOn && {
      title: i18n.t(k.GENERIC__TYPE),
      dataIndex: 'userRequestType',
      key: 'userRequestType',
      render: (userRequestType: UserRequestType) =>
        getUserRequestType(userRequestType),
      sorter: (a, b) => a.userRequestType - b.userRequestType,
    },
  ];

  const viewDetailsColumn: IColumn = {
    title: '',
    dataIndex: 'viewDetails',
    fixed: 'right',
    width: 100,
    key: 'viewDetails',
    render: (_, row: ApprovalRequestRowVM) => (
      <LearnInButton
        tag={ButtonTags.Link}
        color={COLORS.Blue800}
        fontSize={'1rem'}
        fontWeight={'500'}
        hoverUnderline
        className="btn-admin-approval-view-details"
        data-cy="viewDetails"
        onClick={(e) => {
          e.stopPropagation();
          if (loadingIds.includes(row.id)) return;
          setSelectedApproval(row);
          toggleDrawer(row.id);
        }}>
        {selectedApprovalStatus === ApprovalStatus.Requested
          ? i18n.t(k.GENERIC__REVIEW)
          : i18n.t(k.GENERIC__DETAIL__PLURAL)}
        <Icon icon={<ArrowRightOutlined />} />
      </LearnInButton>
    ),
    sorter: null,
  };

  const costCenterColumn = {
    title: i18n.t(k.COST_CENTER),
    dataIndex: 'costCenter',
    key: 'provider',
    render: (provider, row) => {
      const originalValue = row.costCenter;
      const updateInlineValue = (value: string) => {
        row.costCenter = value;
      };
      return (
        <button onClick={(e) => e.stopPropagation()} style={{width: '100%'}}>
          <InlineSelect
            onSubmit={(value) =>
              updateCostCenter(
                value,
                row.id,
                () => updateInlineValue(value),
                () => updateInlineValue(originalValue)
              )
            }
            value={provider}
            options={costCenterOptions}
            readOnly={false}
            notFoundContent={i18n.t(k.GENERIC__NOT_FOUND)}
          />
        </button>
      );
    },
    sorter: stringSorter('provider'),
  };

  const typeColumn = {
    title: i18n.t(k.GENERIC__TYPE),
    dataIndex: 'userRequestType',
    key: 'userRequestType',
    render: (userRequestType: UserRequestType) =>
      getUserRequestType(userRequestType),
    sorter: (a, b) => a.userRequestType - b.userRequestType,
  };
  //Make Type column visible in all tables
  if (isLearnInReimbursementsOn) columns = [...columns, typeColumn];

  if (isProgramPage) {
    const providerColumn = {
      title: i18n.t(k.PROVIDER),
      dataIndex: 'provider',
      key: 'provider',
      render: (provider) => provider,
      sorter: (a, b) => a.provider?.localeCompare(b.provider),
    };

    columns = [providerColumn, ...columns];

    if (isCostCenterAvailable) {
      columns = [...columns, costCenterColumn];
    }

    resourcesApprovalColumns.push(providerColumn);
  }

  const activeTab: ManagerApprovalsTabsName = (() => {
    const tab = parseInt(selectedTabName);
    switch (tab) {
      case ApprovalStatus.Approved:
        return ManagerApprovalsTabsName.APPROVED;
      case ApprovalStatus.Rejected:
        return ManagerApprovalsTabsName.REJECTED;
      case ApprovalStatus.Requested:
      default:
        return ManagerApprovalsTabsName.REQUESTS;
    }
  })();

  const handleChangeTab = (key) => {
    switch (key) {
      case ManagerApprovalsTabsName.REQUESTS:
        toggleTab(ApprovalStatus.Requested);
        return setSelectedApprovalStatus(ApprovalStatus.Requested);
      case ManagerApprovalsTabsName.APPROVED:
        toggleTab(ApprovalStatus.Approved);
        return setSelectedApprovalStatus(ApprovalStatus.Approved);
      case ManagerApprovalsTabsName.REJECTED:
        toggleTab(ApprovalStatus.Rejected);
        return setSelectedApprovalStatus(ApprovalStatus.Rejected);
    }
  };

  columns.push(viewDetailsColumn);
  resourcesApprovalColumns.push(viewDetailsColumn);

  const pageTitle = (() => {
    if (isFinancePage) return i18n.t(k.APPROVAL__FINANCE__PLURAL);
    if (isTimePage) return i18n.t(k.APPROVAL__TIME__PLURAL);
    if (isProgramPage) return i18n.t(k.APPROVAL__PROGRAM__PLURAL);
    if (isResourcePage) return i18n.t(k.APPROVAL__RESOURCE__PLURAL);
    return null;
  })();

  useEffect(() => {
    if (title) document.title = title;
  }, [pageTitle]);

  const getTableDataSource = useMemo(() => {
    const searchParameters = ['title', 'requestedBy', 'provider'];
    const data = getCombinedManagerApprovalsQuery.data.map((a) => {
      a.requestedApproval.parentApproval = a.parentApproval;
      return a.requestedApproval;
    });
    return filterQuery(data, programSearchText, searchParameters);
  }, [
    programSearchText,
    selectedApprovalStatus,
    getCombinedManagerApprovalsQuery.data,
  ]);

  useEffect(() => {
    if (selectedTabName) setSelectedApprovalStatus(parseInt(selectedTabName));
  }, [selectedTabName]);

  useEffect(() => {
    getTableDataSource.filter((item) => {
      if (item.id === parseInt(openDrawerId)) setSelectedApproval(item);
    });
  }, [openDrawerId, getTableDataSource]);

  const handleSearch = (e) => {
    const searchText = e.target.value;
    searchWithDebounce(searchText);
  };

  const searchWithDebounce = debounce((searchText) => {
    setProgramSearchText(searchText);
  }, 500);

  return (
    <>
      <PageTitleBar fullWidth={true} title={pageTitle} />
      {selectedApproval && (
        <Row>
          <ManagerPreApprovalDrawer
            approvalId={selectedApproval?.id}
            approvalType={selectedApproval?.type}
            showPreApprovalDrawer={
              openDrawerId === selectedApproval?.id || selectedApproval?.id
            }
            activeTab={selectedApprovalStatus}
            isReadonly={selectedApprovalStatus != ApprovalStatus.Requested}
            licenseId={
              selectedApproval &&
              ('licenseId' in selectedApproval
                ? selectedApproval?.licenseId
                : null)
            }
            updateCostCenter={updateCostCenter}
            selectedCostCenter={selectedApproval?.costCenter}
            onOk={closeDrawer}
            onCancel={closeDrawer}
            selectedApproval={selectedApproval}
            userRequestType={
              selectedApproval &&
              ('userRequestType' in selectedApproval
                ? getUserRequestType(selectedApproval?.userRequestType)
                : null)
            }
          />
        </Row>
      )}
      <Row className="approvals-list">
        <LearnInTabs
          defaultActiveKey={ManagerApprovalsTabsName.REQUESTS}
          activeKey={activeTab}
          tabBarGutter={50}
          onChange={handleChangeTab}>
          <Tabs.TabPane
            tab={i18n.t(k.REQUEST__PLURAL)}
            key={ManagerApprovalsTabsName.REQUESTS}
            style={{outline: 'none'}}
          />
          <Tabs.TabPane
            tab={i18n.t(k.STATUS__APPROVED)}
            key={ManagerApprovalsTabsName.APPROVED}
          />
          <Tabs.TabPane
            tab={i18n.t(k.STATUS__DECLINED)}
            key={ManagerApprovalsTabsName.REJECTED}
          />
        </LearnInTabs>
        <Input.Search
          allowClear
          onChange={handleSearch}
          placeholder={i18n.t(k.APPROVAL__PLURAL__SEARCH)}
          style={{width: '400px', padding: '10px 32px'}}
        />
        <Col xs={24} md={24}>
          {getCombinedManagerApprovalsQuery.isSuccess &&
            getTableDataSource?.length > 0 && (
              <LearnInContainer tag={ContainerTags.TableBottomPadding}>
                <LearnInTable
                  dataSource={getTableDataSource}
                  scroll={{x: 'max-content'}}
                  columns={isResourcePage ? resourcesApprovalColumns : columns}
                />
              </LearnInContainer>
            )}
          {/* only display loading animation/icon when data is fetching and there isn't any data already loaded to prevent bounce */}
          {getCombinedManagerApprovalsQuery.isFetching &&
            !getCombinedManagerApprovalsQuery.data && <LoadingSpinner />}
          {getTableDataSource?.length === 0 && (
            <>
              {programSearchText !== '' ? (
                <NoDataText
                  header={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
                  subHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__DESCRIPTION)}
                />
              ) : (
                <>
                  {isFinancePage && (
                    <>
                      {activeTab === ManagerApprovalsTabsName.REJECTED && (
                        <NoDataText
                          header={i18n.t(
                            k.APPROVAL__HAVE_NOT__REJECTED_FINANCES
                          )}
                          subHeader={i18n.t(
                            k.APPROVAL__REJECTED_TIME__DESCRIPTION__TEAM
                          )}
                        />
                      )}
                      {activeTab === ManagerApprovalsTabsName.APPROVED && (
                        <NoDataText
                          header={i18n.t(
                            k.APPROVAL__HAVE_NOT__APPROVED_FINANCES
                          )}
                          subHeader={i18n.t(
                            k.APPROVAL__APPROVED_FINANCE__DESCRIPTION__TEAM
                          )}
                        />
                      )}
                      {activeTab === ManagerApprovalsTabsName.REQUESTS && (
                        <NoDataText
                          header={i18n.t(
                            k.APPROVAL__TEAM_HASNT_APPLIED__FINANCE_INCENTIVE
                          )}
                          subHeader={i18n.t(
                            k.APPROVAL__REQUESTED_FINANCE__DESCRIPTION__TEAM
                          )}
                        />
                      )}
                    </>
                  )}
                  {isTimePage && (
                    <>
                      {activeTab === ManagerApprovalsTabsName.REJECTED && (
                        <NoDataText
                          header={i18n.t(
                            k.APPROVAL__HAVE_NOT__REJECTED_INCENTIVES
                          )}
                          subHeader={i18n.t(
                            k.APPROVAL__REJECTED_TIME__DESCRIPTION__TEAM
                          )}
                        />
                      )}
                      {activeTab === ManagerApprovalsTabsName.APPROVED && (
                        <NoDataText
                          header={i18n.t(
                            k.APPROVAL__HAVE_NOT__APPROVED_INCENTIVES
                          )}
                          subHeader={i18n.t(
                            k.APPROVAL__APPROVED_TIME__DESCRIPTION__TEAM
                          )}
                        />
                      )}
                      {activeTab === ManagerApprovalsTabsName.REQUESTS && (
                        <NoDataText
                          header={i18n.t(
                            k.APPROVAL__TEAM_HASNT_APPLIED__TIME_INCENTIVE
                          )}
                          subHeader={i18n.t(
                            k.APPROVAL__REQUESTED_TIME__DESCRIPTION__TEAM
                          )}
                        />
                      )}
                    </>
                  )}
                  {isProgramPage && (
                    <>
                      {activeTab === ManagerApprovalsTabsName.REJECTED && (
                        <NoDataText
                          header={i18n.t(k.APPROVAL__REJECTED__TITLE)}
                          subHeader={i18n.t(k.APPROVAL__REJECTED__DESCRIPTION)}
                        />
                      )}
                      {activeTab === ManagerApprovalsTabsName.APPROVED && (
                        <NoDataText
                          header={i18n.t(k.APPROVAL__APPROVED__TITLE)}
                          subHeader={i18n.t(k.APPROVAL__APPROVED__DESCRIPTION)}
                        />
                      )}
                      {activeTab === ManagerApprovalsTabsName.REQUESTS && (
                        <NoDataText
                          header={i18n.t(k.APPROVAL__PENDING__TITLE)}
                          subHeader={i18n.t(k.APPROVAL__PENDING__DESCRIPTION)}
                        />
                      )}
                    </>
                  )}
                  {isResourcePage && (
                    <>
                      {activeTab === ManagerApprovalsTabsName.REJECTED && (
                        <NoDataText
                          header={i18n.t(k.APPROVAL__REJECTED__TITLE)}
                          subHeader={i18n.t(k.APPROVAL__REJECTED__DESCRIPTION)}
                        />
                      )}
                      {activeTab === ManagerApprovalsTabsName.APPROVED && (
                        <NoDataText
                          header={i18n.t(k.APPROVAL__APPROVED__TITLE)}
                          subHeader={i18n.t(k.APPROVAL__APPROVED__DESCRIPTION)}
                        />
                      )}
                      {activeTab === ManagerApprovalsTabsName.REQUESTS && (
                        <NoDataText
                          header={i18n.t(k.APPROVAL__PENDING__TITLE)}
                          subHeader={i18n.t(k.APPROVAL__PENDING__DESCRIPTION)}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
