import React, {useState} from 'react';
import {ForwardOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {AcademyStepVM} from '@generated/interfaces';
import {DisplayStatus} from '@generated/enums';
import {ContentCompleteButton} from '@blocks/CustomProgramUserDrawer/ContentCompleteButton';
import {i18n, k} from '@i18n/translate';
import {LearnInButton} from '../../Button/Button.style';
import {ButtonTags} from '../../Button/ButtonEnums';
import {
  ButtonsContainer,
  CardContainer,
  ContentCompleteButtonWrapper,
  ContentContainer,
  Description,
  FootContainer,
  InnerContainer,
  MetaData,
} from './LxpContentCard.styled';
import {
  getBlobUrl,
  getDefaultContentImage,
  getMetaData,
} from './LxpContentCard.utils';
import SearchCardHeader from '../SearchCardHeader';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import {LxpContentImage} from './LxpContentImage';

const ProgramSkippedWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const ProgramSkippedText = styled.span`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0 24px 0 8px;
`;

interface LxpContentCardProps {
  step: AcademyStepVM;
  onToggleCompletion: () => void;
  smallImageUrl: string;
  isCompleted: boolean;
  contextMenu?: React.ReactElement;
  isSkipped?: boolean;
}

const LxpContentCard: React.FC<LxpContentCardProps> = ({
  contextMenu,
  step,
  onToggleCompletion,
  smallImageUrl,
  isCompleted,
  isSkipped,
}) => {
  const {icon} = getDefaultContentImage(step.lxpContentType);
  const [image] = useState<string>(getBlobUrl(smallImageUrl) || icon);
  const handleClickViewContent = () => {
    window.open(step.links[0].url, '_blank', 'noopener noreferrer');
  };
  const isVerifiedCompleted =
    step.status.displayStatus === DisplayStatus.VerifiedCompleted;

  const viewContentBtn = (
    <LearnInButton
      aria-label={i18n.t(k.CTA__VIEW_ITEM__FORMAT, {
        item: step.title,
      })}
      key="viewContent"
      tag={ButtonTags.Secondary}
      onClick={handleClickViewContent}>
      {i18n.t(k.A11Y__VIEW_ITEM__FORMAT, {item: i18n.t(k.CONTENT)})}
    </LearnInButton>
  );
  const markCompleteButton = (
    <ContentCompleteButtonWrapper>
      <ContentCompleteButton
        title={step.title}
        key="complete"
        completed={isCompleted}
        onClick={onToggleCompletion}
        disabled={!step.isCompletable || isVerifiedCompleted}
        isVerifiedCompleted={isVerifiedCompleted}
      />
    </ContentCompleteButtonWrapper>
  );

  const metadata = getMetaData(step);

  // For pathways and content that is verified complete show mark complete button
  // but only when item is completed
  let buttons = step.isCompletable
    ? [viewContentBtn, markCompleteButton]
    : isCompleted || isVerifiedCompleted
      ? [viewContentBtn, markCompleteButton]
      : [viewContentBtn];

  if (isSkipped) {
    buttons = [
      <ProgramSkippedWrapper key="skipped">
        <ForwardOutlined
          aria-hidden="true"
          style={{
            color: COLORS.Neutral600,
            fontSize: '1.375rem',
          }}
        />
        <ProgramSkippedText>{i18n.t(k.STATUS__SKIPPED)}</ProgramSkippedText>
      </ProgramSkippedWrapper>,
    ];
  }

  return (
    <CardContainer
      aria-label={`${i18n.t(k.GENERIC__SEARCH_RESULTS)}: ${step.title}`}
      data-cy={`lxp-content-${step.id}`}
      removeBottomMargin={true}>
      <InnerContainer>
        <LxpContentImage
          image={image}
          title={step.title}
          contentType={step.lxpContentType}
        />
        <ContentContainer>
          <SearchCardHeader
            provider={undefined}
            providerImgSrc={''}
            title={step.title}
            headerData={null}
          />
          {metadata ? <MetaData>{metadata}</MetaData> : null}
          <Description>
            {DOMPurify.sanitize(step.description, {ALLOWED_TAGS: []})}
          </Description>
          <FootContainer>
            <ButtonsContainer>
              {buttons}
              {contextMenu}
            </ButtonsContainer>
          </FootContainer>
        </ContentContainer>
      </InnerContainer>
    </CardContainer>
  );
};

export default LxpContentCard;
