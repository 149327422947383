import {LearnInTooltip} from '@components/reusable/Tooltip';
import {Container, Title} from './SectionNavCard.styled';
import {SectionNavCardViewProps} from './SectionNavCard.interfaces';
import {Progress, Skeleton} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import {convertHexToHSL} from '@utils/color';
import CheckMark from '@assets/svg/CheckMark';
import {Breakpoints} from '@utils/responsiveUtils';

function SectionNavCardView({
  title,
  textColor,
  backgroundColor,
  borderColor,
  hoverBorderColor,
  percentComplete,
  isSkeleton,
  showLock,
  showPercentComplete,
  onClick,
  blockingTitle,
}: SectionNavCardViewProps) {
  const blockedToolTipCopy = `Complete ${blockingTitle} to unlock`;
  const renderTitle = () => {
    if (showLock) {
      return (
        <LearnInTooltip title={blockedToolTipCopy}>
          <Title>{title}</Title>
        </LearnInTooltip>
      );
    }
    return <Title>{title}</Title>;
  };
  return (
    <Container
      aria-label={`Select section ${title}`}
      onClick={onClick}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      textColor={textColor}
      hoverBorderColor={hoverBorderColor}>
      <div
        style={{
          color: textColor,
          marginRight: '12px',
          maxHeight: '100%',
          overflowY: 'hidden',
        }}>
        {isSkeleton ? (
          <div style={{width: '200px'}}>
            <Skeleton active paragraph={{rows: 1}} />
          </div>
        ) : (
          <>{renderTitle()}</>
        )}
      </div>
      <div>
        {showPercentComplete &&
          (percentComplete !== null && percentComplete !== 100 ? (
            <Progress
              showInfo={window.innerWidth > Breakpoints.Desktop}
              style={{
                transform:
                  window.innerWidth > Breakpoints.Desktop ? null : 'scale(0.5)',
              }}
              strokeLinecap="butt"
              type="circle"
              percent={percentComplete}
              strokeColor={textColor}
              trailColor={convertHexToHSL(textColor, 0.5)}
              strokeWidth={window.innerWidth > Breakpoints.Desktop ? 8 : 18}
            />
          ) : (
            <div
              style={{
                background: convertHexToHSL(backgroundColor, 0.1),
                width: '45px',
                height: '45px',
                paddingTop: '13px',
                textAlign: 'center',
                borderRadius: '100px',
              }}>
              <CheckMark
                aria-hidden="true"
                fill={textColor}
                style={{
                  height: '13px',
                  width: '17px',
                }}
              />
            </div>
          ))}
        {showLock && (
          <LearnInTooltip title={blockedToolTipCopy}>
            <LockOutlined
              style={{
                color: convertHexToHSL(textColor, 0.5),
                fontSize:
                  window.innerWidth > Breakpoints.Desktop ? '24px' : '18px',
              }}
            />
          </LearnInTooltip>
        )}
      </div>
    </Container>
  );
}

export default SectionNavCardView;
