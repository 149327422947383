import {useNavigate} from 'react-router-dom';
import {useDuplicateAcademyMutation} from '@generated/hooks';
import {editAcademyUrl} from '@utils/ClientPaths';

export const useDuplicateAcademy = () => {
  const navigate = useNavigate();
  const duplicateAcademyMutation = useDuplicateAcademyMutation();

  const handleDuplicateAcademy = (academyId: number) => {
    duplicateAcademyMutation.mutate(
      {pathVars: {academyId}},
      {
        onSuccess: (newAcademyId) => {
          navigate(editAcademyUrl(newAcademyId.toString()));
        },
      }
    );
  };

  return {handleDuplicateAcademy};
};
