import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {ButtonTags} from '@components/../components/reusable/Button/ButtonEnums';
import {ExternalLinkIcon} from '@assets/svg/ExternalLinkIcon';
import {LxpAdminFragment, viewAcademyUrlInternal} from '@utils/ClientPaths';
import {ClickToJoinShareButton} from '@components/ClickToJoin/ClickToJoinShareButton';
/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/
const HeaderLink = styled.a`
  color: ${COLORS.Blue800};
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  margin-right: 24px;
  display: flex;
  align-items: center;
  &:hover,
  &:focus {
    color: ${COLORS.Blue950} !important;
    text-decoration: none !important;
  }
`;
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export interface SetupAcademyHeaderProps {
  onClickPublish: () => void;
  onClickPreview: (url: string) => void;
  showPublishButton: boolean;
  academyId: string;
  hidePreviewAcademyLink: boolean;
  isLxpAdmin: boolean;
  canShowShareableLink?: boolean;
  onClickShareAcademy?: () => void;
  academyUserCount?: number;
  shouldSendEmailInvite?: boolean;
}

function SetupAcademyHeader({
  shouldSendEmailInvite,
  onClickPublish,
  onClickPreview,
  showPublishButton,
  academyId,
  hidePreviewAcademyLink,
  canShowShareableLink,
  onClickShareAcademy,
  academyUserCount,
  isLxpAdmin,
}: SetupAcademyHeaderProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const previewHref = (() => {
    let url = viewAcademyUrlInternal(academyId);

    // Keep context being an LXP admin, otherwise it's lost in the new window
    if (isLxpAdmin) {
      // viewAcademyUrlExternal doesn't include a hash query string, so append
      url = `${url}?${LxpAdminFragment}`;
    }

    return url;
  })();

  return (
    <div style={{display: 'flex'}}>
      {showConfirmation && (
        <LearnInModal
          visible={true}
          tag={ModalTags.Default}
          onCancel={() => setShowConfirmation(false)}
          cancelButtonChild={i18n.t(k.CTA__CANCEL)}
          okButtonChild={i18n.t(k.ACADEMY__PUBLISH)}
          title={i18n.t(k.ACADEMY__PUBLISH)}
          onOk={() => {
            onClickPublish();
            setShowConfirmation(false);
          }}>
          <div style={{padding: '24px'}}>
            {academyUserCount ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n.t(k.ACADEMY__PUBLISH_WARNING__FORMAT, {
                    count: academyUserCount,
                  }),
                }}></span>
            ) : (
              i18n.t(k.ACADEMY__PUBLISH_WARNING)
            )}
          </div>
        </LearnInModal>
      )}
      {canShowShareableLink && (
        <ClickToJoinShareButton
          label={i18n.t(k.CTA__COPY_ACADEMY_LINK)}
          onClick={onClickShareAcademy}></ClickToJoinShareButton>
      )}
      {!hidePreviewAcademyLink && (
        <HeaderLink
          onClick={() => {
            onClickPreview(previewHref);
          }}
          data-testid="preview-academy-link">
          <ExternalLinkIcon
            style={{marginRight: '8px', fill: COLORS.Neutral600}}
          />
          {i18n.t(k.ACADEMY__PREVIEW__VERB)}
        </HeaderLink>
      )}
      {showPublishButton && (
        <LearnInButton
          data-testid="publish-academy-button"
          tag={ButtonTags.Primary}
          onClick={() => {
            if (shouldSendEmailInvite) {
              setShowConfirmation(true);
            } else {
              onClickPublish();
            }
          }}>
          {i18n.t(k.ACADEMY__PUBLISH)}
        </LearnInButton>
      )}
    </div>
  );
}

export default SetupAcademyHeader;
