import Grid from '@blocks/Grid';
import {ModalTitle} from '../../programs/customPrograms/setup/CustomProgramSetupScreen';
import {i18n, k} from '@i18n/translate';
import {MemberSelectOption} from './MemberSelectOption';
import LearnInTable from '@components/reusable/LearnInTable';
import {Divider, TablePaginationConfig} from 'antd';
import {AddMembersTip, MemberSearch} from './styles';
import AsyncSelect from 'react-select/async';
import {customAsyncSelectStyles} from './utils';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LocalNotification} from '@components/reusable/LocalNotification/LocalNotification';
import {WarningFilled} from '@ant-design/icons';
import {CompanyMembersForAdding, LoadOptions, TableParams} from './types';
import {AcademyUsersResponse} from '@generated/interfaces';

import useFeatureFlags from '@hooks/useFeatureFlags';
import {getAcademyMemberColumns} from './MemberColumns';
import {useMemo} from 'react';
interface Props {
  academyMembers: AcademyUsersResponse;

  selectedMembesForAdding: CompanyMembersForAdding[];
  loadOptions: LoadOptions;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  onCompanyMemberSearchChange: (
    selectOptions: CompanyMembersForAdding[]
  ) => void;
  handleClickAddMembers: () => void;
  handleTableChange: (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => void;
  handleRemoveUser: (userId: number) => void;
  tableParams: TableParams;
  isUpdating: boolean;
}

export const ManageAddedMembersTable = ({
  academyMembers,
  selectedMembesForAdding,
  loadOptions,
  inputValue,
  setInputValue,
  onCompanyMemberSearchChange,
  handleClickAddMembers,
  handleTableChange,
  handleRemoveUser,
  tableParams,
  isUpdating,
}: Props) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const columns = getAcademyMemberColumns(
    handleRemoveUser,
    isFeatureFlagOn.ShowEmployeeEmail
  );

  return (
    <>
      <Grid.Container>
        <Divider />
        <Grid.Box quarter>
          <ModalTitle>{i18n.t(k.EMPLOYEE__ADD)}</ModalTitle>
        </Grid.Box>
        <Grid.Box threeQuarters>
          <MemberSearch>
            <div>
              <AsyncSelect
                cacheOptions
                components={{
                  Option: MemberSelectOption,
                }}
                isMulti
                value={selectedMembesForAdding}
                loadOptions={loadOptions}
                name="Employee Search"
                placeholder={i18n.t(k.EMPLOYEE__GROUP_SEARCH)}
                styles={customAsyncSelectStyles}
                inputValue={inputValue}
                onInputChange={setInputValue}
                noOptionsMessage={({inputValue}) => {
                  return inputValue
                    ? i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)
                    : i18n.t(k.EMPLOYEE__SEARCH_TO_ADD);
                }}
                onChange={onCompanyMemberSearchChange}
              />
              <AddMembersTip>
                {i18n.t(k.EMPLOYEE__GROUP_SEARCH_TIP)}
              </AddMembersTip>
            </div>
            <span>
              <LearnInButton
                tabIndex={0}
                onClick={handleClickAddMembers}
                disabled={!selectedMembesForAdding?.length || isUpdating}
                tag={ButtonTags.Primary}>
                {i18n.t(k.GENERIC__ADD)}
              </LearnInButton>
            </span>
          </MemberSearch>
        </Grid.Box>
        {isUpdating && (
          <Grid.Box full>
            <LocalNotification type="warning" icon={<WarningFilled />}>
              {i18n.t(k.EMPLOYEE__UPDATE_TO_ACADEMY_PROCESSING)}
            </LocalNotification>
          </Grid.Box>
        )}
        <Grid.Box full>
          <LearnInTable
            dataSource={isUpdating ? [] : academyMembers?.results}
            columns={columns}
            noDataStyle={{height: 'auto'}}
            onChange={handleTableChange}
            isLoading={isUpdating}
            loadingStatusMsg={i18n.t(k.EMPLOYEE__UPDATING)}
            totalPages={academyMembers?.totalPages}
            pageSize={academyMembers?.pageSize}
            currentPage={tableParams?.pageNumber}
          />
        </Grid.Box>
      </Grid.Container>
    </>
  );
};
