import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import {MultiValue, ActionMeta} from 'react-select';
import {useState} from 'react';
import AsyncSelect from 'react-select/async';
import styled, {keyframes} from 'styled-components';
import translate from '@i18n/translate';
import {
  formatSkillTagOptions,
  MIN_LENGTH_SEARCH_TERM,
  SkillOption,
} from './SkillsTagger.container';
import './skills-tagger.scss';
import IcLxpChevronDown from '@assets/svg/ic-lxp-chevron-down.svg';
import {pxToRem} from '@utils/styleMixins';
import LearnInTag from '@blocks/LearnInTag';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${COLORS.Neutral300};
`;

const Title = styled.h3`
  font-size: 1rem;
  margin-bottom: 0;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  color: ${COLORS.Neutral950};
  margin-bottom: -4px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

const ErrorMessage = styled.p`
  color: ${COLORS.Red600};
`;

const SkillsSection = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out;

  [class*='-multiValue'],
  [class*='control'] > :nth-child(2) {
    display: none;
  }
`;

const ChevronToggle = styled.img`
  transition: rotate 100ms ease-in;
  ${({isOpen}) => `rotate: ${isOpen ? '180' : '0'}deg;`}
  cursor: pointer;
`;

const HelperText = styled.div`
  font-size: ${pxToRem(10, 16)};
  line-height: ${pxToRem(12, 16)};
  margin-top: 4px;
  margin-bottom: 4px;
  color: ${COLORS.Neutral600};
`;

const SkillTagsContainer = styled.div`
  & > * {
    border-radius: 16px;
    margin-bottom: 6px;
    margin-right: 4px;
    display: inline-block;
  }
`;

interface SkillsTaggerProps {
  showMaxSkillTagsError: boolean;
  isLoadingMutation: boolean;
  // For React-Select, which requires the inadvisable 'Function' type
  // eslint-disable-next-line @typescript-eslint/ban-types
  loadOptions: (inputValue: string, callback: Function) => void;
  skillTags: any[]; // SkillDetail is no longer defined in our codebase but imported from a degred package
  onChangeSkillTagSelect: (
    updatedSkillOptions: MultiValue<SkillOption>,
    event: ActionMeta<SkillOption>
  ) => void;
  maxSkills?: number;
}

export function SkillsTagger({
  loadOptions,
  skillTags,
  isLoadingMutation,
  showMaxSkillTagsError,
  onChangeSkillTagSelect,
  maxSkills = 5,
}: SkillsTaggerProps) {
  const [showSkills, setShowSkills] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState('');

  return (
    <Container>
      <Header>
        <Title>{i18n.t(k.GENERIC__SKILL__PLURAL)}</Title>
        <ChevronToggle
          role="button"
          src={IcLxpChevronDown}
          alt={showSkills ? translate('CTA__HIDE') : translate('CTA__SHOW')}
          onClick={() => setShowSkills(!showSkills)}
          isOpen={showSkills}
        />
      </Header>
      {showSkills && (
        <SkillsSection>
          <AsyncSelect
            isLoading={isLoadingMutation}
            cacheOptions
            inputValue={inputValue}
            isClearable={false}
            isMulti
            loadOptions={loadOptions}
            name="Academy Skills"
            onInputChange={setInputValue}
            placeholder={translate('CTA__SEARCH_SKILLS')}
            noOptionsMessage={({inputValue}) => {
              return inputValue?.length >= MIN_LENGTH_SEARCH_TERM
                ? translate('LABEL__SEARCH_NO_SKILLS_FOUND')
                : null;
            }}
            onChange={onChangeSkillTagSelect}
            isDisabled={skillTags?.length >= maxSkills}
            value={[]}
          />
          <HelperText>
            {translate('ACADEMY__SKILL_TAGGER_HELPER_TEXT')}
          </HelperText>
          {showMaxSkillTagsError && (
            <ErrorMessage>{i18n.t(k.SKILL_ERROR)}</ErrorMessage>
          )}
          <SkillTagsContainer>
            {formatSkillTagOptions(skillTags)?.map((skill, index) => (
              <LearnInTag
                key={`${skill.title}-${index}`}
                label={skill.title}
                onClickX={() =>
                  onChangeSkillTagSelect(null, {
                    removedValue: skill,
                    action: 'remove-value',
                  })
                }
              />
            ))}
          </SkillTagsContainer>
        </SkillsSection>
      )}
    </Container>
  );
}
