// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/Responses/AcademiesResponse.cs

import {z} from 'zod';
import {AcademyVMSchema} from './AcademyVMSchema.schema';

export const AcademiesResponseSchema = z.object({
  pageNumber: z.number(),
  pageSize: z.number(),
  results: z.array(AcademyVMSchema).nullable().optional(),
  totalCount: z.number(),
  totalPages: z.number(),
});
