import {ApprovalType} from '@generated/enums';
import {ReactQueryRequest} from '@store/apiEndpoints';
import {useRef, useState} from 'react';
import {FormInstance} from 'antd/lib/form';
import {simpleQueryFn} from '@store/queryClient';
import {ApprovalRequestDetailsVM} from '@models/serverModels';
import {useQuery} from 'react-query';
import {notify} from '@components/user/notifications';
import {useUpdateApprovalExpirationMutation} from '@generated/hooks';

interface getApprovalRequestProps {
  approvalType: ApprovalType;
  approvalId: number;
  getApprovalDetailsRq: (
    approvalType: ApprovalType,
    approvalId: number
  ) => ReactQueryRequest;
}

export const useGetApprovalRequest = ({
  approvalType,
  approvalId,
  getApprovalDetailsRq,
}: getApprovalRequestProps) => {
  const formRef = useRef<FormInstance>(null);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const {queryKey, path: approvalDetailsPath} = getApprovalDetailsRq(
    approvalType,
    approvalId
  );

  const getApprovalDetailsQuery = useQuery<ApprovalRequestDetailsVM>(
    queryKey,
    () => simpleQueryFn(approvalDetailsPath),
    {
      // we want this query to run only when there's a valid approvalId
      enabled: approvalId === 0 || !!approvalId,
    }
  );

  const updateFundsExpirationMutation = useUpdateApprovalExpirationMutation({
    onError: () => notify.approvalUpdateError(),
  });

  return {
    formRef,
    confirmLoading,
    setConfirmLoading,
    getApprovalDetailsQuery,
    updateFundsExpirationMutation,
    requestData: getApprovalDetailsQuery.data,
    requestLoading: getApprovalDetailsQuery.isLoading,
    requestSuccess: getApprovalDetailsQuery.isSuccess,
  };
};
