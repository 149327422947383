import {openMessage} from '@components/user/notifications';
import {api} from '@store/api';
import {getSkillTagsBySearchTerm} from '@utils/ClientPaths';
import {i18n, k} from '@i18n/translate';
import {MultiValue, ActionMeta} from 'react-select';
import {ResourceSkillType} from '@generated/enums';
import {SkillsTagger} from './SkillsTagger.view';
import {useState, useEffect} from 'react';
import NotificationContent from '@components/user/NotificationContent';
import {
  useSkillsAssociatedByIdQuery,
  useCreateSkillsMutation,
  useDeleteSkillsMutation,
} from '@generated/hooks';

export const MIN_LENGTH_SEARCH_TERM = 2;

/*
|-----------------------------------------------------------
| Utils
|-----------------------------------------------------------
*/

export interface SkillOption {
  label: string;
  value: number;
  name: string;
  id: number;
  title: string;
}

export function formatSkillTagOptions(skillTags: any[]): SkillOption[] {
  return (
    skillTags?.map((item) => ({
      ...item,
      label: item.title,
      value: item.id,
    })) || []
  );
}

type TypeSkillTagAction =
  | 'remove-value'
  | 'clear'
  | 'pop-value'
  | 'select-option';

/*
|-----------------------------------------------------------
| Main
|-----------------------------------------------------------
*/

export default function SkillsTaggerContainer({
  academyId,
}: {
  academyId: number;
}) {
  // Error handling
  const [showMaxSkillTagsError, setShowMaxSkillTagsError] =
    useState<boolean>(false);
  useEffect(() => {
    if (showMaxSkillTagsError) {
      setTimeout(() => setShowMaxSkillTagsError(false), 5000);
    }
  }, [showMaxSkillTagsError]);

  // Fetch skills
  const {data: skillTags, invalidateExact: invalidateAssociatedByType} =
    useSkillsAssociatedByIdQuery(
      {
        resourceId: academyId,
        type: ResourceSkillType.Academy,
      },
      {
        enabled: !!academyId,
        onError: () => {
          openMessage(
            'error',
            NotificationContent(i18n.t(k.NOTIFICATION__LOAD_SKILLS_ERROR))
          );
        },
      }
    );

  // Mutation - Add Skill
  const createSkillMutation = useCreateSkillsMutation({
    onSuccess: () => {
      invalidateAssociatedByType();
    },
    onError: () => {
      openMessage(
        'error',
        NotificationContent(i18n.t(k.NOTIFICATION__ADD_SKILLS_ERROR))
      );
    },
  });

  // Mutation - Delete Skill
  const deleteSkillMutation = useDeleteSkillsMutation({
    onSuccess: () => {
      invalidateAssociatedByType();
    },
    onError: () => {
      openMessage(
        'error',
        NotificationContent(i18n.t(k.NOTIFICATION__DELETE_SKILLS_ERROR))
      );
    },
  });

  const loadOptions = async (term: string, done) => {
    try {
      if (term?.length >= MIN_LENGTH_SEARCH_TERM) {
        const response = await api.get(getSkillTagsBySearchTerm(term));
        const skillTagSearchResults = response?.data?.details.filter(
          (result) => {
            return !skillTags?.some((tag) => tag.id === result.id);
          }
        );

        // map to fit options schema
        const formattedOptions = formatSkillTagOptions(skillTagSearchResults);
        // filter out skills already added
        const existingSkillIdsSet = new Set(skillTags.map((skill) => skill.id));
        const filteredOptions = formattedOptions.filter(
          (skill) => !existingSkillIdsSet.has(skill.id)
        );

        done(filteredOptions);
      } else {
        done([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      openMessage(
        'error',
        NotificationContent(i18n.t(k.NOTIFICATION__SEARCH_SKILLS_ERROR))
      );
      done([]);
    }
  };

  async function handleChangeSkillTagSelect(
    updatedOptions: MultiValue<SkillOption>,
    event: ActionMeta<SkillOption>
  ) {
    // Common payload params
    const entityType = ResourceSkillType.Academy;
    const entityId = academyId;

    switch (event.action as TypeSkillTagAction) {
      case 'select-option':
        if (updatedOptions.length > 5) {
          setShowMaxSkillTagsError(true);
        }

        await createSkillMutation.mutate({
          payload: {
            entityId,
            entityType,
            name: event.option?.name,
            title: event.option?.title,
            lxpInputId: event.option?.id,
          },
        });
        break;
      case 'remove-value':
      case 'pop-value':
        await deleteSkillMutation.mutate({
          payload: {
            entityId,
            entityType,
            resourceSkillId: event.removedValue?.id,
          },
        });
        break;
    }
  }

  return (
    <SkillsTagger
      isLoadingMutation={createSkillMutation.isLoading}
      loadOptions={loadOptions}
      onChangeSkillTagSelect={handleChangeSkillTagSelect}
      showMaxSkillTagsError={showMaxSkillTagsError}
      skillTags={skillTags}
    />
  );
}
