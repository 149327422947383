import {useState} from 'react';
import {Tabs, Badge} from 'antd';
import {i18n, k} from '@i18n/translate';
import {LinkVM} from '@models/serverModels';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {SubmissionType} from '@generated/enums';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import ProjectSubmissionContentCardFooter from './ProjectSubmissionContentCardFooter';
import {PeerSubmissions} from './PeerSubmissions';
import {
  AttachmentVM,
  ContentFeedbackVM,
  UserItemStatusVM,
} from '@generated/interfaces';

const TabContainer = styled.div`
  padding: 20px ${({isFullPage}) => (isFullPage ? '0px' : '20px')};
  background-color: ${COLORS.Neutral50};
  border-radius: 0 0 10px 10px;
`;

export interface ProjectSubmissionContentCardFooterContainerProps {
  title: string;
  showSkipProjectButton: boolean;
  isAcademy?: boolean;
  onClickSubmit: (response: string | Blob, done: () => void) => void;
  onClickSubmittedFileLink: () => void;
  submissionType: SubmissionType;
  submittedFile: AttachmentVM | undefined;
  submittedLink: LinkVM | undefined;
  submittedResponse: string | undefined;
  uploadProgress: number | undefined;
  completedOn: string | null;
  showSubmittedResponse: boolean;
  status: UserItemStatusVM;
  contentId: number;
  skipProgram: (contentId: number) => void;
  unskipProgram: (contentId: number) => void;
  stepId: number;
  allowPeerVisibility?: boolean;
  peerSubmissionCount?: number;
  feedback: ContentFeedbackVM[];
  isFullPage: boolean;
  academyOrProgramId?: number;
  sectionId?: number;
}

enum SubmissionTabs {
  Self = 'Self',
  Peers = 'Peers',
}

export function ProjectSubmissionContentCardFooterContainer(
  props: ProjectSubmissionContentCardFooterContainerProps
) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const [activeKey, setActiveKey] = useState<SubmissionTabs>(
    SubmissionTabs.Self
  );
  const showPeerSubmissionsTab =
    props.allowPeerVisibility &&
    (isFeatureFlagOn.PeerVisibility ||
      isFeatureFlagOn.PeerVisibilityForCustomPrograms);

  return showPeerSubmissionsTab ? (
    <LearnInTabs
      defaultActiveKey={SubmissionTabs.Self}
      activeKey={activeKey}
      onChange={(newActiveKey: SubmissionTabs) => setActiveKey(newActiveKey)}>
      <Tabs.TabPane tab={i18n.t(k.PROJECT__YOUR)} key={SubmissionTabs.Self}>
        <TabContainer
          style={{backgroundColor: COLORS.White}}
          isFullPage={props.isFullPage}>
          <ProjectSubmissionContentCardFooter
            {...props}
            canShowFeedback={isFeatureFlagOn.ProjectFeedback} // temporary flag until full roll-out
          />
        </TabContainer>
      </Tabs.TabPane>
      {showPeerSubmissionsTab && (
        <Tabs.TabPane
          tab={
            <div>
              {i18n.t(k.SUBMISSION__NAVIGATE_ALL)}
              {props.peerSubmissionCount > 0 && (
                <Badge
                  style={{
                    backgroundColor: COLORS.Blue50,
                    color: COLORS.Neutral900,
                    marginLeft: '8px',
                    minWidth: '26px',
                    height: '16px',
                    fontSize: '0.75rem',
                    lineHeight: '1rem',
                  }}
                  count={props.peerSubmissionCount}
                />
              )}
            </div>
          }
          key={SubmissionTabs.Peers}>
          <TabContainer
            style={{cursor: 'default'}}
            isFullPage={props.isFullPage}>
            <PeerSubmissions
              academyOrProgramId={props.academyOrProgramId}
              sectionId={props.sectionId}
              isAcademy={props.isAcademy}
              stepId={props.stepId}
            />
          </TabContainer>
        </Tabs.TabPane>
      )}
    </LearnInTabs>
  ) : (
    <TabContainer
      style={{backgroundColor: COLORS.White}}
      isFullPage={props.isFullPage}>
      <ProjectSubmissionContentCardFooter
        {...props}
        canShowFeedback={isFeatureFlagOn.ProjectFeedback} // temporary flag until full roll-out
      />
    </TabContainer>
  );
}
