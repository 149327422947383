import {TIsFeatureFlagOn} from '@hooks/useFeatureFlags';
import {useHasIntegratedUx} from './useHasIntegratedUx';
import {useUrlContainsIndicator} from './useUrlContainsIndicator';
import {UserDetailsVM} from '@generated/interfaces';
import {useIsIntegratedUser} from './useIsIntegratedUser';
import {INTEGRATED_INCOMING_LXP_USER} from '@utils/constants';

/** If directly navigating from LXP, we hide the primary navigation for integrated users, regardless of approver status */
export const useForceHidePrimaryNavigation = (
  user: UserDetailsVM,
  isFeatureFlagOn: TIsFeatureFlagOn,
  isApprover: boolean,
  isLxpAdmin: boolean
) => {
  const isIntegratedUser = useIsIntegratedUser(
    isFeatureFlagOn.UpdatedLxpToCmFlow,
    user
  );
  const hasIntegratedUx = useHasIntegratedUx(isIntegratedUser, isApprover);
  const isIncomingLxpUser =
    sessionStorage.getItem(INTEGRATED_INCOMING_LXP_USER) === 'true';
  const hideIfUrlIndicates = useUrlContainsIndicator(
    location.href,
    isFeatureFlagOn.LXPAdminLayout
  );

  if (isIncomingLxpUser) return true;
  if (isLxpAdmin && !isFeatureFlagOn.LXPAdminLayout) return true;
  if (!isFeatureFlagOn.UpdatedLxpToCmFlow) return false;

  return isIntegratedUser
    ? hideIfUrlIndicates || hasIntegratedUx
    : hideIfUrlIndicates;
};
