import {useEffect} from 'react';
import * as React from 'react';
import {Form} from 'antd';
import {datadogLogs} from '@datadog/browser-logs';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import {useMutation} from 'react-query';
import {
  getDeleteAcademyCoverImageRm,
  updateAcademyRm,
} from '@store/apiEndpoints/academy/mutations';
import {useAcademy} from '@hooks/apiEndpoints/academy/queries';
import {simpleDeleteFn, simpleMutationFn} from '@store/queryClient';
import {AcademyPayload} from '@models/serverModels';
import {AcademyPermissions} from '@models/clientEnums';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import {Setup} from './Setup';
import SetupSideNav from './SetupSideNav';
import LevelsScreen from '../Levels/LevelsScreen';
import SetupAcademyHeader from './SetupAcademyHeader';
import {notify} from '@components/user/notifications';
import BrandingScreen from '@components/admin/pages/academies/Branding/BrandingScreen';
import {AcademyIcon} from '../NewAcademy/NewAcademy.container';
import {b64toBlob} from '@utils/image-utils';
import PermissionsScreen from '../Permissions/PermissionsScreen.container';
import {AcademyScreens, userAcademyRoute} from '@utils/ClientPaths';
import {i18n, k} from '@i18n/translate';
import {AttachmentPayload} from '@models/api/shared/payloads';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';
import {
  useAcademiesCountsQuery,
  useAcademiesIntegrationsChatChannelsDeleteMutation as useChatChannelsDeleteMutation,
  useAcademiesIntegrationsChatChannelsPostMutation as useChatChannelsPostMutation,
  useAcademiesIntegrationsChatChannelsPutMutation as useChatChannelsPutMutation,
  useAcademiesIntegrationsChatChannelsQuery as useChatChannelsQuery,
  useAcademyUsersQuery,
} from '@generated/hooks';
import {
  IntegrationChatChannelType,
  IntegrationChatProvider,
  AcademyStatus,
} from '@generated/enums';
import {getLabelByScreen} from './Setup.utils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {ClickToJoinShareModal} from '@components/ClickToJoin/ClickToJoinShareModal';
import {getCTJAcademy} from '@components/ClickToJoin/ClickToJoin.model';
import {getFullUrl} from '@components/ClickToJoin/utils';
import {
  getTitle,
  mapTextToEnglish,
} from '@utils/enumMapping/MapTextToUserLanguage';
import {Helmet} from 'react-helmet-async';
import useShowSkillsTagging from '@hooks/useShowSkillsTagging';
import {useAuth} from '@utils/oidc-auth-wrapper';
import moment from 'moment';
import {usePublishedAcademiesQueryCached} from '@generated/hooks/academy.get.hooks';

interface SetupContainer {
  onClose: () => void;
  hidePreviewAcademyLink?: boolean;
  getScreenNavItemUrlPath: (
    academyId: number | string,
    screen: AcademyScreens,
    levelId?: number,
    _new?: boolean
  ) => string;
  title?: string;
}

export interface AcademySetupFormInstance {
  description: string;
  name: string;
  startDate: Date;
  endDate: Date;
  duration: number;
}

const SetupContainer = ({
  onClose,
  getScreenNavItemUrlPath,
  hidePreviewAcademyLink,
  title,
}: SetupContainer) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {user} = useAuth();
  const {academyId, screen} = useParams();
  const {showSkillsTagging} = useShowSkillsTagging();
  const isLxpAdmin = useIsLxpAdmin();
  const navigate = useNavigate();

  const [form] = Form.useForm<AcademySetupFormInstance>();
  const [privacyState, setPrivacyState] = React.useState<AcademyPermissions>(
    AcademyPermissions.Public
  );
  const [previewUrl, setPreviewUrl] = React.useState('');
  const [attachments, setAttachments] = React.useState<AttachmentPayload[]>([]);
  const [shouldSendEmailInvite, setShouldSendEmailInvite] =
    React.useState(false);
  const [isShareLinkModalVisible, setIsShareLinkModalVisible] =
    React.useState(false);
  const [levelsScreenKey, setLevelsScreenKey] = React.useState<number>(0);

  const isNewLevel = location.pathname
    .split('/')
    .some((part) => part.includes('new'));

  const {invalidateExact: invalidateAcademiesCount} = useAcademiesCountsQuery(
    null,
    {
      enabled: false,
    }
  );
  const {invalidateExact: invalidateAcademiesList} =
    usePublishedAcademiesQueryCached(
      {queryParams: {includeSkills: showSkillsTagging}},
      {enabled: false}
    );

  const {
    data: academy,
    refetch: refetchAcademy,
    isLoading: isLoadingAcademy,
  } = useAcademy(parseInt(academyId), {
    enabled: !!academyId,
    refetchOnWindowFocus: false,
  });

  const {data: academyUsers} = useAcademyUsersQuery({
    academyId,
  });

  const {
    data: chatChannels,
    isLoading: isChatChannelsLoading,
    invalidateExact: invalidateChatChannels,
  } = useChatChannelsQuery(
    {
      academyId: parseInt(academyId),
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!academyId,
    }
  );
  const chatChannelsDelete = useChatChannelsDeleteMutation({
    onSuccess: invalidateChatChannels,
  });
  const chatChannelsPost = useChatChannelsPostMutation({
    onSuccess: invalidateChatChannels,
  });
  const chatChannelsPut = useChatChannelsPutMutation({
    onSuccess: invalidateChatChannels,
  });

  function updateFormFromSavedValues() {
    form.setFieldsValue({
      name: getTitle(academy?.name),
      description: academy?.description,
      startDate: !!academy?.startDate
        ? moment(academy?.startDate, 'YYYY-MM-DD')
        : null,
      endDate: !!academy?.endDate
        ? moment(academy?.endDate, 'YYYY-MM-DD')
        : null,
      duration: academy?.duration,
    });
    setPrivacyState(
      academy?.restrictedAccess
        ? AcademyPermissions.Restricted
        : AcademyPermissions.Public
    );
    setShouldSendEmailInvite(!!academy?.invitationEmail);
  }

  useEffect(updateFormFromSavedValues, [academy]);

  useEffect(() => {
    if (!attachments.length) return;
    const url = URL.createObjectURL(b64toBlob(attachments[0].fileContent));
    setPreviewUrl(url);
  }, [attachments]);

  const deleteAcademyCoverImageMutation = useMutation(
    () => {
      const deleteAcademyCoverImageRm = getDeleteAcademyCoverImageRm(
        parseInt(academyId)
      );

      return simpleDeleteFn<null>(
        deleteAcademyCoverImageRm.path,
        deleteAcademyCoverImageRm.payload
      );
    },
    {
      onSuccess: async () => {
        await refetchAcademy();
        notify.deleteCustomProgramCoverImageSuccess();
      },
      onError: () => {
        notify.deleteCustomProgramCoverImageError();
      },
    }
  );

  const onDeleteCoverImage = async () => {
    await deleteAcademyCoverImageMutation.mutateAsync();
  };

  const handleUpdateLink = async (channelLink: string) => {
    const payload = {
      channelLink,
      integrationChatChannelType: IntegrationChatChannelType.Manual,
      integrationChatProvider: IntegrationChatProvider.Slack,
    };
    return chatChannelsPut.mutate({
      payload,
      pathVars: {
        academyId: parseInt(academyId),
      },
    });
  };

  const handleAddLink = async (channelLink: string) => {
    const payload = {
      channelLink,
      integrationChatChannelType: IntegrationChatChannelType.Manual,
      integrationChatProvider: IntegrationChatProvider.Slack,
    };
    return chatChannelsPost.mutate({
      payload,
      pathVars: {
        academyId: parseInt(academyId),
      },
    });
  };

  const handleDeleteAttachment = async () => {
    if (academyId && academy?.academyProfileImageUrl) {
      onDeleteCoverImage();
    }

    setAttachments([]);
    setPreviewUrl('');
  };

  useEffect(() => {
    if (previewUrl === '') {
      setPreviewUrl(academy?.academyProfileImageUrl || '');
    }
  }, [academy]);

  const updateAcademyMutation = useMutation(
    (args: AcademyPayload) => {
      const updateAcademy = updateAcademyRm(args);
      return simpleMutationFn<null>(updateAcademy.path, updateAcademy.payload);
    },
    {
      onSuccess: async () => {
        notify.updateAcademySuccess();
        await refetchAcademy();
        invalidateAcademiesList();
        updateFormFromSavedValues();
      },
      onError: async (e) => {
        console.warn('error', e);
      },
    }
  );

  const academyUpdateData = {
    id: parseInt(academyId),
    name: academy?.name,
    status: academy?.status,
    academyProfileImageUrl: previewUrl,
    images: attachments,
    description: academy?.description,
    restrictedAccess: privacyState === AcademyPermissions.Restricted,
    invitationEmail: shouldSendEmailInvite,
    startDate: academy?.startDate,
    endDate: academy?.endDate,
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((payload) => {
        updateAcademyMutation.mutateAsync({
          ...academyUpdateData,
          ...payload,
          name: mapTextToEnglish(payload.name),
        });
        invalidateAcademiesCount();
      })
      .catch((error) => {
        datadogLogs.logger.error('Error while submitting new academy', error);
      });
  };

  const publishAcademyMutation = useMutation(
    () => {
      const updateAcademyStatusRm = updateAcademyRm({
        ...academyUpdateData,
        status: AcademyStatus.Published,
      });
      return simpleMutationFn<null>(
        updateAcademyStatusRm.path,
        updateAcademyStatusRm.payload
      );
    },
    {
      onSuccess: async () => {
        await refetchAcademy();
        notify.academyPublishedSuccess();
        invalidateAcademiesCount();
      },
      onError: () => {
        notify.academyPublishedError();
      },
    }
  );

  const getClickToJoinModalProps = () => {
    const ssoProviderParam = user?.ssoProvider
      ? {
          ssoprovider: user?.ssoProvider, // keep 'ssoprovider' key lowercase
        }
      : null;

    return getFullUrl(userAcademyRoute(academyId), {
      ...ssoProviderParam,
    });
  };

  return (
    <LearnInPageDrawer
      Icon={AcademyIcon}
      onClose={onClose}
      title={getLabelByScreen(screen as AcademyScreens)}
      visible={true}
      contentWrapperStyle={{transform: 'none'}}
      zIndex={998}
      headerContent={
        <SetupAcademyHeader
          shouldSendEmailInvite={shouldSendEmailInvite}
          academyUserCount={academyUsers?.length || 0}
          hidePreviewAcademyLink={hidePreviewAcademyLink}
          academyId={academyId}
          isLxpAdmin={isLxpAdmin}
          onClickPublish={() => {
            publishAcademyMutation.mutateAsync();
          }}
          onClickPreview={(route: string) => {
            navigate(route);
          }}
          showPublishButton={academy?.status !== AcademyStatus.Published}
          canShowShareableLink={
            isFeatureFlagOn.ShowAcademyClickToJoin &&
            academy?.status === AcademyStatus.Published &&
            !!academy?.joinId
          }
          onClickShareAcademy={() => setIsShareLinkModalVisible(true)}
        />
      }>
      <SetupSideNav
        academyId={parseInt(academyId)}
        currentScreen={screen}
        getScreenNavItemUrlPath={(screen, levelId) =>
          getScreenNavItemUrlPath(parseInt(academyId), screen, levelId)
        }
      />
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isLoadingAcademy ? (
        <LoadingSpinner />
      ) : (
        <>
          <Routes>
            {screen === AcademyScreens.setup && (
              <Route
                key={AcademyScreens.setup}
                path="/*"
                element={
                  <Setup
                    handleDeleteChatLink={() => {
                      return chatChannelsDelete.mutate({
                        pathVars: {
                          academyId: parseInt(academyId),
                        },
                      });
                    }}
                    isLoadingChatLink={isChatChannelsLoading}
                    chatLink={chatChannels}
                    handleAddLink={handleAddLink}
                    handleUpdateLink={handleUpdateLink}
                    title={i18n.t(k.GENERIC__SETUP)}
                    form={form}
                    onSubmit={handleSubmit}
                    privacyState={privacyState}
                    setPrivacyState={setPrivacyState}
                    setAttachments={setAttachments}
                    handleDeleteAttachment={handleDeleteAttachment}
                    previewUrl={previewUrl}
                    shouldSendEmailInvite={shouldSendEmailInvite}
                    setShouldSendEmailInvite={setShouldSendEmailInvite}
                  />
                }
              />
            )}

            {screen === AcademyScreens.permissions && (
              <Route
                key={AcademyScreens.permissions}
                path="/*"
                element={
                  <PermissionsScreen title={i18n.t(k.PERMISSION__PLURAL)} />
                }
              />
            )}

            {screen === AcademyScreens.branding && (
              <Route
                key={AcademyScreens.branding}
                path="/*"
                element={
                  <BrandingScreen
                    title={i18n.t(k.BRAND__BRANDING)}
                    academyData={academy}
                    refetchAcademy={refetchAcademy}
                  />
                }
              />
            )}

            {screen === AcademyScreens.overview && (
              <>
                <Route
                  key={AcademyScreens.overview}
                  path=":levelId/*"
                  element={
                    <LevelsScreen
                      key={levelsScreenKey}
                      onForceRemount={() => setLevelsScreenKey((n) => n + 1)}
                      title={i18n.t(k.GENERIC__OVERVIEW)}
                      isNewLevel={isNewLevel}
                      getScreenNavItemUrlPath={(levelId, _new) =>
                        getScreenNavItemUrlPath(
                          academyId,
                          AcademyScreens.overview,
                          levelId,
                          _new
                        )
                      }
                    />
                  }
                />
                {/* This page can render before levels have loaded on the client, in which case there is no levelId yet */}
                <Route
                  key={AcademyScreens.overview}
                  path="/*"
                  element={
                    <LevelsScreen
                      title={i18n.t(k.GENERIC__OVERVIEW)}
                      isNewLevel={isNewLevel}
                      getScreenNavItemUrlPath={(levelId, _new) =>
                        getScreenNavItemUrlPath(
                          academyId,
                          AcademyScreens.overview,
                          levelId,
                          _new
                        )
                      }
                    />
                  }
                />
              </>
            )}
          </Routes>
        </>
      )}
      <ClickToJoinShareModal
        ui={getCTJAcademy(isShareLinkModalVisible, getClickToJoinModalProps())}
        onClose={() => setIsShareLinkModalVisible(false)}
      />
    </LearnInPageDrawer>
  );
};

export default SetupContainer;
