import {ToggleChevron} from '@components/reusable/Button/Button.style';
import LearnInDrawer from '@components/reusable/LearnInDrawer';

import {i18n, k} from '@i18n/translate';

import {COLORS} from '@utils/constants';
import styled from 'styled-components';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/
const Title = styled.div`
  text-align: left;
  color: #1b1c23;
  font-size: 1rem;
  font-weight: 500;
`;

const Subtitle = styled.div`
  color: #44444a;
  font-weight: 400;
  font-size: 0.75rem;
`;

const OptionsContainer = styled.button`
  padding: 16px 24px;
  width: 100%;
`;
const OptionButtonContainer = styled.button`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  &:hover {
    background: ${COLORS.Neutral100};
  }
`;
const ICON_WRAPPER_SIZE = 32;

const IconWrapper = styled.div<{background: string}>`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ICON_WRAPPER_SIZE}px;
  height: ${ICON_WRAPPER_SIZE}px;
  background-color: ${({background}) => background};
`;

/*
|--------------------------------------------------------------------------
| Drawer & Table Component
|--------------------------------------------------------------------------
*/

interface IAddStepDrawerProps {
  onClose: () => void;
  visible: boolean;
  options: any[];
}

const AddStepDrawer = ({onClose, visible, options}: IAddStepDrawerProps) => {
  return (
    <>
      {visible && (
        <LearnInDrawer
          closeButtonPlacement={'right'}
          title={i18n.t(k.LEVEL__STEP__ADD)}
          bodyStyle={{
            background: COLORS.Neutral50,
            padding: 0,
            borderRadius: 0,
          }}
          onClose={onClose}
          visible={visible}>
          <OptionsContainer>
            {options.map((option: any) => {
              return (
                <OptionButtonContainer
                  role="button"
                  key={option.title}
                  tabIndex={0}
                  onClick={() => {
                    option.onClick();
                    onClose();
                  }}
                  style={{background: option.background}}>
                  <div style={{display: 'flex'}}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '24px',
                      }}>
                      <IconWrapper background={option.iconBg}>
                        <option.Icon
                          style={{color: option.color, fontSize: '20px'}}
                        />
                      </IconWrapper>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        color: option.color,
                      }}>
                      <Title>{option.title}</Title>
                      <Subtitle>{option.subtitle}</Subtitle>
                    </div>
                  </div>
                  <ToggleChevron
                    openDirection="left"
                    closeDirection="left"
                    open={false}
                    style={{
                      color: COLORS.Neutral800,
                      fontSize: 24,
                    }}
                  />
                </OptionButtonContainer>
              );
            })}
          </OptionsContainer>
        </LearnInDrawer>
      )}
    </>
  );
};

export default AddStepDrawer;
