import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';

export const AddMembersTip = styled.div`
  font-weight: 400;
  font-size: ${pxToRem(10)};
  color: ${COLORS.Neutral950};
`;

export const MemberSearch = styled.div`
  display: flex;
  > div {
    margin-right: 12px;
    width: 100%;
  }
`;
