import {i18n, k} from '@i18n/translate';
import {AdminPaths} from '@utils/ClientPaths';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {isBetaAny, isProductionAny} from '@utils/environment-helpers';
import {getIncentiveAdminFinanceRq} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import {NavMenuItem, SubMenuKeys} from '@blocks/appNav/AppNavMenu';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import AuditOutlined from '@ant-design/icons/lib/icons/AuditOutlined';
import SnippetsOutlined from '@ant-design/icons/lib/icons/SnippetsOutlined';
import TrophyOutlined from '@ant-design/icons/lib/icons/TrophyOutlined';
import RocketOutlined from '@ant-design/icons/lib/icons/RocketOutlined';
import BarChartOutlined from '@ant-design/icons/lib/icons/BarChartOutlined';
import {UserOutlined} from '@ant-design/icons';
import useApprovals from '@hooks/useApprovals';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function useGetAdminNavMenuItem(): NavMenuItem[] {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isAcademiesOn = isFeatureFlagOn.Academies;
  const isLicensesOn = isFeatureFlagOn.Licenses;
  const isIncentivesOn = isFeatureFlagOn.Incentives;
  const isHideAdminSettings = isFeatureFlagOn.HideAdminSettings;
  const isSunsetInitiativesOn = isFeatureFlagOn.SunsetInitiatives;

  const {
    allPendingApprovalsCount,
    pendingProgramsTabCount,
    pendingResourcesTabCount,
  } = useApprovals('admin');

  const getLearningBudgetInfoQuery = getIncentiveAdminFinanceRq();
  const {data: learningBudgetInfo} = useQuery(
    getLearningBudgetInfoQuery.queryKey,
    () => simpleQueryFn(getLearningBudgetInfoQuery.path)
  );
  const virtualCard = 'Prepayment';
  const isVirtualCard = learningBudgetInfo?.[0]?.incentiveType === virtualCard;

  // Nav Menu Items
  const navMenuItems = [
    {
      label: i18n.t(k[SubMenuKeys.Learning]),
      icon: <SnippetsOutlined aria-hidden="true" />,
      subItems: [
        {
          label: i18n.t(k.PROGRAM__PLURAL),
          path: AdminPaths.Programs,
        },
        isAcademiesOn && {
          label: i18n.t(k.ACADEMY__PLURAL),
          path: AdminPaths.Academies,
        },
        isLicensesOn && {
          label: i18n.t(k.LICENSE__PLURAL),
          path: AdminPaths.Licenses,
        },
      ].filter(Boolean),
    },
    isIncentivesOn && {
      label: i18n.t(k.INCENTIVE__PLURAL),
      icon: <TrophyOutlined aria-hidden="true" />,
      path: AdminPaths.InvestmentsIncentives,
    },
    !isSunsetInitiativesOn && {
      label: i18n.t(k.INITIATIVE__PLURAL),
      icon: <RocketOutlined aria-hidden="true" />,
      path: AdminPaths.Initiatives,
    },
    {
      label: i18n.t(k[SubMenuKeys.Approvals]),
      icon: (
        <span aria-hidden="true">
          <AuditOutlined
            aria-hidden="true"
            title=""
            aria-label=""
            aria-labelledby="test"
          />
        </span>
      ),
      count: allPendingApprovalsCount,
      subItems: [
        {
          label: i18n.t(k.PROGRAM__PLURAL),
          path: AdminPaths.ApprovalsProgram,
          count: pendingProgramsTabCount,
        },
        {
          label: i18n.t(k.RESOURCE__PLURAL),
          path: AdminPaths.ApprovalsResource,
          count: pendingResourcesTabCount,
        },
      ].filter(Boolean),
    },
    {
      label: i18n.t(k.REPORT__PLURAL),
      icon: <BarChartOutlined aria-hidden="true" />,
      path: AdminPaths.Reports,
    },
    {
      label: i18n.t(k.GENERIC__PEOPLE),
      icon: <UserOutlined aria-hidden="true" />,
      path: AdminPaths.People,
    },
    isHideAdminSettings &&
      isVirtualCard && {
        label: `${i18n.t(k.ADMIN__SETTING__PLURAL)}`,
        icon: <SettingOutlined aria-hidden="true" />,
        path: AdminPaths.Prepayment,
      },
    !isProductionAny && !isBetaAny && {label: 'QA', path: AdminPaths.Qa},
    !isProductionAny &&
      !isBetaAny && {
        label: 'Developer Admin',
        path: AdminPaths.DeveloperAdmin,
      },
  ].filter(Boolean);

  return navMenuItems;
}

export default useGetAdminNavMenuItem;
