import {
  LearnInTextArea,
  InputLabel,
  ErrorMessage,
} from '@components/reusable/LearnInForm';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {ForwardOutlined} from '@ant-design/icons';
import ContextMenuButton from '@blocks/ContextMenu';
import {i18n, k} from '@i18n/translate';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {useState} from 'react';

const SkippedText = styled.span`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0 24px 0 8px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

const SkipWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const SubmitButton = ({
  onClick,
  title,
  loading,
}: {
  onClick: () => void;
  title: string;
  loading?: boolean;
}) => {
  return (
    <LearnInButton
      loading={loading}
      onClick={onClick}
      tag={ButtonTags.Primary}
      aria-label={i18n.t(k.DISCUSSION__SUBMIT_FORMAT, {item: title})}>
      {loading ? i18n.t(k.SUBMISSION__ACTIVE) : i18n.t(k.CTA__SUBMIT)}
    </LearnInButton>
  );
};

interface SubmissionFormProps {
  title: string;
  textResponse: string;
  setTextResponse: (text: string) => void;
  isSkipped: boolean;
  onClickSubmit: () => void;
  showSkipButton?: boolean;
  contextTextId: string;
  onClickSkipDiscussion: () => void;
  onClickUnskipDiscussion: () => void;
  contentId: number;
  disableSubmitButtonOnClick?: boolean;
  onCancelEdit: () => void;
}
export function SubmissionForm({
  title,
  textResponse,
  setTextResponse,
  isSkipped,
  onClickSubmit,
  showSkipButton,
  contentId,
  contextTextId,
  onClickSkipDiscussion,
  onClickUnskipDiscussion,
  disableSubmitButtonOnClick,
  onCancelEdit,
}: SubmissionFormProps) {
  const [isTextResponseValid, setIsTextResponseValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateTextResponse = (textResponseValue: string) => {
    const isValid = !!textResponseValue;
    setIsTextResponseValid(isValid);
    return isValid;
  };

  const handleChangeTextResponse = (textResponseValue: string) => {
    validateTextResponse(textResponseValue);
    setTextResponse(textResponseValue);
  };

  return (
    <>
      <InputLabel
        htmlFor="text-response"
        label={i18n.t(k.CTA__SUBMIT_RESPONSE)}
        required
      />
      <LearnInTextArea
        id="text-response"
        value={textResponse}
        updateFunc={handleChangeTextResponse}
        placeholder={i18n.t(k.DISCUSSION__ANSWER_PROMPT)}
        aria-labelledby={`content-${contentId}`}
      />
      {!isTextResponseValid && (
        <ErrorMessage
          message={i18n.t(k.VALIDATION__DISCUSSION__ERROR__EMPTY_RESPONSE)}
        />
      )}
      <ButtonWrapper>
        {isSkipped ? (
          <SkipWrapper key="skipped">
            <ForwardOutlined
              aria-hidden="true"
              style={{
                color: COLORS.Neutral600,
                fontSize: '1.375rem',
              }}
            />
            <SkippedText>{i18n.t(k.STATUS__SKIPPED)}</SkippedText>
          </SkipWrapper>
        ) : (
          <div style={{display: 'flex'}}>
            <SubmitButton
              title={title}
              loading={isSubmitting}
              onClick={async () => {
                if (validateTextResponse(textResponse)) {
                  if (disableSubmitButtonOnClick) {
                    setIsSubmitting(true);
                  }
                  await onClickSubmit();
                  setIsSubmitting(false);
                  setTextResponse('');
                }
              }}
            />
            <LearnInButton
              style={{marginLeft: 8}}
              tag={ButtonTags.Secondary}
              onClick={() => {
                setTextResponse('');
                onCancelEdit();
              }}>
              {i18n.t(k.CTA__CANCEL)}
            </LearnInButton>
          </div>
        )}
        {showSkipButton && (
          <ContextMenuButton
            key="context"
            size="large"
            popupContainerId={contextTextId}
            itemId={contextTextId}
            overlayPlacement="bottomRight"
            menuItems={[
              !isSkipped && {
                label: i18n.t(k.DISCUSSION__SKIP),
                onClick: () => {
                  onClickSkipDiscussion();
                },
              },
              isSkipped && {
                label: i18n.t(k.DISCUSSION__UNSKIP),
                onClick: () => {
                  onClickUnskipDiscussion();
                },
              },
            ]}
          />
        )}
      </ButtonWrapper>
    </>
  );
}
