/** These strings aren't customer-facing: these don't need to be localized. */
export const DUE_DATE_REMINDER_PROJECT = {
  name: 'Eleven',
  parentType: 'academy',
  parentTitle: 'MKUltra',
  contentType: 'project',
  contentImageUrl:
    'https://static.wikia.nocookie.net/logopedia/images/5/5d/Netflix_2014.svg/revision/latest/scale-to-width-down/300?cb=20201124111638',
  dueDate: 'Wednesday, May 15',
  title: 'Vecna, The Mind Flayer, and The Vines',
  buttonUrl: '/#fake-url',
  companyLogoUrl:
    'https://static.wikia.nocookie.net/logopedia/images/f/f5/Stranger_Things.svg/revision/latest/scale-to-width-down/300?cb=20220803140619',
};
