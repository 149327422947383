import PageNotFound from '404';
import EmailPreview from '@components/EmailPreview';
import AdminWrapper from '@components/admin/AdminWrapper';
import {i18n, k} from '@i18n/translate';
import {RouteElement} from '@models/clientModels';
import {EmailPreviewPaths} from '@utils/ClientPaths';
import {isProductionAny} from '@utils/environment-helpers';
import {Navigate, useSearchParams, useNavigate} from 'react-router-dom';
import {useGetEnabledUserRoutes} from './useGetEnabledUserRoutes';
import {useAuth} from '@utils/oidc-auth-wrapper';
import useFeatureFlags from './useFeatureFlags';
import {useGoBack} from './useGoBack';
import useCanShowPrograms from './useCanShowPrograms';
import {useCanManageApprovals} from './useCanManageApprovals';
import {useForceHidePrimaryNavigation} from './integrated/useForceHidePrimaryNavigation';
import {useHidePrimaryNavigation} from './integrated/useHidePrimaryNavigation';
import {useIsIntegratedUser} from './integrated/useIsIntegratedUser';
import {TeamInsightsContainer} from '@components/manager/pages/team-insights';
import ManagerApprovalsList from '@components/manager/pages/approvals/components/managerApprovalsList/ManagerApprovalsList';
import {
  ApprovalsDiscriminator,
  RequestApprovalType,
  PlanItemType,
} from '@generated/enums';
import ManagerReportsWrapper from '@components/manager/pages/approvals/components/ManagerReports/ManagerReportsWrapper';
import PaginatedManagerApprovalsList from '@components/manager/pages/approvals/components/managerApprovalsList/v2';
import {
  adminProgramsCustomProgramManage,
  adminProgramsCustomRoute,
  adminProgramsMarketplaceRoute,
  getAcademyUserDrawerAdminPreviewScreenNavItemUrlPath,
  getAdminAcademyConfigureDrawerRoute,
} from '@utils/ClientPaths';
import AdminProgramsPage from 'components/admin/pages/programs/Programs';
import CustomProgramAdminDrawer from '@components/admin/pages/programs/customPrograms/CustomProgramAdminDrawerWithRouting.container';
import CustomProgramUserDrawerWithRouting from '@blocks/CustomProgramUserDrawer/CustomProgramUserDrawerWithRouting.container';
import {
  getAdminAcademiesUrl,
  adminCustomProgramScreenRoute,
} from '@utils/ClientPaths';
import PlanItemDetailPage from '@components/reusable/PlanItemDetailPage/PlanItemDetailPage.container';
import {NewAcademyContainer} from '@components/admin/pages/academies/NewAcademy/NewAcademy.container';
import AdminAcademiesPage from '@components/admin/pages/academies/AcademiesAdminPage.container';
import SetupContainer from '@components/admin/pages/academies/Setup/Setup.container';
import AcademyDrawer from '@components/user/pages/academy/AcademyDrawer/AcademyDrawer.container';
import AdminLicensesPage from '@components/admin/pages/licenses/LicensesAdminPage.container';
import AdminReportsWrapper from '@components/admin/pages/reports/AdminReportsWrapper';
import InitiativesWrapper from '@components/admin/pages/initiatives/InitiativesWrapper';
import NewInitiativesContainer from '@components/admin/pages/initiatives/components/NewInitiative/NewInitiative.container';
import Prepayment from '@components/admin/pages/prepayment/Prepayment';
import QaPage from '@pages/QaPage';
import DeveloperAdminPage from '@pages/DeveloperAdminPage';
import AdminUsers from '@components/admin/pages/users/AdminUsers.container';
import {DrilldownContainer} from '@components/manager/pages/team-insights/Drilldown/Drilldown.container';
import PrimaryRoutesContainer from 'PrimaryRoutes.container';
import Incentives from '@components/admin/pages/investments/components/incentives/Incentives';
import AdminApprovalsList from '@components/admin/pages/approvals/components/adminApprovalsList/AdminApprovalsList';
import PaginatedAdminApprovalsList from '@components/admin/pages/approvals/components/AdminApprovalsListV2';
import {useIsLxpAdmin} from './integrated/useIsLxpAdmin';

// TODO: Lazy-Loaded Routes

/** Get routes that are common to both integrated and non-integrated (standard) users.
 * This is used in combination of useBuildRouteElements to handle complexity.
 */
const useGetEnabledPrimaryRoutesShared = ({
  isManager,
  isAdmin,
}: {
  isManager: boolean;
  isAdmin: boolean;
}): RouteElement[] => {
  const enabled = true;

  /** Logic from UserRoutes.tsx **/
  const {user} = useAuth();
  const {isFeatureFlagOn} = useFeatureFlags();
  const canShowPrograms = useCanShowPrograms();
  const isApprover = useCanManageApprovals(user);
  const isLxpAdmin = useIsLxpAdmin();
  const forceNavOff = useForceHidePrimaryNavigation(
    user,
    isFeatureFlagOn,
    isApprover,
    isLxpAdmin
  );
  const hidePrimaryNavigation = useHidePrimaryNavigation({
    user,
    location: window.location,
    forceOff: forceNavOff,
  });

  // User roles/access
  const isIntegratedUser = useIsIntegratedUser(
    isFeatureFlagOn.UpdatedLxpToCmFlow,
    user
  );
  const isAcademiesOn = isFeatureFlagOn.Academies;
  const hideMarketplaceFromLxp = isFeatureFlagOn.HideMarketplaceFromLXP;

  const userRoutesConfig = useGetEnabledUserRoutes({
    canShowPrograms,
    hideMarketplaceFromLxp,
    hidePrimaryNavigation,
    isAcademiesOn,
    isApprover,
    isIntegratedUser,
  });

  const ManagerApprovalsListComponent =
    isFeatureFlagOn.ManagerApprovalsPagination
      ? PaginatedManagerApprovalsList
      : ManagerApprovalsList;

  const AdminApprovalsListComponent = isFeatureFlagOn.AdminApprovalsPagination
    ? PaginatedAdminApprovalsList
    : AdminApprovalsList;

  const routeElements: RouteElement[] = [
    {
      path: '/',
      enabled,
      element: <PrimaryRoutesContainer />,
      children: [
        {
          path: '/',
          enabled,
          element: <Navigate to="/user/manage" />,
        },

        /*
        |-----------------------------------------------------------
        | Section: User
        |-----------------------------------------------------------
        */
        // <UserRoutes />, UserPaths.Wildcard

        ...userRoutesConfig.map((route) => {
          route.path = `/user/${route.path}`.replace('//', '/');
          return route;
        }),

        /*
        |-----------------------------------------------------------
        | Section: Manager
        |-----------------------------------------------------------
        */

        // <ManagerRoutes />, ManagerPaths.Wildcard
        {
          path: '/manager/',
          enabled: isManager,
          element: <Navigate to={'/manager/approvals'} />,
        },
        {
          path: '/manager/team-insights/*',
          enabled: isManager && isFeatureFlagOn.TeamInsights,
          element: <TeamInsightsContainer title={i18n.t(k.TEAM__INSIGHTS)} />,
        },

        /*
        |-----------------------------------------------------------
        | Section: Manager Approvals
        |-----------------------------------------------------------
        */

        //<ApprovalsInternalRoutes />, ManagerPaths.ApprovalsWildcard
        {
          path: '/manager/approvals/',
          element: <Navigate to="progress" />,
          enabled: isManager,
        },
        {
          path: '/manager/approvals/program',
          enabled: isManager,

          element: (
            <ManagerApprovalsListComponent
              title={i18n.t(k.APPROVAL__PROGRAM__PLURAL)}
              approvalType={RequestApprovalType.Program}
              approvalItemType={ApprovalsDiscriminator.Program}
            />
          ),
        },
        {
          path: '/manager/approvals/time',
          enabled: isManager,

          element: (
            <ManagerApprovalsList
              title={i18n.t(k.APPROVAL__TIME__PLURAL)}
              approvalItemType={ApprovalsDiscriminator.Incentive}
            />
          ),
        },
        {
          path: '/manager/approvals/finance',
          enabled: isManager,
          element: (
            <ManagerApprovalsList
              title={i18n.t(k.APPROVAL__FINANCE__PLURAL)}
              approvalItemType={ApprovalsDiscriminator.Incentive}
            />
          ),
        },
        {
          path: '/manager/approvals/progress',
          enabled: isManager,

          element: <ManagerReportsWrapper title={i18n.t(k.REPORT__PLURAL)} />,
        },
        {
          path: '/manager/approvals/resource',
          enabled: isManager,
          element: (
            <ManagerApprovalsListComponent
              title={i18n.t(k.APPROVAL__RESOURCE__PLURAL)}
              approvalType={RequestApprovalType.Resource}
              approvalItemType={ApprovalsDiscriminator.LearningResource}
            />
          ),
        },

        /*
        |-----------------------------------------------------------
        | Section: Admin
        |-----------------------------------------------------------
        */

        {
          path: '/admin/',
          enabled,
          element: <Navigate to={adminProgramsMarketplaceRoute()} />,
        },
        {
          path: '/admin/programs',
          enabled,
          element: <Navigate to={adminProgramsMarketplaceRoute()} />,
        },
        {
          path: '/admin/programs/:tab/*',
          enabled,
          element: <AdminProgramsPage title={i18n.t(k.PROGRAM__PLURAL)} />,
          children: [
            {
              path: ':customProgramId/edit/*',
              enabled,

              element: (
                <CustomProgramAdminDrawerContainer
                  title={i18n.t(k.PROGRAM)}
                  getScreenNavItemUrlPath={adminProgramsCustomProgramManage}
                  visible
                />
              ),
            },
            {
              path: ':customProgramId/view/*',
              enabled,

              element: (
                <CustomProgramUserDrawerWithRoutingContainer
                  title={i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}
                  isActivePlanItem={false}
                  showCohortButton
                />
              ),
            },
          ],
        },
        {
          path: '/admin/program-detail/:id',
          enabled,

          element: (
            <PlanItemDetailPageContainer
              title={i18n.t(k.PROGRAM__DETAILS)}
              visible
              itemType={PlanItemType.Program}
            />
          ),
        },
        {
          path: '/admin/academies/new',
          enabled,

          element: <NewAcademyContainer />,
        },
        {
          path: '/admin/academies',
          enabled,

          element: <AdminAcademiesPage title={i18n.t(k.ACADEMY__PLURAL)} />,
          children: [
            {
              path: 'configure/:academyId/:screen/*',
              enabled,
              element: (
                <SetupContainerContainer
                  title={i18n.t(k.GENERIC__SETUP)}
                  // onClose={() => navigate(getAdminAcademiesUrl())}
                  getScreenNavItemUrlPath={getAdminAcademyConfigureDrawerRoute}
                />
              ),
            },
            {
              path: 'view/:academyId/*',
              enabled,
              element: (
                <AcademyDrawerContainer
                  title={i18n.t(k.GENERIC__OVERVIEW)}
                  isActivePlanItem={false}
                  getScreenNavItemUrlPath={
                    getAcademyUserDrawerAdminPreviewScreenNavItemUrlPath
                  }
                />
              ),
            },
          ],
        },
        {
          path: '/admin/licenses/*',
          enabled,

          element: <AdminLicensesPage title={i18n.t(k.LICENSE__PLURAL)} />,
        },

        /*
        |-----------------------------------------------------------
        | Section: Admin Investments
        |-----------------------------------------------------------
        */
        // {
        //   path: '/admin/investments/*',
        //   enabled,
        //   element: <InvestmentsInternalRoutes />,
        // },

        {
          path: '/admin/investments',
          enabled,
          element: <Navigate to={`admin/investments/incentives`} />,
        },
        {
          path: '/admin/investments/incentives',
          enabled,
          element: <Incentives title={i18n.t(k.INCENTIVE__PLURAL)} />,
        },

        /*
        |-----------------------------------------------------------
        | Section: Admin Approvals
        |-----------------------------------------------------------
        */

        // <ApprovalsInternalRoutes />, '/admin/approvals/*'

        {
          path: '/admin/approvals',
          enabled,
          element: <Navigate to={'/admin/approvals/progress'} />,
        },
        {
          path: '/admin/approvals/progress',
          enabled,
          element: <ManagerReportsWrapper title={i18n.t(k.REPORT__PLURAL)} />,
        },
        {
          path: '/admin/approvals/program',
          enabled,
          element: (
            <AdminApprovalsListComponent
              title={i18n.t(k.APPROVAL__PROGRAM__PLURAL)}
              approvalType={RequestApprovalType.Program}
            />
          ),
        },
        {
          path: '/admin/approvals/time',
          enabled,
          element: (
            <ManagerApprovalsList
              title={i18n.t(k.APPROVAL__TIME__PLURAL)}
              approvalItemType={ApprovalsDiscriminator.Incentive}
            />
          ),
        },
        {
          path: '/admin/approvals/finance',
          enabled,
          element: (
            <ManagerApprovalsList
              title={i18n.t(k.APPROVAL__FINANCE__PLURAL)}
              approvalItemType={ApprovalsDiscriminator.Incentive}
            />
          ),
        },
        {
          path: '/admin/approvals/resource',
          enabled,
          element: (
            <AdminApprovalsListComponent
              title={i18n.t(k.APPROVAL__RESOURCE__PLURAL)}
              approvalType={RequestApprovalType.Resource}
            />
          ),
        },

        /*
        |-----------------------------------------------------------
        | Section: Admin
        |-----------------------------------------------------------
        */

        // {
        //   path: AdminPaths.Wildcard,
        //   enabled,
        //   element: (
        //     <AdminWrapper isAdmin={isAdmin}>
        //       <AdminRoutes />
        //     </AdminWrapper>
        //   ),
        // },

        ...[
          {
            path: '/admin/reports',
            enabled,
            element: <AdminReportsWrapper title={i18n.t(k.REPORT__PLURAL)} />,
          },
          {
            path: '/admin/initiatives',
            enabled,

            element: <InitiativesWrapper title={i18n.t(k.INITIATIVE)} />,
          },
          {
            path: '/admin/initiatives-create',
            enabled,
            element: (
              <NewInitiativesContainer
                title={i18n.t(k.INITIATIVE__CREATE)}
                showDrawer={false}
                onCompleted={undefined}
              />
            ),
          },
          {
            path: '/admin/initiatives-edit/:id',
            enabled,

            element: (
              <NewInitiativesContainer
                title={i18n.t(k.INITIATIVE__EDIT)}
                showDrawer={false}
                onCompleted={undefined}
              />
            ),
          },
          {
            path: '/admin/prepayment',
            enabled,

            element: <Prepayment title={i18n.t(k.CARD__VIRTUAL_CARD__FUNDS)} />,
          },
          {
            path: '/admin/qa',
            enabled,

            element: <QaPage title={i18n.t(k.QA)} />,
          },
          {
            path: '/admin/developer-admin',
            enabled,

            element: <DeveloperAdminPage title={i18n.t(k.DEVELOPER)} />,
          },
          {
            path: '/admin/people',
            enabled,

            element: <AdminUsers title={i18n.t(k.GENERIC__PEOPLE)} />,
          },
          {
            path: '/admin/people/:userId/*',
            enabled,

            element: <DrilldownContainer title={i18n.t(k.GENERIC__PEOPLE)} />,
          },
        ].map((route) => {
          route.element = (
            <AdminWrapper isAdmin={isAdmin}>{route.element}</AdminWrapper>
          );
          return route;
        }),

        /*
        |-----------------------------------------------------------
        | Email
        |-----------------------------------------------------------
        */

        {
          path: EmailPreviewPaths.Wildcard,
          enabled: !isProductionAny,
          element: <EmailPreview />,
        },

        /*
        |-----------------------------------------------------------
        | 404
        |-----------------------------------------------------------
        */

        {
          path: '*',
          enabled,
          element: <PageNotFound title={i18n.t(k.ERROR__PAGE__NOT__FOUND)} />,
        },
      ],
    },
  ];

  return routeElements;
};

export default useGetEnabledPrimaryRoutesShared;

/*
|-----------------------------------------------------------
| Wrappers
|-----------------------------------------------------------
*/

function CustomProgramAdminDrawerContainer(props: any) {
  const navigate = useNavigate();
  return (
    <CustomProgramAdminDrawer
      onClose={() => navigate(adminProgramsCustomRoute())}
      {...props}
    />
  );
}

function CustomProgramUserDrawerWithRoutingContainer(props: any) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <CustomProgramUserDrawerWithRouting
      onClose={() => navigate(adminProgramsCustomRoute())}
      getScreenNavItemUrlPath={(id, screen) =>
        adminCustomProgramScreenRoute(id, screen, searchParams)
      }
      {...props}
    />
  );
}

function PlanItemDetailPageContainer(props: any) {
  const onClose = useGoBack();
  return <PlanItemDetailPage {...props} onClose={onClose} />;
}

function SetupContainerContainer(props: any) {
  const navigate = useNavigate();
  return (
    <SetupContainer
      {...props}
      onClose={() => navigate(getAdminAcademiesUrl())}
    />
  );
}

function AcademyDrawerContainer(props: any) {
  const navigate = useNavigate();
  return (
    <AcademyDrawer
      {...props}
      onClose={() => navigate(getAdminAcademiesUrl())}
    />
  );
}
