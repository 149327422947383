import {i18n, k} from '@i18n/translate';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import OptionsForm from '@components/reusable/OptionForm';
import {ContentIconColors, ContentIcons} from '@blocks/ContentTypeIcon';
import {ContentType} from '@generated/enums';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/
const getDisplayAttributes = (contentType: ContentType) => {
  return {
    color: ContentIconColors[contentType].color,
    background: ContentIconColors[contentType]['backgroundColor'],
    Icon: ContentIcons[contentType],
  };
};
export const contentOptionsWithDiscussions = (
  onClickContent: (contentType: ContentType) => void
) => [
  {
    title: i18n.t(k.GENERIC__CONTENT),
    subtitle: i18n.t(k.PROGRAM__CUSTOM__CURRICULUM__CONTENT__ADD),
    onClick: () => onClickContent(ContentType.Text),
    ...getDisplayAttributes(ContentType.Text),
  },
  {
    title: i18n.t(k.PROJECT),
    subtitle: i18n.t(k.GENERIC__ADD_PROJECT_OR_ASSIGNMENT),
    onClick: () => onClickContent(ContentType.Project),
    ...getDisplayAttributes(ContentType.Project),
  },
  {
    title: i18n.t(k.EVENT),
    subtitle: i18n.t(k.EVENT__SCHEDULE_LIVE),
    onClick: () => onClickContent(ContentType.Event),
    ...getDisplayAttributes(ContentType.Event),
  },
  {
    title: i18n.t(k.DISCUSSION),
    subtitle: i18n.t(k.DISCUSSION__CREATE),
    onClick: () => onClickContent(ContentType.Discussion),
    ...getDisplayAttributes(ContentType.Discussion),
  },
  {
    title: i18n.t(k.RATING__SKILL_RATING),
    subtitle: i18n.t(k.RATING__ADMIN_STEP_DESCRIPTION),
    onClick: () => onClickContent(ContentType.Discussion),
    ...getDisplayAttributes(ContentType.Discussion),
  },
];
export const contentOptions = (
  onClickContent: (contentType: ContentType) => void
) => [
  {
    title: i18n.t(k.CONTENT),
    subtitle: i18n.t(k.PROGRAM__CUSTOM__CURRICULUM__CONTENT__ADD),
    onClick: () => onClickContent(ContentType.Text),
    ...getDisplayAttributes(ContentType.Text),
  },
  {
    title: i18n.t(k.PROJECT),
    subtitle: i18n.t(k.GENERIC__ADD_PROJECT_OR_ASSIGNMENT),
    onClick: () => onClickContent(ContentType.Project),
    ...getDisplayAttributes(ContentType.Project),
  },
  {
    title: i18n.t(k.EVENT),
    subtitle: i18n.t(k.EVENT__SCHEDULE_LIVE),
    onClick: () => onClickContent(ContentType.Event),
    ...getDisplayAttributes(ContentType.Event),
  },
];

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ICurriculumScreenNewContentButtonProps {
  onClickContent: (contentType: ContentType) => void;
}

function CurriculumScreenNewContentButton({
  onClickContent,
}: ICurriculumScreenNewContentButtonProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const showDiscussions = isFeatureFlagOn.CustomProgramDiscussionSteps;
  return (
    <OptionsForm
      buttonProps={{tag: ButtonTags.Secondary}}
      buttonLabel={i18n.t(k.CTA__ADD_ITEM__FORMAT, {
        item: i18n.t(k.GENERIC__CONTENT),
      })}
      options={
        showDiscussions
          ? contentOptionsWithDiscussions(onClickContent)
          : contentOptions(onClickContent)
      }
    />
  );
}

export default CurriculumScreenNewContentButton;
