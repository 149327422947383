import React from 'react';
import {
  BudgetDonutContainerProps,
  CustomPieConfig,
} from './BudgetDonut.interfaces';
import BudgetDonutView from './BudgetDonut.view';
import {getCost, useDeterminePlbGaugeStatus} from './BudgetDonut.hooks';
import {COLORS} from '@utils/constants';
import {DisplayStatus, FinanceAmountType, FinanceType} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import {formatCurrency} from '@utils/moneyUtils';
import {BudgetSections, UserPlanItemStatusString} from '@models/clientEnums';
import {useGetCurrencyExchangeQuery} from '@hooks/apiEndpoints/localization/queries';
import {useLocation} from 'react-router-dom';
import {
  UserPlanProgramItemVM,
  UserProgramInsightVM,
} from '@models/serverModels';

function BudgetDonutContainer({
  exchangeRate,
  financeLearningBuds,
  isFinanceLearningBudsSuccess,
  personalization,
  user,
  userProgramPlan,
  drillDownData,
}: BudgetDonutContainerProps) {
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery();
  const {pathname} = useLocation();
  const notManagePath =
    pathname.includes('team-insights') || pathname.includes('people');
  const getSum = (directBillingItemStatus) =>
    (userProgramPlan as UserPlanProgramItemVM[])
      ?.filter(({status}) => status === directBillingItemStatus)
      ?.reduce(
        (sum, {licenseCost, licenseCurrency}) =>
          sum +
          getCost(
            licenseCost,
            licenseCurrency,
            getCurrencyExchangeQuery?.data,
            user?.currency
          ),
        0
      );

  const directBillingPending = !notManagePath
    ? getSum(UserPlanItemStatusString.Pending)
    : 0;
  const directBillingApproved = !notManagePath
    ? getSum(UserPlanItemStatusString.Approved)
    : 0;
  const {
    balance,
    awaitingApproval,
    spentAmount,
    remaining,
    financeAmountType,
    totalApprovedFunds,
    pendingReimbursement,
    financeType,
  } = financeLearningBuds ?? {};

  const isReimbursement = financeType == FinanceType.TuitionReimbursement;
  const isDirectBilling = financeType == FinanceType.DirectBilling;
  const isExternalReimbursement =
    financeType == FinanceType.ExternalReimbursement;

  const determinePlbGaugeStatus = useDeterminePlbGaugeStatus(
    financeLearningBuds?.recurrenceFrequency,
    financeLearningBuds?.recurrenceType,
    financeLearningBuds?.advancedAmount,
    financeLearningBuds?.financeIncentiveAmount,
    user?.currency,
    exchangeRate
  );
  const getColor = (type) =>
    ({
      remaining: COLORS.Neutral200,
      ready: COLORS.Green700,
      spent: COLORS.Neutral300,
      pending: COLORS.Neutral300,
      awaiting: COLORS.Yellow500,
    }[type]);

  const noLimit = financeAmountType === FinanceAmountType.Any;

  const data = [
    {
      type: BudgetSections.Ready,
      title:
        isReimbursement || isDirectBilling || isExternalReimbursement
          ? i18n.t(k.STATUS__APPROVED)
          : i18n.t(k.GENERIC__READY_TO_SPEND),
      value: isReimbursement
        ? totalApprovedFunds
        : isDirectBilling
          ? directBillingApproved
          : balance,
    },
    {
      type: BudgetSections.Awaiting,
      title: i18n.t(k.STATUS__AWAITING_APPROVAL),
      value: notManagePath
        ? drillDownData?.awaitingApproval
        : isDirectBilling
        ? directBillingPending
        : awaitingApproval,
    },
    isReimbursement && {
      type: BudgetSections.Pending,
      title: i18n.t(k.REIMBURSEMENT__PENDING),
      value: notManagePath
        ? drillDownData?.pendingReimbursedAmount
        : pendingReimbursement,
    },
    !isDirectBilling &&
      !isExternalReimbursement && {
        type: BudgetSections.Spent,
        title: isReimbursement
          ? i18n.t(k.REIMBURSEMENT__REIMBURSED)
          : i18n.t(k.GENERIC__SPENT),
        value:
          notManagePath && isReimbursement
            ? drillDownData?.reimbursedAmount
            : notManagePath
            ? drillDownData?.spentAmount
            : spentAmount,
      },
    !noLimit && {
      type: BudgetSections.Remaining,
      title: i18n.t(k.GENERIC__REMAINING),
      value: notManagePath ? drillDownData?.remainingAmount : remaining,
    },
  ];

  const config: CustomPieConfig = {
    legend: false,
    tooltip: false,
    label: false,
    height: 200,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ({type}) => getColor(type),
    pattern: ({type}, color) => {
      if (type === 'pending') {
        return {
          type: 'line',
          cfg: {
            backgroundColor: color,
            fill: COLORS.Black,
            stroke: COLORS.Black,
            size: 2,
            rotation: -45,
          },
        };
      }
    },
    radius: 1,
    innerRadius: 0.6,
    lineWidth: 3,
    width: 200,
    statistic: {
      title: {
        style: {
          color: COLORS.Neutral900,
          fontSize: '1rem',
          fontWeight: 300,
        } as any,
        content: formatCurrency(
          notManagePath
            ? drillDownData?.plbAmount
            : financeLearningBuds?.limitOnRequestedFunds,
          user?.currency,
          exchangeRate,
          {decimal: true}
        ),
      },
      content: {
        style: {
          color: COLORS.Neutral900,
          fontSize: '0.5625rem',
          fontWeight: 700,
          lineHeight: '1.25rem',
          letterSpacing: '0.05625rem',
        },
        content: i18n.t(k.GENERIC__TOTAL_BUDGET).toUpperCase(),
      },
    },
  };

  return (
    <BudgetDonutView
      config={config}
      data={data}
      determinePlbGaugeStatus={determinePlbGaugeStatus}
      exchangeRate={exchangeRate}
      getColor={getColor}
      isFinanceLearningBudsSuccess={isFinanceLearningBudsSuccess}
      hasChart={!noLimit}
      personalization={personalization}
      user={user}
      isDirectBilling={isDirectBilling || isExternalReimbursement}
    />
  );
}

export default BudgetDonutContainer;
