import styled from 'styled-components';
import {COLORS} from '@utils/constants';

export const AcademyNameButton = styled.button`
  color: ${COLORS.Blue800};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledPermission = styled.div`
  font-size: 1rem;
`;
