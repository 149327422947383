import {useState} from 'react';
import {getMemberIds, getUserAndGroupOptions} from './utils';
import {useAcademiesUsersAllAdminQuery} from '@generated/hooks';
import {TablePaginationConfig} from 'antd';
import {notify} from '@components/user/notifications';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {CompanyMembersForAdding, LoadOptions, TableParams} from './types';
import {useParams} from 'react-router-dom';
import {simpleDeleteFn} from '@store/queryClient';
import {useMutation} from 'react-query';
import {getRemoveAcademiesUserRm} from '@store/apiEndpoints/academy/mutations';
import {ManageAddedMembersTable} from './ManageAddedMembersTable';

interface Props {
  restrictedAccess: boolean;
}

export const ManageAddedMembers = ({restrictedAccess}: Props) => {
  const params = useParams();
  const academyId = params.academyId;
  const {user} = useAuth();
  const PAGE_SIZE = 10;

  // State variables
  const [inputValue, setInputValue] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pageSize: PAGE_SIZE,
    pageNumber: 1,
    sortBy: 'name',
    isAscending: true,
  });
  const [selectedMembesForAdding, setSelectedMembesForAdding] = useState<
    CompanyMembersForAdding[]
  >([]);

  // Queries
  const {data: academyMembers, invalidateExact: invalidateAcademyMembers} =
    useAcademiesUsersAllAdminQuery({
      academyId: parseInt(academyId),
      queryParams: {...tableParams},
    });

  const loadOptions: LoadOptions = async (inputValue, callback) => {
    try {
      const options = await getUserAndGroupOptions(
        inputValue,
        academyMembers.results,
        user?.companyId,
        true
      );
      callback(options);
    } catch (error) {
      callback([]);
    }
  };

  // Handle Remove User
  const removeUserAcademyAccessMutation = useMutation(
    (userId: number) => {
      const removeAcademyUsersMutation = getRemoveAcademiesUserRm(
        parseInt(params.academyId),
        userId
      );
      return simpleDeleteFn<null>(
        removeAcademyUsersMutation.path,
        removeAcademyUsersMutation.payload
      );
    },
    {
      onSuccess: async () => {
        await invalidateAcademyMembers();
      },
    }
  );
  const handleRemoveUser = (userId: number) => {
    removeUserAcademyAccessMutation.mutateAsync(userId);
  };

  // Handle Add Members to local state
  const onCompanyMemberSearchChange = (
    selectedOptions: CompanyMembersForAdding[]
  ) => {
    setSelectedMembesForAdding(selectedOptions || []);
  };

  // Add Members to Academy
  const handleClickAddMembers = async () => {
    try {
      setIsUpdating(true);
      const {userCompanyIds, groupIds} = getMemberIds(selectedMembesForAdding);

      // Mock API call with delay for testing
      // TODO: Update polling api call when api is available passing userCompanyIds and groupIds
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setSelectedMembesForAdding([]);
    } catch (error) {
      notify.academyUserAddedError();
    } finally {
      setIsUpdating(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters,
    sorter
  ) => {
    setTableParams({
      pageSize: pagination.pageSize,
      pageNumber: pagination.current,
      sortBy: sorter.field,
      isAscending: sorter.order === 'ascend',
    });
  };

  return (
    <>
      {restrictedAccess && (
        <ManageAddedMembersTable
          academyMembers={academyMembers}
          selectedMembesForAdding={selectedMembesForAdding}
          loadOptions={loadOptions}
          inputValue={inputValue}
          onCompanyMemberSearchChange={onCompanyMemberSearchChange}
          handleClickAddMembers={handleClickAddMembers}
          handleTableChange={handleTableChange}
          handleRemoveUser={handleRemoveUser}
          tableParams={tableParams}
          setInputValue={setInputValue}
          isUpdating={isUpdating}
        />
      )}
    </>
  );
};
