import Grid from '@blocks/Grid';
import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {DateInput, DateInputItem, DateText} from './Setup.styled';
import {InputLabel} from '@components/reusable/LearnInForm';
import {AcademySetupFormInstance} from './Setup.container';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {getDurationRules, getRunDateRules} from './Setup.utils';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {StyledLearnInDatePicker} from '@components/reusable/LearnInDatePicker';
import {DATE_FORMAT} from '@utils/timeUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
const FormItem = Form.Item;

interface Props {
  form?: FormInstance<AcademySetupFormInstance>;
  hasError: boolean;
}

export const DateInputs = ({form, hasError}: Props) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const startDate = Form.useWatch('startDate', form);

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('duration', value === '' ? null : value);
  };

  // prevent user from entering decimal and 'e' in duration input
  const preventDecimalAndE = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '.' || e.key.toLowerCase() === 'e') {
      e.preventDefault();
    }
  };

  return (
    isFeatureFlagOn.AcademyMemberDates && (
      <DateInput id="date-input" hasError={hasError}>
        <Grid.Box half style={{marginBottom: 0}}>
          <InputLabel
            htmlFor="duration-input"
            label={i18n.t(k.ACADEMY__SETUP__DURATION)}
          />
          <DateInputItem>
            <FormItem
              id="duration-input"
              name="duration"
              rules={getDurationRules()}>
              <LearnInInput
                tag={InputTags.PRIMARY}
                type="number"
                min={1}
                size="middle"
                aria-label={i18n.t(k.ACADEMY__SETUP__DURATION_HOURS)}
                onBlur={handleDurationChange}
                onKeyDown={preventDecimalAndE}
              />
            </FormItem>
            <DateText>{i18n.t(k.DATE__HOUR__PLURAL)}</DateText>
          </DateInputItem>
        </Grid.Box>
        <Grid.Box half>
          <InputLabel
            htmlFor="start-date-input"
            label={i18n.t(k.ACADEMY__SETUP__DATE_RANGE)}
          />
          <DateInputItem>
            <FormItem name="startDate">
              <StyledLearnInDatePicker
                id="start-date-input"
                format={DATE_FORMAT.MONTH_DAY_YEAR}
                size="large"
                placeholder={i18n.t(k.GENERIC__SELECT_DATE)}
                aria-label={i18n.t(k.DATE__START)}
              />
            </FormItem>
            <DateText>{i18n.t(k.GENERIC__TO)}</DateText>
            <FormItem name="endDate" rules={getRunDateRules(startDate)}>
              <StyledLearnInDatePicker
                id="end-date-input"
                format={DATE_FORMAT.MONTH_DAY_YEAR}
                size="large"
                placeholder={i18n.t(k.GENERIC__SELECT_DATE)}
                aria-label={i18n.t(k.DATE__END)}
              />
            </FormItem>
          </DateInputItem>
        </Grid.Box>
      </DateInput>
    )
  );
};
