/** These strings aren't customer-facing: these don't need to be localized. */
export const ADDED_AS_CONTRIBUTOR = {
  name: 'Hermione',
  permissions: 'view the academy, edit the academy, and review projects',
  type: 'program',
  programTitle: 'Xylomancy 101',
  programUrl: '/#/user/program-detail/10000',
  companyLogoUrl:
    'https://static.wikia.nocookie.net/logopedia/images/8/87/Logowizardingworld2018.png/revision/latest/scale-to-width-down/200?cb=20181127033141',
};
