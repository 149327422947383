import {useState, useEffect} from 'react';
import {COLORS} from '@utils/constants';
import {Divider, Modal} from 'antd';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {UserItemStatusVM, MessageDto} from '@generated/interfaces';
import {DisplayStatus} from '@generated/enums';
import {SubmissionPreview} from './SubmissionPreview';
import {SubmissionForm} from './SubmissionForm';
import {PeerPreview} from './PeerPreview';
import {DiscussionDrawer} from './DiscussionDrawer';
import {useAuth} from '@utils/oidc-auth-wrapper';

const {confirm} = Modal;

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  margin-bottom: 16px;
`;

const ModalBody = styled.div`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: left;
`;

export interface DiscussionStepFooterProps {
  title: string;
  description: string;
  dueDate?: string;
  messages?: MessageDto[];
  status: UserItemStatusVM;
  showSkipButton: boolean;
  skipDiscussion: (contentId: number) => void;
  unskipDiscussion: (contentId: number) => void;
  onClickSubmit: (response: string, messageId?: string) => void;
  onClickDelete: (messageId: string) => void;
  contentId: number;
  messageCount?: number;
  messagesPreview: MessageDto[];
  userMessages?: MessageDto[];
}
export function DiscussionStepFooter({
  title,
  description,
  status,
  messages,
  dueDate,
  showSkipButton,
  skipDiscussion,
  unskipDiscussion,
  contentId,
  onClickSubmit,
  onClickDelete,
  messagesPreview = [],
  userMessages = [],
  messageCount,
}: DiscussionStepFooterProps) {
  const [textResponse, setTextResponse] = useState('');
  const [drawerTextResponse, setDrawerTextResponse] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const contextTextId = 'discussion-submission-text';
  const {user} = useAuth();

  const userSubmission =
    userMessages.length > 0 ? userMessages[userMessages.length - 1] : undefined;

  const showSubmission = !isEditing && userSubmission;

  useEffect(() => {
    if (userSubmission) {
      setTextResponse(userSubmission.content);
    }
  }, [userSubmission]);

  function handleClickDelete(id?: string) {
    confirm({
      icon: false,
      closable: true,
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__DELETE_ITEM_YES__FORMAT, {
        item: i18n.t(k.SUBMISSION),
      }),
      okType: 'danger',
      title: <ModalTitle>{i18n.t(k.SUBMISSION__DELETE)}</ModalTitle>,
      content: (
        <>
          <ModalBody>{i18n.t(k.SUBMISSION__DELETE__PROMPT)}</ModalBody>
          <Divider
            style={{
              position: 'absolute',
              left: 0,
              bottom: '2.75rem',
            }}
          />
        </>
      ),
      bodyStyle: {borderRadius: '25px'},
      okButtonProps: {type: 'primary', shape: 'round', size: 'large'},
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        size: 'large',
      },
      onOk() {
        onClickDelete(id || userSubmission.id);
        setTextResponse('');
      },
    });
  }

  return (
    <div id={contextTextId} style={{padding: '0px 20px 20px 20px'}}>
      <PeerPreview
        openDrawer={() => setShowDrawer(true)}
        messages={messagesPreview}
        messageCount={messageCount}
      />
      <Divider style={{margin: '16px 0'}} />
      <DiscussionDrawer
        handleDeleteSubmission={(id: string) => {
          handleClickDelete(id);
        }}
        userId={user?.userCompanyId}
        userIsAdmin={user?.isAdmin}
        messages={messages}
        dueDate={dueDate}
        title={title}
        description={description}
        visible={showDrawer}
        setShowDrawer={(show) => setShowDrawer(show)}
        submissionForm={
          <SubmissionForm
            title={title}
            contentId={contentId}
            contextTextId={contextTextId}
            textResponse={drawerTextResponse}
            setTextResponse={(response) => setDrawerTextResponse(response)}
            isSkipped={status.displayStatus === DisplayStatus.Skipped}
            onClickSkipDiscussion={() => null}
            onClickUnskipDiscussion={() => null}
            onClickSubmit={() => {
              onClickSubmit(drawerTextResponse);
              setDrawerTextResponse('');
            }}
            showSkipButton={false}
            onCancelEdit={() => setIsEditing(false)}
          />
        }
      />
      {showSubmission ? (
        <SubmissionPreview
          handleDeleteSubmission={() => handleClickDelete()}
          handleEditSubmission={() => setIsEditing(true)}
          firstName={user?.firstName}
          lastName={user?.lastName}
          submissionText={userSubmission.content}
          timestamp={userSubmission.createdOn}
        />
      ) : (
        <SubmissionForm
          disableSubmitButtonOnClick={true}
          title={title}
          contentId={contentId}
          contextTextId={contextTextId}
          textResponse={textResponse}
          setTextResponse={(response) => setTextResponse(response)}
          isSkipped={status.displayStatus === DisplayStatus.Skipped}
          onClickSkipDiscussion={() => skipDiscussion(contentId)}
          onClickUnskipDiscussion={() => unskipDiscussion(contentId)}
          onClickSubmit={() => {
            setIsEditing(false);
            onClickSubmit(textResponse, userSubmission?.id);
          }}
          showSkipButton={showSkipButton}
          onCancelEdit={() => setIsEditing(false)}
        />
      )}
    </div>
  );
}
