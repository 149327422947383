import {
  DefaultSortOrder,
  TableColText,
} from '@components/reusable/LearnInTable';
import {AcademyNameButton, StyledPermission} from '../AcademiesTable.styles';
import {useNavigate} from 'react-router-dom';
import {
  adminAcademyReportsRoute,
  editAcademyUrl,
  viewAcademyUrl,
} from '@utils/ClientPaths';
import {i18n, k} from '@i18n/translate';
import {getTitle} from '@utils/enumMapping/MapTextToUserLanguage';
import ContextMenuButton from '@blocks/ContextMenu';
import {COLORS} from '@utils/constants';
import {getPermissionTypeDisplay, renderStatusColumn} from '../utils';
import {DATE_FORMAT} from '@utils/timeUtils';
import moment from 'moment';

export const useGetColumns = (handleDeleteAcademy, handleDuplicateAcademy) => {
  const navigate = useNavigate();

  return [
    {
      title: i18n.t(k.ACADEMY__NAME),
      dataIndex: 'name',
      key: 'name',
      render: (academyName: string, {id}) => (
        <TableColText data-testid={`table-cell-title-${id}`}>
          <AcademyNameButton onClick={() => navigate(editAcademyUrl(id))}>
            {getTitle(academyName)}
          </AcademyNameButton>
        </TableColText>
      ),
      defaultSortOrder: DefaultSortOrder.Ascend,
      sorter: true,
      width: '25%',
    },
    {
      title: i18n.t(k.PERMISSION__PLURAL),
      dataIndex: 'restrictedAccess',
      key: 'restrictedAccess',
      render: (permissions) => {
        return (
          <StyledPermission>
            {getPermissionTypeDisplay(permissions)}
          </StyledPermission>
        );
      },
      sorter: true,
    },
    {
      title: i18n.t(k.GENERIC__MEMBERS),
      dataIndex: 'memberCount',
      key: 'memberCount',
      render: (memberCount) => memberCount || '',
      sorter: true,
    },

    {
      title: i18n.t(k.DATE__START),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate) =>
        startDate ? moment(startDate).format(DATE_FORMAT.MONTH_DAY_YEAR) : '',
      sorter: true,
    },
    {
      title: i18n.t(k.DATE__END),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate) =>
        endDate ? moment(endDate).format(DATE_FORMAT.MONTH_DAY_YEAR) : '',
      sorter: true,
    },
    {
      title: i18n.t(k.STATUS__STATUS),
      dataIndex: 'status',
      key: 'status',
      render: renderStatusColumn,
      sorter: true,
    },
    {
      title: null,
      dataIndex: null,
      key: 'contextMenu',
      render: (_, row) => {
        return (
          <ContextMenuButton
            popupContainerId="main-content"
            itemId={row.id}
            menuItems={[
              {
                label: i18n.t(k.ACADEMY__VIEW),
                onClick: () => navigate(viewAcademyUrl(row.id, 'content')),
                dataTestId: `view-academy-option-${row.id}`,
              },
              {
                label: i18n.t(k.ACADEMY__EDIT),
                onClick: () => {
                  return navigate(editAcademyUrl(row.id));
                },
                dataTestId: `remove-academy-option-${row.id}`,
              },
              {
                label: i18n.t(k.REPORT__PLURAL__VIEW),
                onClick: () => navigate(adminAcademyReportsRoute(row.id)),
                dataTestId: `view-academy-option-${row.id}`,
              },
              {
                label: i18n.t(k.ACADEMY__DUPLICATE__VERB),
                onClick: () => handleDuplicateAcademy(row.id),
                dataTestId: `duplicate-academy-option-${row.id}`,
              },
              {
                label: (
                  <span style={{color: COLORS.Red800}}>
                    {i18n.t(k.ACADEMY__DELETE)}
                  </span>
                ),
                onClick: () => handleDeleteAcademy(row.id),
                dataTestId: `remove-academy-option-${row.id}`,
              },
            ].filter(Boolean)}
          />
        );
      },
    },
  ];
};
