import {
  isLocal,
  isDevelopment,
  isStaging,
  isProductionUS,
  isProductionEU,
  isProductionCA,
  isLocalDB,
  isBetaEU,
  isBetaUS,
  isProductionCustomSubDomain,
} from '@utils/environment-helpers';
import {UserManagerSettings} from 'oidc-client-ts';

class UrlConfig {
  readonly env: string;
  readonly WEB_URL: string;
  readonly API_URL: string;
  readonly DIRECT_API_URL: string;
  readonly IDENTITY_URL: string;
  readonly AUTH_CONFIG: UserManagerSettings;

  constructor() {
    const localApiBase = 'http://localhost:5200/';
    const localWebBase = 'https://localhost:5100/';
    const localIdentityBase = isLocalDB
      ? 'https://localhost:5001'
      : 'https://staging.degreed.com/develop/auth';

    //  dev
    const developmentAzureBase = 'https://dev.degreed.dev/develop';

    // staging
    const stagingAzureBase = 'https://staging.degreed.dev/develop';

    // beta
    const betaEUBase = 'https://eu.betatest.degreed.com/develop';
    const betaUSBase = 'https://betatest.degreed.com/develop';

    // production
    const productionUsBase = 'https://degreed.com/develop';
    const productionEuBase = 'https://eu.degreed.com/develop';
    const productionCaBase = 'https://ca.degreed.com/develop';
    const productionCustomSubDomainBase = `https://${location.host}/develop`;

    const apiBasePath = '/develop/api';

    this.AUTH_CONFIG = {
      authority: '',
      client_id: 'learnin-react',
      redirect_uri: '',
      silent_redirect_uri: '',
      post_logout_redirect_uri: '',
      automaticSilentRenew: true,
      response_type: 'code',
      scope: 'openid offline_access',
    };

    if (isLocal) {
      this.API_URL = localApiBase;
      this.DIRECT_API_URL = localApiBase;
      this.WEB_URL = localWebBase;
      this.AUTH_CONFIG.authority = localIdentityBase;
      this.AUTH_CONFIG.redirect_uri = localWebBase + 'signin-callback.html';
      this.AUTH_CONFIG.silent_redirect_uri =
        localWebBase + 'silent-refresh.html';
      this.AUTH_CONFIG.post_logout_redirect_uri = localWebBase;
    } else if (isDevelopment) {
      this.API_URL = apiBasePath;
      this.DIRECT_API_URL = apiBasePath;
      this.WEB_URL = developmentAzureBase;
      this.AUTH_CONFIG.authority = developmentAzureBase + '/auth';
      this.AUTH_CONFIG.redirect_uri =
        developmentAzureBase + '/signin-callback.html';
      this.AUTH_CONFIG.silent_redirect_uri =
        developmentAzureBase + '/silent-refresh.html';
      this.AUTH_CONFIG.post_logout_redirect_uri = developmentAzureBase;
    } else if (isStaging) {
      this.API_URL = apiBasePath;
      this.DIRECT_API_URL = apiBasePath;
      this.WEB_URL = stagingAzureBase;
      this.AUTH_CONFIG.authority = stagingAzureBase + '/auth';
      this.AUTH_CONFIG.redirect_uri =
        stagingAzureBase + '/signin-callback.html';
      this.AUTH_CONFIG.silent_redirect_uri =
        stagingAzureBase + '/silent-refresh.html';
      this.AUTH_CONFIG.post_logout_redirect_uri = stagingAzureBase;
    } else if (isBetaEU) {
      this.API_URL = apiBasePath;
      this.DIRECT_API_URL = apiBasePath;
      this.WEB_URL = betaEUBase;
      this.AUTH_CONFIG.authority = betaEUBase + '/auth';
      this.AUTH_CONFIG.redirect_uri = betaEUBase + '/signin-callback.html';
      this.AUTH_CONFIG.silent_redirect_uri =
        betaEUBase + '/silent-refresh.html';
      this.AUTH_CONFIG.post_logout_redirect_uri = betaEUBase;
    } else if (isBetaUS) {
      this.API_URL = apiBasePath;
      this.DIRECT_API_URL = apiBasePath;
      this.WEB_URL = betaUSBase;
      this.AUTH_CONFIG.authority = betaUSBase + '/auth';
      this.AUTH_CONFIG.redirect_uri = betaUSBase + '/signin-callback.html';
      this.AUTH_CONFIG.silent_redirect_uri =
        betaUSBase + '/silent-refresh.html';
      this.AUTH_CONFIG.post_logout_redirect_uri = betaUSBase;
    } else if (isProductionCustomSubDomain) {
      this.API_URL = apiBasePath;
      this.DIRECT_API_URL = apiBasePath;
      this.WEB_URL = productionCustomSubDomainBase;
      this.AUTH_CONFIG.authority = productionCustomSubDomainBase + '/auth';
      this.AUTH_CONFIG.redirect_uri =
        productionCustomSubDomainBase + '/signin-callback.html';
      this.AUTH_CONFIG.silent_redirect_uri =
        productionCustomSubDomainBase + '/silent-refresh.html';
      this.AUTH_CONFIG.post_logout_redirect_uri = productionCustomSubDomainBase;
    } else if (isProductionUS) {
      this.API_URL = apiBasePath;
      this.DIRECT_API_URL = apiBasePath;
      this.WEB_URL = productionUsBase;
      this.AUTH_CONFIG.authority = productionUsBase + '/auth';
      this.AUTH_CONFIG.redirect_uri =
        productionUsBase + '/signin-callback.html';
      this.AUTH_CONFIG.silent_redirect_uri =
        productionUsBase + '/silent-refresh.html';
      this.AUTH_CONFIG.post_logout_redirect_uri = productionUsBase;
    } else if (isProductionEU) {
      this.API_URL = apiBasePath;
      this.DIRECT_API_URL = apiBasePath;
      this.WEB_URL = productionEuBase;
      this.AUTH_CONFIG.authority = productionEuBase + '/auth';
      this.AUTH_CONFIG.redirect_uri =
        productionEuBase + '/signin-callback.html';
      this.AUTH_CONFIG.silent_redirect_uri =
        productionEuBase + '/silent-refresh.html';
      this.AUTH_CONFIG.post_logout_redirect_uri = productionEuBase;
    } else if (isProductionCA) {
      this.API_URL = apiBasePath;
      this.DIRECT_API_URL = apiBasePath;
      this.WEB_URL = productionCaBase;
      this.AUTH_CONFIG.authority = productionCaBase + '/auth';
      this.AUTH_CONFIG.redirect_uri =
      productionCaBase + '/signin-callback.html';
      this.AUTH_CONFIG.silent_redirect_uri =
      productionCaBase + '/silent-refresh.html';
      this.AUTH_CONFIG.post_logout_redirect_uri = productionCaBase;
    }
  }
}

export default UrlConfig;
