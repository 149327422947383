import {useCallback, useEffect, useState} from 'react';
import AddCatalogContentDrawer from './AddCatalogContentDrawer';
import {
  AcademyStepVM,
  LxpContentId,
  LxpContentSearchResult,
  LxpFacetResponse,
  LxpFacetResponseValue,
} from '@generated/interfaces';
import {TableParams} from '../../users/AdminUsers.interfaces';
import CatalogContentModal from '../CatalogContentModal/CatalogContentModal';
import {renderInputType} from '@components/reusable/CatalogContent/utils';
import {useLxpcontentSearchMutation} from '@generated/hooks';
import {useAcademiesLevelsLxpContentMutation} from '@generated/hooks';
import {useParams} from 'react-router-dom';
import {alphabetizeByName} from '@utils/filterUtils';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface AddMarketplaceProgramDrawerContainerProps {
  onClose: (callbackOptions?: {shouldRefresh?: boolean}) => void;
  existingSteps?: AcademyStepVM[];
}

export interface Tag {
  tagId: number;
  name: string;
  position: number;
  isFollowing: boolean;
  title: string;
  isFocused: boolean;
  isMentoring: boolean;
  internalUrl: string;
  resourceId: number;
  resourceType: string;
}

export interface PaginationInfo {
  startCount: number;
  endCount: number;
  totalCount: number;
}

function AddCatalogContentDrawerContainer({
  onClose,
  /**
   * @todo: use existingSteps prop to filter out steps that have been added to the Academy level already
   */
  existingSteps,
}: AddMarketplaceProgramDrawerContainerProps) {
  const PAGE_SIZE = 20;
  const {academyId, levelId} = useParams();
  const [newlySelected, setNewlySelected] = useState<LxpContentId[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    type: '',
    provider: '',
    endorsed: '',
  });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: PAGE_SIZE,
      total: 0,
    },
    field: 'dateModified',
  });
  const [selectedContent, setSelectedContent] =
    useState<LxpContentSearchResult>();
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>();
  const [showContentModal, setShowContentModal] = useState(false);
  const [data, setData] = useState<LxpContentSearchResult[]>([]);
  const [typeOptions, setTypeOptions] = useState<LxpFacetResponseValue[]>([]);
  const [providerOptions, setProviderOptions] = useState<
    LxpFacetResponseValue[]
  >([]);
  const [endorsedOptions, setEndorsedOptions] = useState<
    LxpFacetResponseValue[]
  >([]);
  const [totalPages, seTotalPages] = useState(0);
  const [requestFacets, setRequestFacets] = useState<LxpFacetResponse[]>([
    {
      id: 'Type',
      name: 'Type',
      values: [],
    },
    {
      id: 'Provider',
      name: 'Provider',
      values: [],
    },
    {
      id: 'Endorsed',
      name: 'Endorsed',
      values: [],
    },
  ]);

  const addLxpContentMutation = useAcademiesLevelsLxpContentMutation({
    onSuccess: () => {
      onClose({shouldRefresh: true});
    },
  });

  const handleClickCheckbox = (content: LxpContentId) => {
    !newlySelected.some((e) => e.id === content.id)
      ? setNewlySelected((selectedPrograms) => [...selectedPrograms, content])
      : setNewlySelected([...newlySelected.filter((x) => x.id !== content.id)]);
  };
  const handleClearSelectedPrograms = () => {
    setNewlySelected([]);
  };

  //Check or uncheck all elements
  const handleCheckAll = () => {
    setAllSelected(!allSelected);
  };

  useEffect(() => {
    !!allSelected
      ? setNewlySelected(
          data?.map((s) => {
            return {id: s.id, contentType: s.contentType};
          })
        )
      : setNewlySelected([]);
  }, [allSelected]);

  const handleTableChange = (
    pagination: Partial<{total: number}>,
    filters,
    sorter
  ) => {
    pagination.total = tableParams.pagination.pageSize;
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleSearch = (e) => {
    setDelayedSearchTerm(e.target.value);
  };

  useEffect(() => {
    //Delay the search so the user gets time to type
    const handler = setTimeout(() => {
      setSearchTerm(delayedSearchTerm);
      //return the user to the first page
      setTableParams({
        ...tableParams,
        pagination: {
          current: 1,
          pageSize: tableParams.pagination.pageSize,
          total: tableParams.pagination.pageSize,
        },
      });
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [delayedSearchTerm]);

  const lxpcontentSearch = useLxpcontentSearchMutation({
    onSuccess: (data) => {
      setData(data.results);
      setPaginationInfo({
        startCount: (data.pageNumber - 1) * data.results.length + 1,
        endCount: data.results.length * data.pageNumber,
        totalCount: data.totalResults,
      });
      const types = data?.facets.find((facet) => facet.id === 'Type');
      const typeOptionObjects = types.values.map((obj) => ({
        ...obj,
        label: obj.name == 'Episode' ? 'Podcast' : obj.name,
        value: obj.id,
      }));
      setTypeOptions(typeOptionObjects.sort(alphabetizeByName));
      const providers = data?.facets.find((facet) => facet.id === 'Provider');
      const providerOptionObjects = providers.values.map((obj) => ({
        ...obj,
        label: obj.name,
        value: obj.id,
      }));
      setProviderOptions(providerOptionObjects.sort(alphabetizeByName));
      const endorsed = data?.facets.find((facet) => facet.id === 'Endorsed');
      const endorsedOptionObjects = endorsed.values.map((obj) => ({
        ...obj,
        label: obj.name,
        value: obj.id,
      }));
      setEndorsedOptions(endorsedOptionObjects);
      seTotalPages(data?.totalPages);
    },
  });
  const searchMutation = useCallback(() => {
    //Remove empty facets
    const payloadFacets = requestFacets.filter(
      (facet) => facet.values.length > 0
    );
    lxpcontentSearch.mutate({
      payload: {
        boostRecent: true,
        pageNumber: tableParams?.pagination?.current,
        searchText: searchTerm,
        facets: payloadFacets,
        pageSize: PAGE_SIZE,
        academyId: parseInt(academyId),
      },
    });
  }, [lxpcontentSearch, requestFacets, searchTerm, tableParams?.pagination]);

  useEffect(() => {
    searchMutation();
  }, [searchTerm, tableParams]);

  const selectContent = (content) => {
    setSelectedContent(content);
    setShowContentModal(true);
  };

  const handleAddToSelection = () => {
    addLxpContentMutation.mutate({
      pathVars: {
        academyId: parseInt(academyId),
        academyLevelId: parseInt(levelId),
      },
      payload: {lxpContents: newlySelected},
    });
  };
  const handleChangeFilters = (item, filter) => {
    setFilters((prev) => ({...prev, [filter]: item}));
    const facetRequest = requestFacets;
    item.map((arrayItem) => (arrayItem.isSelected = true));
    facetRequest.map((facet) => {
      if (facet.id == filter) {
        facet.values = item;
      }
    });
    setRequestFacets(facetRequest);
    searchMutation();
  };

  return (
    <div>
      <AddCatalogContentDrawer
        dataSource={data || []}
        newlySelected={newlySelected}
        onClickCheckbox={handleClickCheckbox}
        clearSelectedPrograms={handleClearSelectedPrograms}
        onClose={onClose}
        allSelected={allSelected}
        checkAll={handleCheckAll}
        addSteps={handleAddToSelection}
        handleSearch={handleSearch}
        handleTableChange={handleTableChange}
        handleChangeFilters={handleChangeFilters}
        filters={filters}
        selectContent={selectContent}
        tableParams={tableParams}
        typeOptions={typeOptions}
        providerOptions={providerOptions}
        paginationInfo={paginationInfo}
        endorsedOptions={endorsedOptions}
        isLoading={lxpcontentSearch.isLoading}
        totalPages={totalPages}
        pageSize={PAGE_SIZE}
        existingSteps={existingSteps}
      />
      <CatalogContentModal
        visible={showContentModal}
        onCancel={() => setShowContentModal(false)}
        inputId={selectedContent?.id}
        inputType={renderInputType(selectedContent?.contentType)}
      />
    </div>
  );
}

export default AddCatalogContentDrawerContainer;
