import {useParamInSessionStorage} from '@hooks/useParamInSessionStorage';
import {getQueryParamsFromHash} from '@hooks/useSearchParamUtils';

export const useIsLxpAdmin = (enabled: boolean = true) => {
  const sessionLxpAdmin = sessionStorage.getItem('lxpAdmin') === 'true';
  if (!sessionLxpAdmin) {
    return useParamInSessionStorage(
      enabled,
      'lxpAdmin',
      String(getQueryParamsFromHash())
    );
  }
  return sessionLxpAdmin;
};
