import {queryHookFactory} from './../../utils';

import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Configuration/ConfigurationController.cs

/*
|--------------------------------------------------------------------------
|  get-web-base-url 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useWebBaseUrlQuery} from '@generated/hooks';
 *
 *  const {
 *    data: webBaseUrl,
 *    isLoading: isWebBaseUrlLoading,
 *    invalidateExact: invalidateWebBaseUrl,
 *  } = useWebBaseUrlQuery(null)
 *
 */

export const useWebBaseUrlQuery = queryHookFactory<
  null,
  string // return type
>('useWebBaseUrlQuery', () => `configuration/get-web-base-url`, {
  responseSchema: z.string(),
});

// CACHED
export const useWebBaseUrlQueryCached = queryHookFactory<
  null,
  string // return type
>(
  'useWebBaseUrlQuery',
  () => `configuration/get-web-base-url`,
  {responseSchema: z.string()},
  {staleTime: 600000}
);
