import {
  ApprovalRequestVM,
  CostCenterOverridePayload,
  CostCenterUserRequestOverridePayload,
  UpdateApprovalRequest,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {UpdateApprovalRequestSchema} from '../zodSchemas/UpdateApprovalRequestSchema.schema';
import {CostCenterOverridePayloadSchema} from '../zodSchemas/CostCenterOverridePayloadSchema.schema';
import {CostCenterUserRequestOverridePayloadSchema} from '../zodSchemas/CostCenterUserRequestOverridePayloadSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Approvals/AdminApprovalsController.cs

/*
|--------------------------------------------------------------------------
|  update-approval 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsUpdateApprovalMutation} from '@generated/hooks';
 *
 *  const adminApprovalsUpdateApproval = useAdminApprovalsUpdateApprovalMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  adminApprovalsUpdateApproval.mutate({
 *    payload: {
 *      ...UpdateApprovalRequest
 *    },
 *  })
 *
 */

export const useAdminApprovalsUpdateApprovalMutation = mutationHookFactory<
  UpdateApprovalRequest, // payload
  null,
  ApprovalRequestVM // return type
>(
  'useAdminApprovalsUpdateApprovalMutation',
  'post',
  () => `admin-approvals/update-approval`,
  {payloadSchema: UpdateApprovalRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  update-cost-center 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsUpdateCostCenterMutation} from '@generated/hooks';
 *
 *  const adminApprovalsUpdateCostCenter = useAdminApprovalsUpdateCostCenterMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  adminApprovalsUpdateCostCenter.mutate({
 *    payload: {
 *      ...CostCenterOverridePayload
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsUpdateCostCenterMutation = mutationHookFactory<
  CostCenterOverridePayload, // payload
  null,
  null
>(
  'useAdminApprovalsUpdateCostCenterMutation',
  'post',
  () => `admin-approvals/update-cost-center`,
  {payloadSchema: CostCenterOverridePayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-user-request-approval 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAdminApprovalsUpdateUserRequestApprovalMutation} from '@generated/hooks';
 *
 *  const adminApprovalsUpdateUserRequestApproval = useAdminApprovalsUpdateUserRequestApprovalMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  adminApprovalsUpdateUserRequestApproval.mutate({
 *    payload: {
 *      ...UpdateApprovalRequest
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAdminApprovalsUpdateUserRequestApprovalMutation =
  mutationHookFactory<
    UpdateApprovalRequest, // payload
    null,
    boolean // return type
  >(
    'useAdminApprovalsUpdateUserRequestApprovalMutation',
    'post',
    () => `admin-approvals/update-user-request-approval`,
    {payloadSchema: UpdateApprovalRequestSchema}
  );

/*
|--------------------------------------------------------------------------
|  update-user-request-cost-center 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateUserRequestCostCenterMutation} from '@generated/hooks';
 *
 *  const updateUserRequestCostCenter = useUpdateUserRequestCostCenterMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateUserRequestCostCenter.mutate({
 *    payload: {
 *      ...CostCenterUserRequestOverridePayload
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useUpdateUserRequestCostCenterMutation = mutationHookFactory<
  CostCenterUserRequestOverridePayload, // payload
  null,
  null
>(
  'useUpdateUserRequestCostCenterMutation',
  'post',
  () => `admin-approvals/update-user-request-cost-center`,
  {payloadSchema: CostCenterUserRequestOverridePayloadSchema}
);
