import {LDContext, ProviderConfig} from 'launchdarkly-react-client-sdk';
import {
  isBetaEU,
  isBetaUS,
  isDevelopmentAny,
  isLocal,
  isProductionEU,
  isProductionCA,
  isStagingAny,
} from './environment-helpers';

export enum FeatureFlagTreatments {
  On = 'on',
  Off = 'off',
  Control = 'control',
}

export enum FeatureFlagExperiments {
  PeerVisibility = 'feature__peer_visibility',
  PeerVisibilityForCustomPrograms = 'feature__peer_visibility_for_custom_programs',
  TeamInsights = 'feature__team_insights',
  PrepaymentTab = 'feature__prepayment-tab',
  PrepaymentLinkNewBankAccount = 'feature__link-new-bank-account-to-stripe',
  DisableProgressUpdates = 'feature__disable-progress-updates',
  CustomPrograms = 'feature__custom-programs',
  OnlyCustomProgramsUser = 'feature__only-show-custom-programs-to-user',
  TimeIncentives = 'feature__time-incentives',
  Academies = 'feature__academies',
  ConnectCalendar = 'feature__connect-calendar',
  GoogleCalendar = 'feature__google-calendar',
  Licenses = 'feature__licenses',
  ExternalReimbursements = 'feature__external-reimbursements',
  PseudoLocale = 'feature__locale-testing',
  CostCenter = 'feature__cost-center',
  DisableVirtualCards = 'feature__disable-virtual-cards',
  Incentives = 'feature__incentives',
  SlackControls = 'temp__slack-controls',
  AcademyPeople = 'feature__academy-people',
  LearnInReimbursements = 'feature__learn-in-reimbursements',
  CredsparkDemo = 'temp__credspark_demo',
  ReportStatusTag = 'feature__report_status_tag',
  UnspentFunds = 'feature__unspent_funds',
  Subscriptions = 'temp__subscriptions',
  TranslatePlbFields = 'temp__translate_plb_fields',
  HideMarketplace = 'feature__custom_program_hide_marketplace',
  ProjectFeedback = 'temp__show_project_feedback',
  ProductSwitcher = 'temp_product_switcher',
  RenameApprovalFields = 'temp_rename_approval_fields',
  RequestWithCurrency = 'temp__request_with_currency',
  Swedish = 'feature__language_swedish',
  Russian = 'feature__language_russian',
  Polish = 'feature__language_polish',
  Dutch = 'feature__language_dutch',
  Korean = 'feature__language_korean',
  Italian = 'feature__language_italian',
  SpainSpanish = 'feature__language_spain_spanish',
  Danish = 'feature__language_danish',
  HideHeaderOnResubmit = 'temp__hide_header_resubmit',
  HideSubtextForResubmissions = 'temp__hide_subtext_for_resubmissions',
  NewTags = 'temp__status-tags-ui',
  CustomProgramDiscussionSteps = 'temp__cp_discussion_steps',
  WhatsNext = 'feature__whats_next',
  InstructionText = 'feature__instruction_text',
  Prorating = 'temp__prorate_budgets',
  HelperText = 'feature__help_text',
  SetLanguageFromBrowser = 'temp__set_language_from_browser',
  SkipAcademyStep = 'feature__skip_academy_step',
  AcademyLoadingRefresh = 'feature__academy_loading_refresh',
  ReimbursementAttachmentsMigration = 'temp__reimbursements_attachments_migration',
  DirectBilling = 'temp__direct_billing',
  YourPlanNameChange = 'temp_rename_your_plan_to_home',
  VoucherCodes = 'temp__voucher_codes',
  NudgeEmails = 'temp__nudge_emails',
  ViewMembersInCustomProgram = 'temp__view_members_in_custom_program',
  ProgramDetailsRedesign = 'temp__program_detail_redesign_p1',
  RemoveOtherIncentives = 'temp__remove_other_incentives',
  AcademyProgramApprovers = 'temp__academy_program_approver',
  WelcomeBackHeader = 'temp__your_plan_welcome_back_header',
  LxpToCmFlow = 'temp__cm_lxp_flow',
  IntegratedUser = 'temp__integrated_user',
  UpdatedLxpToCmFlow = 'temp__updated_cm_lxp_flow',
  SettingsRedesign = 'temp__settings_redesign',
  Nominations = 'feature__nomination',
  HideMarketplaceFromLXP = 'feature__hide_marketplace',
  HidePrimaryNav = 'temp__hide_primary_nav',
  ShowEmployeeEmail = 'temp__show_employee_email',
  LicenseModalUpdate = 'temp__access_program_voucher',
  ShowCustomProgramClickToJoin = 'temp__allow_custom_program_join',
  ShowAcademyClickToJoin = 'temp__allow_academy_join',
  ShowProductSwitcherLxpOption = 'temp__show_product_switcher_lxp_option',
  UnlockStepOnProgramApproval = 'feature__unlock-step-on-program-approval',
  ProgramDetailsRedesignPt2 = 'temp_program_details_redesign_pt2',
  BulkNudgeAllParticipants = 'temp__bulk_nudge_all_participants',
  HideAdminSettings = 'temp__hide_admin_settings',
  AcademyAndCpLxpFlow = 'temp__acad-and-cp-lxp_flow',
  IdleTimeLogout = 'idle_time_logout',
  SunsetMonthlyRenewal = 'temp__sunset_monthly_renewal',
  TeamInsightsHideColumns = 'temp__team_insights_hide_columns',
  HideChatWidget = 'feature__hide_help_widget',
  EditProjectSubmissions = 'feature__edit_project_submissions',
  SunsetInitiatives = 'temp__sunsetinitiatives',
  SunsetReviews = 'temp__sunsetreviews',
  HideEveryYearDirectBilling = 'temp__hide_every-year-direct-billing',
  HideEveryYearReimbursement = 'temp__hide_every-year-reimbursement',
  HideRenewalDate = 'temp__hide_renewaldate',
  HideRollOver = 'feature__hide_rollover',
  CatalogContentAcademies = 'feature__catalog_content_academies',
  AcademiesCleanUp = 'temp__academies_clean_up',
  IsLxpAdmin = 'feature__is_lxp_admin',
  HideProductSwitcher = 'temp__hide_product_switcher',
  Czech_Czech_Republic = 'feature__language_czech',
  Hungarian = 'feature__language_hungarian',
  Indonesian = 'feature__language_indonesian',
  Portuguese_Portugal = 'feature__language_portuguese_portugal',
  Romanian_Romania = 'feature__language_romanian_romania',
  Thai = 'feature__language_thai',
  Turkish = 'feature__language_turkish',
  Vietnamese = 'feature__language_vietnamese',
  Chinese_Taiwan = 'feature__language_chinese_taiwan',
  Greek_Greece = 'feature__language_greek_greece',
  ACMLayout = 'temp__acm_layout',
  LXPAdminLayout = 'temp__lxp_admin_layout',
  AdminApprovalsPagination = 'temp__admin-approvals-pagination',
  ManagerApprovalsPagination = 'temp__manager-approvals-pagination',
  DurationStrings = 'temp__duration-strings',
  AcademyMemberDates = 'temp__academy-member-dates',
  AcademySkillAssessmentSteps = 'temp__academy-skill-assessment-steps',
  ContentCatalogImageRendering = 'temp__catalog-image-rendering',
}

/**
 * This returns the treatments you want to use when developing in localhost mode rather than pulling them from launch darkly.
 */
function getLocalhostFeatureTreatments() {
  // add key values here to create feature flags on local.
  // 'reporting_v2': 'on', // example with just a string value for the treatment
  //     'billing_updates': { treatment: 'visa', config: '{ "color": "blue" }' }, //example of a defined config
  // 'show_status_bar': { treatment: 'off', config: null } // example of a null config
  return {
    [FeatureFlagExperiments.IsLxpAdmin]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.PeerVisibilityForCustomPrograms]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.TeamInsights]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.PrepaymentTab]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.PrepaymentLinkNewBankAccount]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.DisableProgressUpdates]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.CustomPrograms]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.OnlyCustomProgramsUser]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.TimeIncentives]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.Academies]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ConnectCalendar]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.GoogleCalendar]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.Licenses]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ExternalReimbursements]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.PseudoLocale]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.CostCenter]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.DisableVirtualCards]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.Incentives]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.SlackControls]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.AcademyPeople]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.LearnInReimbursements]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.CredsparkDemo]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.ReportStatusTag]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.UnspentFunds]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Subscriptions]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.TranslatePlbFields]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.HideMarketplace]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.ProjectFeedback]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ProductSwitcher]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.RenameApprovalFields]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.RequestWithCurrency]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Swedish]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Russian]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Polish]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Dutch]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Korean]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Italian]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.SpainSpanish]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Danish]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HideHeaderOnResubmit]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HideSubtextForResubmissions]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.NewTags]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.CustomProgramDiscussionSteps]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.InstructionText]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Prorating]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.WhatsNext]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HelperText]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.SetLanguageFromBrowser]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.SkipAcademyStep]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.AcademyLoadingRefresh]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ReimbursementAttachmentsMigration]:
      FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.DirectBilling]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.YourPlanNameChange]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.VoucherCodes]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.NudgeEmails]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ViewMembersInCustomProgram]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ProgramDetailsRedesign]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.RemoveOtherIncentives]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.AcademyProgramApprovers]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.WelcomeBackHeader]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.LxpToCmFlow]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.IntegratedUser]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.UpdatedLxpToCmFlow]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.SettingsRedesign]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Nominations]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.HideMarketplaceFromLXP]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.HidePrimaryNav]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.ShowEmployeeEmail]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.LicenseModalUpdate]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ShowCustomProgramClickToJoin]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ShowAcademyClickToJoin]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ShowProductSwitcherLxpOption]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.UnlockStepOnProgramApproval]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ProgramDetailsRedesignPt2]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.BulkNudgeAllParticipants]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HideAdminSettings]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.AcademyAndCpLxpFlow]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.IdleTimeLogout]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.SunsetMonthlyRenewal]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.TeamInsightsHideColumns]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HideChatWidget]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.EditProjectSubmissions]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.SunsetInitiatives]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.SunsetReviews]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HideEveryYearDirectBilling]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HideEveryYearReimbursement]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HideRenewalDate]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HideRollOver]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.CatalogContentAcademies]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.AcademiesCleanUp]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.HideProductSwitcher]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Czech_Czech_Republic]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Hungarian]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Indonesian]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Portuguese_Portugal]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Romanian_Romania]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Thai]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Turkish]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Vietnamese]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Chinese_Taiwan]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.Greek_Greece]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ACMLayout]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.LXPAdminLayout]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.AdminApprovalsPagination]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ManagerApprovalsPagination]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.DurationStrings]: FeatureFlagTreatments.On,
    [FeatureFlagExperiments.AcademyMemberDates]: FeatureFlagTreatments.Off,
    [FeatureFlagExperiments.AcademySkillAssessmentSteps]:
      FeatureFlagTreatments.On,
    [FeatureFlagExperiments.ContentCatalogImageRendering]:
      FeatureFlagTreatments.On,
  };
}

function getLDKey() {
  if (isLocal) return '645524c6dec45c1335d67c27';
  if (isDevelopmentAny) return '645524c6dec45c1335d67c27';
  if (isStagingAny) return '645524c6dec45c1335d67c27';
  if (isBetaEU) return '6647637b6cb83d10685667fa';
  if (isBetaUS) return '66476571f9860a0fd627dbcb';
  if (isProductionEU) return '6647658a611bfc107de98155';
  if (isProductionCA) return '67916d1c32ebe209b41c9c53';
  return '645524c6dec45c1335d67c28';
}

export function getLxpLDKey() {
  if (isLocal) return '5d2d052951ef5906f1c14064';
  if (isDevelopmentAny) return '5d2d052951ef5906f1c14064';
  if (isStagingAny) return '5d2d052951ef5906f1c14064';
  if (isBetaEU) return '5da745a482ce5308b4b0ce95';
  if (isBetaUS) return '5d7feab1f36a0007ada65d10';
  if (isProductionEU) return '5da7455782ce5308b4b0ce7d';
  if (isProductionCA) return '609da0c82cfc680dc9ed003f';
  return '5d2d052951ef5906f1c14065';
}

export const getLocalHostTreatment = (key: string) => {
  const treatments = getLocalhostFeatureTreatments();
  return treatments[key];
};

export const getLDConfig = (user: {
  userCompanyId: string;
  companyId: string;
}): ProviderConfig => ({
  clientSideID: getLDKey(),
  context: {
    kind: 'user',
    key: user?.userCompanyId,
    company: user?.companyId,
  },
  options: {
    streaming: false,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
});

// Helper to convert LD flags from true/false to on/off to support existing logic in place
export const isLDFeatureFlagOn = (flagStatus: boolean) => {
  return flagStatus ? FeatureFlagTreatments.On : FeatureFlagTreatments.Off;
};
