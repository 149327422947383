import {
  dateTimeSorter,
  renderDateTime,
  stringSorter,
  TooltipCol,
} from '@components/reusable/LearnInTable';
import {i18n, k} from '@i18n/translate';
import {RemoveMemberButton} from './RemoveMemberButton';

export const getAcademyMemberColumns = (
  handleRemoveUser: (userId: number) => void,
  showEmployeeEmail: boolean
) => {
  return [
    showEmployeeEmail
      ? TooltipCol({
          dataIndex: 'name',
          title: i18n.t(k.EMPLOYEE__NAME),
          tooltipDataIndex: 'email',
          width: 350,
        })
      : {
          title: i18n.t(k.EMPLOYEE__NAME),
          dataIndex: 'name',
          key: 'name',
          sorter: stringSorter<any>('name'),
        },
    {
      title: i18n.t(k.DATE__ADDED),
      dataIndex: 'dateAdded',
      key: 'dateAdded',
      render: renderDateTime(),
      sorter: dateTimeSorter('dateAdded'),
    },
    {
      title: null,
      dataIndex: 'status',
      key: 'status',
      render: (_, {userCompanyId}) =>
        RemoveMemberButton({handleRemoveUser, userCompanyId}),
    },
  ];
};
