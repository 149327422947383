import {LxpContentType} from '@generated/enums';
import {
  LxpPreviewImageContainer,
  PreviewImgContainer,
  LxpPreviewImage,
} from './LxpContentCard.styled';
import {getDefaultContentImage} from './LxpContentCard.utils';
import {i18n, k} from '@i18n/translate';
import useFeatureFlags from '@hooks/useFeatureFlags';

interface Props {
  image: string;
  title: string;
  contentType: LxpContentType;
}

export const LxpContentImage = ({image, title, contentType}: Props) => {
  const {icon, backgroundColor} = getDefaultContentImage(contentType);
  const isIcon = !image || image === icon;
  const lxpProxy = `https://img.degreed.com/image/fetch/pg_1/w_133,h_75,c_fill,g_faces:center,f_auto,q_auto,dpr_2.0`;
  const {isFeatureFlagOn} = useFeatureFlags();

  // Feature flag will turn on image proxy via lxp
  const imgSrc =
    isFeatureFlagOn.ContentCatalogImageRendering && !isIcon
      ? `${lxpProxy}/${encodeURIComponent(image)}`
      : image;

  return (
    <PreviewImgContainer>
      <LxpPreviewImageContainer
        style={{
          backgroundColor: backgroundColor,
        }}>
        <LxpPreviewImage
          imageType={
            isIcon
              ? 'icon'
              : isFeatureFlagOn.ContentCatalogImageRendering
                ? 'proxy'
                : 'regular'
          }
          src={imgSrc}
          alt={`${title} ${i18n.t(k.LXP__CONTENT)}`}
        />
      </LxpPreviewImageContainer>
    </PreviewImgContainer>
  );
};
