import './ManagerPreApprovalDrawer.scss';
import {ApprovalStatus, ApprovalType} from '@generated/enums';
import {getManagerApprovalDetailsRq} from '@store/apiEndpoints';
import {
  ResourceApprovalRequest,
  UserRequestApprovalVM,
} from '@models/serverModels';
import {PreApprovalDrawer} from '@components/admin/pages/approvals/components/PreApprovalDrawer';
import {useGetApprovalRequest} from '@hooks/useGetApprovalRequest';
import useApprovals from '@hooks/useApprovals';

interface Props {
  activeTab: ApprovalStatus;
  approvalId: number;
  approvalType: ApprovalType;
  isReadonly?: boolean;
  licenseId?: number;
  onCancel: () => void;
  onOk: () => void;
  selectedApproval?: UserRequestApprovalVM | ResourceApprovalRequest | null;
  selectedCostCenter?: string;
  showPreApprovalDrawer: boolean;
  updateCostCenter?: (
    costCenter: string,
    userRequestApprovalId: number,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  costCenter?: string;
  userRequestType?: string;
}

export default function ManagerPreApprovalDrawer({
  activeTab,
  approvalId,
  approvalType,
  isReadonly,
  licenseId,
  onCancel,
  onOk,
  selectedApproval,
  selectedCostCenter,
  showPreApprovalDrawer,
  updateCostCenter,
  userRequestType,
}: Props) {
  const {
    formRef,
    confirmLoading,
    setConfirmLoading,
    getApprovalDetailsQuery,
    updateFundsExpirationMutation,
    requestData,
    requestLoading,
    requestSuccess,
  } = useGetApprovalRequest({
    approvalType,
    approvalId,
    getApprovalDetailsRq: getManagerApprovalDetailsRq,
  });

  // Approval Request Util
  const {handleApprovalRequest, isLoadingMutation} = useApprovals('manager', {
    onSuccessHandleApprovalRequest: async () => {
      onOk();
    },
  });

  const disableUpdateApprovalStatusButtons = isLoadingMutation;

  return (
    <PreApprovalDrawer
      activeTab={activeTab}
      approvalId={approvalId}
      confirmLoading={confirmLoading}
      selectedCostCenter={selectedCostCenter}
      disableUpdateApprovalStatusButtons={disableUpdateApprovalStatusButtons}
      formRef={formRef}
      getApprovalDetailsQuery={getApprovalDetailsQuery}
      isReadonly={isReadonly}
      licenseId={licenseId}
      onCancel={onCancel}
      onOk={onOk}
      requestData={requestData}
      requestLoading={requestLoading}
      requestSuccess={requestSuccess}
      selectedApproval={selectedApproval}
      setConfirmLoading={setConfirmLoading}
      updateCostCenter={updateCostCenter}
      updateFundsExpiration={updateFundsExpirationMutation.mutate}
      userRequestType={userRequestType}
      visible={showPreApprovalDrawer}
      handleApprovalRequest={handleApprovalRequest}
    />
  );
}
