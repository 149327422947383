import Grid from '@blocks/Grid';
import {InputLabel} from '@components/reusable/LearnInForm';
import AsyncSelect from 'react-select/async';
import {i18n, k} from '@i18n/translate';
import React from 'react';
import Attachments from './Attachments';
import {AllowSkipCheckbox, Description, DueDate} from './Form';
import {getInitialDueDateAndTime} from './Form/utils';
import {FormProps} from './AddEditContent.config';

const SkillRatingForm: React.FC<FormProps> = ({
  step,
  attachmentProps,
  showRichTextUploads,
  canShowAllowSkip,
  stepRequirementComponent,
}) => {
  const initialValues = getInitialDueDateAndTime(step);

  return (
    <Grid.Container>
      <Grid.Box>
        <InputLabel
          htmlFor="skill-input"
          label={i18n.t(k.GENERIC__SKILL)}
          required
        />
        <AsyncSelect
          data-testid="skill-select"
          isLoading={false}
          id="skill-input"
          cacheOptions
          inputValue={''}
          isClearable={false}
          loadOptions={() => null}
          name="Academy Skills"
          onInputChange={() => null}
          placeholder={i18n.t(k.CTA__SEARCH_SKILLS)}
          noOptionsMessage={({inputValue}) => {
            return inputValue?.length >= 2
              ? i18n.t(k.LABEL__SEARCH_NO_SKILLS_FOUND)
              : null;
          }}
          onChange={() => null}
          isDisabled={false}
          value={[]}
        />
        <p>{i18n.t(k.RATING__ADD_STEP_TITLE_HELPER)}</p>
      </Grid.Box>

      <Grid.Box>
        <Description
          initialValue={step?.description}
          richTextUploads={showRichTextUploads}
        />
      </Grid.Box>

      <DueDate
        initialDueDate={initialValues?.initialDueDate}
        initialDueDateTime={initialValues?.initialDueDateTime}
        initialTimeZoneId={step?.dueDateTimeZone}
      />

      {stepRequirementComponent}

      {canShowAllowSkip && (
        <Grid.Box full style={{marginBottom: 12}}>
          <AllowSkipCheckbox initialValue={step?.allowSkip} />
        </Grid.Box>
      )}
      <Attachments {...attachmentProps} />
    </Grid.Container>
  );
};

export default SkillRatingForm;
