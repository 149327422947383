import {UserDetailsVM} from '@generated/interfaces';
import useFeatureFlags from '../useFeatureFlags';
import {useFlagsHaveLoaded} from '../useFlagsHaveLoaded';
import {useCanManageApprovals} from '@hooks/useCanManageApprovals';
import {useUrlContainsIndicator} from './useUrlContainsIndicator';
import {useGetUrlIncludesList} from './useGetUrlIncludesList';
import {useUrlIsInHideNavList} from './useUrlIsInHideNavList';
import {useIsIntegratedUser} from './useIsIntegratedUser';
import {useIsLxpAndAcademiesUser} from './useIsLxpAndAcademiesUser';

export const useHidePrimaryNavigation = ({
  user,
  location,
  forceOff = false,
}: {
  user: UserDetailsVM;
  location: Location;
  forceOff?: boolean;
}): boolean => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isIntegratedUser = useIsIntegratedUser(
    isFeatureFlagOn.UpdatedLxpToCmFlow,
    user
  );
  const canManageApprovals = useCanManageApprovals(user);
  const isLxpAndAcademiesUser = useIsLxpAndAcademiesUser(
    user?.isAcademiesIntegratedExperienceDisabled
  );
  const hideNavForLxpToCm =
    (isFeatureFlagOn.LxpToCmFlow ||
      isFeatureFlagOn.AcademyAndCpLxpFlow ||
      isIntegratedUser) &&
    !canManageApprovals;

  // URL comparisons
  const urlsIncluded = useGetUrlIncludesList(user, isFeatureFlagOn);
  const hideIfUrlIndicates = useUrlContainsIndicator(
    location.href,
    isFeatureFlagOn.LXPAdminLayout,
    true
  );

  const {urlIsInHideList, alwaysHidden} = useUrlIsInHideNavList(
    urlsIncluded,
    location.hash,
    user
  );

  // Determinations
  const hasApolloFlag =
    isFeatureFlagOn.ACMLayout || isFeatureFlagOn.LXPAdminLayout;
  const flagsNotLoaded = !useFlagsHaveLoaded(isFeatureFlagOn);
  const navShouldBeOff =
    urlIsInHideList &&
    (alwaysHidden ||
      hideNavForLxpToCm ||
      hideIfUrlIndicates ||
      (!hasApolloFlag && isLxpAndAcademiesUser));
  const isNavHidden = flagsNotLoaded || navShouldBeOff;

  // If the condition is to force the nav off, we take that regardless of `isNavHidden` since it's effectively an override.
  return !!(forceOff || isNavHidden);
};
