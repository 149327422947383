import {AcademyUserStatus} from './../enums';
import {AcademyUserDto} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AcademyUserDto

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyUserDtoMockData(
  args?: Partial<AcademyUserDto>
): AcademyUserDto {
  return {
    dateAdded: String(faker.date.past()),
    email: String(faker.internet.email()), // optional
    lastNudgeSent: faker.word.noun(), // optional
    lxpGroupId: faker.datatype.number(), // optional
    name: faker.name.fullName(),
    role: faker.name.jobTitle(),
    status: AcademyUserStatus.InProgress,
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyUserDtoMockData: AcademyUserDto = {
  dateAdded: '2023-03-29T22:17:19.738072',
  email: 'bjohnson@gmail.com', // optional
  lastNudgeSent: 'string', // optional
  lxpGroupId: 1, // optional
  name: 'Bobby Johnson',
  role: 'Assistant to the Regional Manager',
  status: AcademyUserStatus.InProgress,
  userCompanyId: 1,
};
