import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {AIRPLANE_DESKTOP, AIRPLANE_IMAGE} from '@assets/cloudinary-urls';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {Helmet} from 'react-helmet-async';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {focusManager} from 'react-query';

/**
 * Determine the clamp() css property margin-top. Get the smallest number and biggest number (restrictions) then compare against browser width
 */

interface PageNotFoundProps {
  title?: string;
  header?: string;
  subheader?: string;
}
export default function PageNotFound({
  header,
  subheader,
  title,
}: PageNotFoundProps) {
  const navigate = useNavigate();
  const {isFeatureFlagOn} = useFeatureFlags();

  // Hack to unstick Apollo layout after hiding the nav
  const forceFlushHack = () => {
    focusManager.setFocused(false);
    focusManager.setFocused(true);
  };

  const goHome = () => {
    navigate('/');
    forceFlushHack();
  };

  return (
    <Wrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <h1>{header || i18n.t(k.ERROR__OOPS)}</h1>
      <h2>{subheader || i18n.t(k.ERROR__FUN_404)}</h2>
      <ButtonWrapper>
        <LearnInButton tag={ButtonTags.Primary} onClick={goHome}>
          {isFeatureFlagOn.YourPlanNameChange
            ? i18n.t(k.HOME__YOUR__GO_TO)
            : i18n.t(k.PLAN__YOUR__GO_TO)}
        </LearnInButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  padding-top: 171px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background: url(${AIRPLANE_IMAGE}) left bottom no-repeat;
  background-size: 60%;
  width: 100%;
  @media (min-width: 1000px) {
    background-image: url(${AIRPLANE_DESKTOP});
    background-position-x: -10em;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
