import {RefObject, useState} from 'react';
import {Col, Divider, Row} from 'antd';
import {QuillHtmlViewer} from '@components/admin/pages/approvals/components/QuillHtmlViewer';
import {i18n, k} from '@i18n/translate';
import LearnInDrawer, {renderFooter} from '@components/reusable/LearnInDrawer';
import {
  ApprovalRequestDetailsVM,
  ResourceApprovalRequest,
  UserRequestApprovalVM,
} from '@models/serverModels';
import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  ApprovalType,
  FinanceType,
} from '@generated/enums';
import {getLicenseRq} from '@store/apiEndpoints';
import {useQuery, UseQueryResult} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import {useGetCostCentersVMQuery} from '@hooks/apiEndpoints/company/queries';
import {FormInstance} from 'antd/lib/form';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {OriginalRequestForm} from './OriginalRequestForm';
import {AdditionalRequestForm} from './AdditionalRequestForm';
import styled from 'styled-components';
import moment from 'moment';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {dateToUTCMoment} from '@utils/timeUtils';
import {getPlbL10nIfAvailable, getUsersLocale} from '@utils/l10nUtils';
import {
  PreApprovalQuestion,
  UpdateApprovalRequest,
} from '@generated/interfaces';
import {isQuestionASubscription} from '@utils/preApprovalRequest';
import {useGetCostCenterOptions} from '@components/reusable/CostCenterField/CostCenterField.hooks';
import {AdminScreenApprovalStatus} from '@models/clientEnums';
import Speaker from '@assets/svg/speaker.svg';
import {COLORS} from '@utils/constants';
import {AddUpdateLicenseVM} from '@components/admin/pages/licenses/components/AddUpdateLicenseDrawer/AddUpdateLicenseDrawer.types';

const StyledQuillHtmlViewer = styled(QuillHtmlViewer)`
  color: #0a53be !important;
  .ant-row {
    background: green;
    margin: 0 !important;
  }
`;

const AlertBanner = styled.div`
  background: ${COLORS.Yellow50};
  border-radius: 4px;
  border: 1px solid ${COLORS.Yellow500};
  color: ${COLORS.Neutral900};
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 30px;
  padding: 18px;
  display: flex;
`;

const AlertTitle = styled.span`
  color: ${COLORS.Neutral900};
  font-weight: 500;
`;

const AlertParagraph = styled.span`
  color: ${COLORS.Neutral700};
`;

export interface IPreApprovalDrawerProps {
  activeTab: ApprovalStatus | AdminScreenApprovalStatus;
  approvalId: number;
  confirmLoading?: boolean;
  selectedCostCenter?: string;
  disableUpdateApprovalStatusButtons: boolean;
  formRef: RefObject<FormInstance>;
  getApprovalDetailsQuery: UseQueryResult<ApprovalRequestDetailsVM>;
  isOverride?: boolean;
  isReadonly: boolean;
  itemType?: ApprovalsDiscriminator;
  licenseId?: number;
  onCancel: () => void;
  onOk: () => void;
  requestData: ApprovalRequestDetailsVM;
  requestLoading: boolean;
  requestSuccess: boolean;
  selectedApproval?: UserRequestApprovalVM | ResourceApprovalRequest | null;
  setConfirmLoading: (confirmLoading: boolean) => void;
  updateCostCenter?: (
    costCenter: string,
    userRequestApprovalId: number,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  updateFundsExpiration?: (options: any, extraOptions?: any) => any;
  userRequestType?: string;
  visible: boolean;
  handleApprovalRequest: (payload: UpdateApprovalRequest) => Promise<void>;
}

export const PreApprovalDrawer = ({
  activeTab,
  approvalId,
  confirmLoading,
  disableUpdateApprovalStatusButtons,
  formRef,
  getApprovalDetailsQuery,
  isOverride,
  isReadonly,
  itemType,
  licenseId,
  onCancel,
  onOk,
  requestData,
  requestLoading,
  requestSuccess,
  selectedApproval,
  selectedCostCenter,
  setConfirmLoading,
  updateCostCenter,
  updateFundsExpiration,
  userRequestType,
  visible,
  handleApprovalRequest,
}: IPreApprovalDrawerProps) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isCostCenterOn = isFeatureFlagOn.CostCenter;
  const isUnspentFundsOn = isFeatureFlagOn.UnspentFunds;
  const isTranslatePlbFieldsOn = isFeatureFlagOn.TranslatePlbFields;

  const [isRequiredNotes, setIsRequiredNotes] = useState(false);

  const getUserQuery = useGetUserQuery();

  const {data: costCenters} = useGetCostCentersVMQuery(null, {
    enabled: isCostCenterOn,
  });
  const costCenterOptions = useGetCostCenterOptions(costCenters);

  const licenseRq = getLicenseRq(licenseId);
  const isLicense = !!licenseId;
  const getLicenseQuery = useQuery<AddUpdateLicenseVM>(
    licenseRq.queryKey,
    () => simpleQueryFn(licenseRq.path),
    {enabled: !!isLicense}
  );

  const isSubscription =
    requestData?.requestedApproval?.applicationDetails?.application?.find(
      (q: PreApprovalQuestion) => isQuestionASubscription(q)
    );

  const showUnspentFunds =
    !isSubscription &&
    !isLicense &&
    isUnspentFundsOn &&
    FinanceType.Prepayment === getUserQuery.data?.plbType &&
    requestData?.requestedApproval?.status !== ApprovalStatus.Rejected;

  const showCostCenter = isCostCenterOn && costCenters?.length > 0;
  const parentApproval = requestData?.parentApproval;
  const requestedApproval = requestData?.requestedApproval;
  const originalRequest =
    parentApproval || requestedApproval || selectedApproval;
  const additionalRequest = parentApproval ? requestedApproval : null;

  const originalApprovalExpirationDay =
    requestedApproval?.approvalExpirationDate
      ? dateToUTCMoment(
          moment(requestedApproval?.approvalExpirationDate)
        ).date()
      : null;

  const isResource = (type) =>
    type === ApprovalsDiscriminator.LearningResource ||
    originalRequest?.type === ApprovalType.RESOURCE;
  const title = userRequestType
    ? `${i18n.t(k.REVIEW__DETAIL__PLURAL)} (${userRequestType})`
    : i18n.t(k.REVIEW__DETAIL__PLURAL);
  const approvalGuidelines =
    licenseId && getLicenseQuery.data?.approvalGuideline
      ? getLicenseQuery.data?.approvalGuideline
      : getPlbL10nIfAvailable({
          nonLocalized: requestedApproval?.approvalGuidelines,
          key: 'plbApprovalGuidelines',
          locale: getUsersLocale(),
          flagIsOn: isTranslatePlbFieldsOn,
        });

  const handleUpdateFundsExpiration = (
    expirationDate: Date,
    userRequestId: number,
    onSuccess: () => void
  ) => {
    const strippedExpirationDate = moment(expirationDate)
      .startOf('day')
      .utc(true)
      .toDate();
    updateFundsExpiration(
      {
        payload: {
          expirationDate: strippedExpirationDate,
          userRequestId,
        },
      },
      {
        onSuccess: () => onSuccess(),
      }
    );
  };

  const handleForm = (approvalStatus: ApprovalStatus) => {
    return async () => {
      setConfirmLoading(true);
      setIsRequiredNotes(false);
      try {
        const formData = formRef.current.getFieldsValue();
        await formRef.current.validateFields();

        const mutateApproval = () =>
          handleApprovalRequest({
            status: approvalStatus,
            type: getApprovalDetailsQuery.data.requestedApproval?.type,
            costCenter: formData.costCenter,
            id: approvalId,
            notes: formData.approverNotes,
          });

        const changedExpiry =
          !!formData.fundsExpireOn &&
          originalApprovalExpirationDay !==
            moment(formData.fundsExpireOn).date();
        if (
          approvalStatus === ApprovalStatus.Rejected &&
          !formRef.current?.getFieldValue('approverNotes')
        ) {
          setIsRequiredNotes(true);
          formRef.current.validateFields(['approverNotes']);
        } else if (showUnspentFunds && changedExpiry) {
          handleUpdateFundsExpiration(
            formData.fundsExpireOn,
            getApprovalDetailsQuery.data.requestedApproval?.applicationDetails
              ?.id,
            mutateApproval
          );
        } else {
          mutateApproval();
        }
      } catch (error) {
        console.warn('Error:', error);
      } finally {
        setConfirmLoading(false);
      }
    };
  };

  const footer = (() => {
    const readOnly =
      (!isOverride &&
        activeTab == AdminScreenApprovalStatus.PendingManagerApproval) ||
      isReadonly;

    const editable = renderFooter({
      onClose: handleForm(ApprovalStatus.Rejected),
      onOk: handleForm(ApprovalStatus.Approved),
      loading: disableUpdateApprovalStatusButtons,
      cancelText: i18n.t(k.CTA__DECLINE),
      submitText: i18n.t(k.CTA__APPROVE),
      disableButton: !requestData?.canBeApproved,
    });

    const readyOnlyFooter = (
      <LearnInButton
        tag={ButtonTags.DrawerFooterSecondary}
        loading={confirmLoading}
        onClick={onCancel}>
        {i18n.t(k.CTA__CLOSE)}
      </LearnInButton>
    );

    const fundsExpirationFooter = renderFooter({
      onClose: onCancel,
      onOk: () => {
        const data = formRef.current.getFieldsValue();
        const changedExpiry =
          data.fundsExpireOn &&
          originalApprovalExpirationDay !== moment(data.fundsExpireOn).date();

        if (changedExpiry) {
          handleUpdateFundsExpiration(
            data.fundsExpireOn,
            getApprovalDetailsQuery.data.requestedApproval?.applicationDetails
              ?.id,
            onCancel
          );
        } else {
          onCancel();
        }
      },
      loading: disableUpdateApprovalStatusButtons,
      cancelText: i18n.t(k.CTA__CLOSE),
      submitText: i18n.t(k.CTA__SAVE),
    });

    if (readOnly && showUnspentFunds) return fundsExpirationFooter;
    if (readOnly) return readyOnlyFooter;
    return editable;
  })();
  return (
    <LearnInDrawer
      height={additionalRequest ? '100%' : '600px'}
      title={title}
      closeButtonPlacement={'right'}
      onClose={onCancel}
      visible={visible}
      footer={footer}>
      <Row className={'admin-manager-pre-approval-drawer-content-layout'}>
        {requestData ? (
          !requestData?.canBeApproved ? (
            <AlertBanner>
              <img
                src={Speaker}
                alt="speaker"
                height="72px"
                width="86px"
                style={{marginRight: '20px'}}
              />
              <div>
                <AlertTitle>
                  {i18n.t(k.APPROVAL__NO_MORE_VOUCHERS_TITLE)}
                </AlertTitle>
                <br />
                <AlertParagraph>
                  {i18n.t(k.APPROVAL__NO_MORE_VOUCHERS_SUBTITLE)}
                </AlertParagraph>
              </div>
            </AlertBanner>
          ) : (
            <Col xs={24} md={24} className="pre-approval-drawer-paragraph">
              <Row>
                <StyledQuillHtmlViewer
                  html={approvalGuidelines}
                  placeholder={i18n.t(k.APPROVAL__REVIEW__DESCRIPTION)}
                />
              </Row>
            </Col>
          )
        ) : (
          ''
        )}

        <Divider />
        <Col xs={24} md={24} className="pre-approval-drawer-form-layout">
          <AdditionalRequestForm
            activeTab={activeTab}
            additionalRequest={additionalRequest}
            selectedCostCenter={selectedCostCenter}
            costCenterOptions={costCenterOptions}
            formRef={formRef}
            isNoteRequired={isRequiredNotes}
            isResource={isResource}
            licenseId={licenseId}
            originalRequest={originalRequest}
            requestData={requestData}
            requestLoading={requestLoading}
            requestSuccess={requestSuccess}
            selectedApproval={selectedApproval}
            showCostCenter={showCostCenter}
            showUnspentFunds={showUnspentFunds}
            updateCostCenter={updateCostCenter}
            isReadonly={isReadonly}
            approvalExpiry={requestedApproval?.approvalExpirationDate}
          />
          <OriginalRequestForm
            activeTab={activeTab}
            selectedCostCenter={selectedCostCenter}
            costCenterOptions={costCenterOptions}
            formRef={formRef}
            isNoteRequired={isRequiredNotes}
            isReadonly={isReadonly}
            isResource={isResource}
            itemType={itemType}
            originalRequest={originalRequest}
            requestData={requestData}
            requestLoading={requestLoading}
            requestSuccess={requestSuccess}
            selectedApproval={selectedApproval}
            showCostCenter={showCostCenter}
            showUnspentFunds={!additionalRequest && showUnspentFunds}
            updateCostCenter={updateCostCenter}
            approvalExpiry={requestedApproval?.approvalExpirationDate}
          />
        </Col>
      </Row>
    </LearnInDrawer>
  );
};
