export const getFullUrl = (
  path: string,
  serverQueryParams: {[key: string]: string | number} = null,
  host?: string
) => {
  host = host ?? window.location.host;
  const isLocalHost = (host: string) => host.includes('localhost');
  const baseDirectory = isLocalHost(host) ? '' : '/develop';

  // Stringify each server query param
  let serverQueryString = '';
  let i = 0;
  for (const x in serverQueryParams) {
    const appendWith = i === 0 ? '?' : '&';
    const queryParam = serverQueryParams[x];
    serverQueryString += `${appendWith}${x}=${queryParam}`;
    i++;
  }

  return `https://${host}${baseDirectory}${serverQueryString}#${path}`;
};
