import {useMemo, useState} from 'react';
import {api} from '@store/api';
import {useMutation} from 'react-query';
import {simpleMutationFn, simpleDeleteFn} from '@store/queryClient';
import {useParams} from 'react-router-dom';
import {AddedEmployees} from '@components/reusable/RestrictedAccess/AddedEmployees/AddedEmployees';
import {getCompanyUsers} from '@utils/ClientPaths';
import {
  getAddAcademiesUsersRm,
  getRemoveAcademiesUserRm,
} from '@store/apiEndpoints/academy/mutations';
import {useUserQuery, useAcademyUsersQuery} from '@generated/hooks';
import {i18n, k} from '@i18n/translate';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {
  stringSorter,
  dateTimeSorter,
  renderDateTime,
  TooltipCol,
} from '@components/reusable/LearnInTable';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import useFeatureFlags from '@hooks/useFeatureFlags';
interface Props {
  restrictedAccess: boolean;
}

interface CompanyUsersForAdding {
  label: string;
  value: string;
  email: string;
  canShowEmployeeEmail: boolean;
}

export const AddedEmployeesContainer = ({restrictedAccess}: Props) => {
  const [selectedUsersForAdding, setCompanyUsersForAdding] = useState<
    CompanyUsersForAdding[]
  >([]);
  const params = useParams();
  const {data: user} = useUserQuery(null);
  const {isFeatureFlagOn} = useFeatureFlags();

  const {data: academyUsers, invalidateExact: invalidateAcademyUsers} =
    useAcademyUsersQuery({
      academyId: params.academyId,
    });

  const updateAcademyUsersMutation = useMutation(
    (args: {academyId: number; userCompanyIds: number[]}) => {
      const updateAcademyUsers = getAddAcademiesUsersRm(args);
      return simpleMutationFn<null>(
        updateAcademyUsers.path,
        updateAcademyUsers.payload
      );
    },
    {
      onSuccess: async (id) => {
        setCompanyUsersForAdding([]);
        invalidateAcademyUsers();
      },
      onError: async (e) => {
        console.warn('error', e);
      },
    }
  );

  const handleClickAddRecommendedUsers = async () => {
    await updateAcademyUsersMutation.mutateAsync({
      academyId: parseInt(params.academyId),
      userCompanyIds: selectedUsersForAdding.map((user) =>
        parseInt(user.value)
      ),
    });
    setCompanyUsersForAdding([]);
  };

  const removeUserAcademyAccessMutation = useMutation(
    (userId: number) => {
      const removeAcademyUsersMutation = getRemoveAcademiesUserRm(
        parseInt(params.academyId),
        userId
      );
      return simpleDeleteFn<null>(
        removeAcademyUsersMutation.path,
        removeAcademyUsersMutation.payload
      );
    },
    {
      onSuccess: async () => {
        await invalidateAcademyUsers();
      },
    }
  );
  const handleRemoveUser = (userId: number) => {
    removeUserAcademyAccessMutation.mutateAsync(userId);
  };

  const academyUserAddedEmployeesColumns = useMemo(() => {
    return [
      isFeatureFlagOn.ShowEmployeeEmail
        ? TooltipCol({
            dataIndex: 'name',
            title: i18n.t(k.EMPLOYEE__NAME),
            tooltipDataIndex: 'email',
            width: 350,
          })
        : {
            title: i18n.t(k.EMPLOYEE__NAME),
            dataIndex: 'name',
            key: 'name',
            sorter: stringSorter<any>('name'),
          },
      {
        title: i18n.t(k.DATE__ADDED),
        dataIndex: 'dateAdded',
        key: 'dateAdded',
        render: renderDateTime(),
        sorter: dateTimeSorter('dateAdded'),
      },
      {
        title: i18n.t(k.GENERIC__ACTION),
        dataIndex: 'status',
        key: 'status',
        render: (_, {userCompanyId}) => (
          <LearnInButton
            tag={ButtonTags.Footer}
            onClick={() => {
              handleRemoveUser(userCompanyId);
            }}>
            {i18n.t(k.CTA__REMOVE)}
          </LearnInButton>
        ),
      },
    ];
  }, []);

  const loadOptions = async (name, done) => {
    try {
      const response = await api.get(getCompanyUsers(user?.companyId, {name}));
      const options = response.data
        .filter((item) => {
          return academyUsers?.every(
            (recommendedUser) => recommendedUser.email !== item.email
          );
        })
        .map((item) => ({
          label: item.name,
          value: item.id,
          email: item.email,
          canShowEmployeeEmail: isFeatureFlagOn.ShowEmployeeEmail,
        }));

      done(options);
    } catch (error) {
      console.error('Error fetching data:', error);
      done([]);
    }
  };

  return (
    <>
      {restrictedAccess && (
        <AddedEmployees
          loadOptions={loadOptions}
          selectedUsersForAdding={selectedUsersForAdding}
          onCompanyUserSearchChange={setCompanyUsersForAdding}
          recommendedUsers={academyUsers || []}
          onClickAddRecommendedUsers={handleClickAddRecommendedUsers}
          removeUser={handleRemoveUser}
          customColumnsConfig={academyUserAddedEmployeesColumns}
        />
      )}
    </>
  );
};
