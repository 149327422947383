import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Card, Col, Divider, List, Row, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import {UserProgressStatus} from '../../store/interface';
import moment from 'moment';
import PreApprovalDrawer from '../../../components/preApprovalDrawer/PreApprovalDrawer.container';
import {
  PlanItemTypeWithCustomProgram,
  UserPlanCardActions,
} from '@models/clientEnums';
import {UserPlanProgramItemVM} from '@models/serverModels';
import useTreatment from '@hooks/useTreatment';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {UserPlanCardDataHeader as UserPlanProgramStatusCardDataHeader} from '@blocks/programStatusCard/ProgramStatusCard';
import {UserPlanCardDataHeader} from '@blocks/userPlanCard/UserPlanCard';
import ActionButton from '@blocks/actionButton/ActionButton';
import {shouldShowExpandedCardView} from '@blocks/userPlanCardActionMenu/UserPlanCardActionMenu';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {FinanceType, PlanItemType} from '@generated/enums';

import LicenseViewerModal from '@blocks/licenseViewerModal/LicenseViewerModal.container';
import {DATE_FORMAT} from '@utils/timeUtils';
import ReimbursementDrawerContainer from '@blocks/reimbursementDrawer/ReimbursementDrawer.container';
import {useUserPlanFinanceLearningBudgetOverviewVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {pxToRem} from '@utils/styleMixins';

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface Props {
  showActions: boolean;
  item: UserPlanProgramItemVM;
  handleSettingsActions: (action: UserPlanCardActions) => void;
}

const getItemStatusTag = (status: string): any => {
  let tagStyle;
  let tagText = '';
  switch (status) {
    case UserProgressStatus.UPCOMING:
      tagStyle = TagStyles.LightGrey;
      tagText = i18n.t(k.DATE__UPCOMING).toLocaleUpperCase();
      break;
    case UserProgressStatus.DUE:
      tagStyle = TagStyles.Yellow;
      tagText = i18n.t(k.DATE__DUE).toLocaleUpperCase();
      break;
    case UserProgressStatus.LATE:
      tagStyle = TagStyles.Red;
      tagText = i18n.t(k.DATE__LATE).toLocaleUpperCase();
      break;
    case UserProgressStatus.SUBMITTED:
      tagStyle = TagStyles.Green;
      tagText = i18n.t(k.STATUS__SUBMITTED).toLocaleUpperCase();
      break;
  }

  return <LearnInTag label={tagText} tagStyle={tagStyle} />;
};

const UserPlanProgramCard = ({
  showActions,
  item,
  handleSettingsActions,
}: Props) => {
  const navigate = useNavigate();
  // note, because this flag turns off the feature this is a bit of a double negative
  const areProgressUpdatesEnabled =
    useTreatment(FeatureFlagExperiments.DisableProgressUpdates) ===
    FeatureFlagTreatments.Off;
  const [showPreApprovalDrawer, setShowPreApprovalDrawer] = useState(false);
  const [showViewLicenseModal, setShowViewLicenseModal] = useState(false);
  const [showRequestLicenseDrawer, setShowRequestLicenseDrawer] =
    useState(false);
  const [showReimbursementDrawer, setShowReimbursementDrawer] = useState(false);
  const getLearningBudgetInfoQuery =
    useUserPlanFinanceLearningBudgetOverviewVMQuery();
  const isReimbursementPlan =
    getLearningBudgetInfoQuery.data?.financeType ===
    FinanceType.TuitionReimbursement;
  return (
    <article
      style={{marginBottom: pxToRem(16, 16)}}
      aria-label={`${i18n.t(k.PROGRAM)}: ${item.title}`}
      data-cy={`program-${item.id}`}>
      <Row align="middle" justify="center">
        <Col xs={24}>
          {showPreApprovalDrawer && (
            <PreApprovalDrawer
              visible={showPreApprovalDrawer}
              planItemId={item.id}
              itemId={item.programId}
              planItemType={item.itemType}
              status={item.status}
              currency={item.currency}
              onOk={() => {
                setShowPreApprovalDrawer(false);
              }}
              onCancel={() => {
                setShowPreApprovalDrawer(false);
              }}
            />
          )}
          {showViewLicenseModal && (
            <LicenseViewerModal
              provider={item.provider}
              licenseId={item.licenseId}
              programId={item.programId}
              onClose={() => {
                setShowViewLicenseModal(false);
              }}
            />
          )}
          {showRequestLicenseDrawer && (
            <PreApprovalDrawer
              itemId={item.programId}
              planItemType={PlanItemTypeWithCustomProgram.Program}
              licenseId={item.licenseId}
              hasLicense={item.isLicenseProgram}
              status={item.status}
              currency={item.currency}
              visible={showRequestLicenseDrawer}
              onOk={() => {
                setShowRequestLicenseDrawer(false);
              }}
              onCancel={() => {
                setShowRequestLicenseDrawer(false);
              }}
            />
          )}
          {showReimbursementDrawer && (
            <ReimbursementDrawerContainer
              isOpen={showReimbursementDrawer}
              setIsOpen={setShowReimbursementDrawer}
              selectedRequestItem={item.id}
            />
          )}
        </Col>
      </Row>
      <Card
        role="article"
        aria-labelledby="user-plan-time-card-title"
        key={item.id}
        className="user-plan-item-full-card"
        id={`user-plan-program-card-${item.id}`}>
        {item.marketplaceProgram ? (
          <UserPlanCardDataHeader
            item={{
              ...item,
              ...{itemType: PlanItemType.Program},
            }}
            planItemTypeWithCustomProgram={PlanItemType.Program}
            showActions={showActions}
            handleSettingsActions={handleSettingsActions}
            handleShowPreApprovalDrawer={() => setShowPreApprovalDrawer(true)}
            handleShowRequestLicenseDrawer={() =>
              setShowRequestLicenseDrawer(true)
            }
            handleShowViewLicenseModal={() => setShowViewLicenseModal(true)}
            handleShowReimbursementModal={() =>
              setShowReimbursementDrawer(true)
            }
            isReimbursementPlan={isReimbursementPlan}
          />
        ) : (
          <UserPlanProgramStatusCardDataHeader
            item={{
              ...item,
              ...{itemType: PlanItemType.Program},
            }}
            planItemTypeWithCustomProgram={
              PlanItemTypeWithCustomProgram.CustomProgram
            }
            showActions={showActions}
            handleSettingsActions={handleSettingsActions}
            handleShowPreApprovalDrawer={() => setShowPreApprovalDrawer(true)}
          />
        )}

        {/* Expanded View */}
        {shouldShowExpandedCardView(
          PlanItemTypeWithCustomProgram.Program,
          item.status
        ) &&
          areProgressUpdatesEnabled && (
            <Row
              align="middle"
              style={{margin: '0 0 12px 0', alignItems: 'end'}}
              role="button"
              tabIndex={0}
              onClick={() => {
                navigate(`/user/manage/program/${item.id}`);
              }}>
              <Divider style={{margin: '0 0 24px 0'}} />
              <Col sm={19}>
                <Typography.Text
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    lineHeight: '1.1875rem',
                  }}>
                  {i18n.t(k.PROGRESS__UPDATES)}
                </Typography.Text>
              </Col>
              <Col sm={5} style={{height: '50px'}}>
                <ActionButton
                  item={{
                    ...item,
                    itemType: item?.itemType,
                    programApprovalRequired: item?.approvalRequired,
                  }}
                  itemType={PlanItemTypeWithCustomProgram.Program}
                  handleShowPreApprovalDrawer={setShowPreApprovalDrawer}
                  handleShowRequestLicenseDrawer={setShowRequestLicenseDrawer}
                  handleShowViewLicenseModal={setShowViewLicenseModal}
                  onSettings={handleSettingsActions}
                  buttonLabel={i18n.t(k.CTA__VIEW__DETAIL__PLURAL)}
                />
              </Col>

              {item.progressUpdates?.length ? (
                <>
                  <List
                    itemLayout="horizontal"
                    style={{width: '100%', marginTop: '10px'}}
                    dataSource={item.progressUpdates}
                    renderItem={(item: any) => (
                      <List.Item>
                        <Row style={{width: '100%'}}>
                          <Col xs={4}>
                            <Row>
                              <Typography.Text style={{fontSize: '1rem'}}>
                                {moment(item.date).format(
                                  DATE_FORMAT.MONTH_DAY_YEAR_PADDED
                                )}
                              </Typography.Text>
                            </Row>
                          </Col>
                          <Col xs={12}>
                            <Row>
                              <Typography.Text
                                style={{fontSize: '1rem'}}
                                ellipsis>
                                {item.title}
                              </Typography.Text>
                            </Row>
                          </Col>
                          <Col xs={4}>
                            <Row>
                              <Typography.Text style={{fontSize: '1rem'}}>
                                {i18n.t(k.DATE__WEEK)} {item.week}
                              </Typography.Text>
                            </Row>
                          </Col>
                          <Col xs={4}>
                            <Row justify="end">
                              {getItemStatusTag(item.status)}
                            </Row>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </>
              ) : (
                <>
                  <Col sm={24}>
                    <Typography.Paragraph
                      style={{
                        fontWeight: 'normal',
                        fontSize: '1.25rem',
                        lineHeight: '150%',
                        color: '#9B9B9B',
                      }}>
                      {i18n.t(k.PROGRAM__NO_SUBMITTED_PROGRESS)}
                    </Typography.Paragraph>
                  </Col>
                </>
              )}
            </Row>
          )}
      </Card>
    </article>
  );
};

export default UserPlanProgramCard;
