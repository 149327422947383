import {useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import Grid from '@blocks/Grid';
import {Divider} from 'antd';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  stringSorter,
  dateTimeSorter,
  renderDateTime,
  IColumn,
} from '@components/reusable/LearnInTable';
import LearnInTable from '@components/reusable/LearnInTable';
import {ModalTitle} from '@components/admin/pages/programs/customPrograms/setup/CustomProgramSetupScreen';
import {DoubleLineOption} from '@components/reusable/Select/OptionItem.style';
import AsyncSelect from 'react-select/async';
import {COLORS} from '@utils/constants';
import {AcademyUserVM, CustomProgramUserResponse} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Option Component
|--------------------------------------------------------------------------
*/

const StyledEmployeeOption = styled.div`
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  padding: 10px;
`;

interface EmployeeOptionProps {
  data: CompanyUsersForAdding;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getStyles: Function;
  isFocused: boolean;
  innerProps: any;
}

const EmployeeOption = (props: EmployeeOptionProps) => {
  const {email, label: name, canShowEmployeeEmail, value} = props.data;
  return (
    <StyledEmployeeOption
      style={{
        ...props.getStyles('option', props),
        backgroundColor: props.isFocused ? COLORS.Neutral200 : COLORS.White, // Highlight the focused option
      }}
      tabIndex={0}
      name={`${name} - ${email}`}
      value={`${name} - ${email}`}
      {...props.innerProps}
      key={value}>
      {canShowEmployeeEmail ? (
        <DoubleLineOption>
          {name}
          <span>{email}</span>
        </DoubleLineOption>
      ) : (
        name
      )}
    </StyledEmployeeOption>
  );
};

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/

const EmployeeSearch = styled.div`
  display: flex;
  > div {
    margin-right: 12px;
    width: 100%;
  }
`;

const customAsyncSelectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Adjust the z-index value as needed
  }),
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface CompanyUsersForAdding {
  label: string;
  value: string;
  email: string;
  canShowEmployeeEmail: boolean;
}

export interface Props {
  recommendedUsers: AcademyUserVM[] | CustomProgramUserResponse[];
  customColumnsConfig?: IColumn<unknown>[];
  // For React-Select, which requires the inadvisable 'Function' type
  // eslint-disable-next-line @typescript-eslint/ban-types
  loadOptions: (inputValue: string, callback: Function) => void;
  onClickAddRecommendedUsers: () => void;
  onCompanyUserSearchChange: (
    selectedUsersForAdding: CompanyUsersForAdding[]
  ) => void;
  removeUser: (userCompanyId: number, customProgramId: number) => void;
  selectedUsersForAdding: CompanyUsersForAdding[];
}

export const AddedEmployees = ({
  customColumnsConfig,
  loadOptions,
  onClickAddRecommendedUsers,
  onCompanyUserSearchChange,
  recommendedUsers,
  removeUser,
  selectedUsersForAdding,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const defaultPermittedUserTableCols = useMemo(() => {
    return [
      {
        title: i18n.t(k.EMPLOYEE__NAME),
        dataIndex: 'userName',
        key: 'userName',
        sorter: stringSorter<any>('userName'),
      },
      {
        title: i18n.t(k.DATE__ADDED),
        dataIndex: 'userPlanProgramItemCreatedOn',
        key: 'userPlanProgramItemCreatedOn',
        render: renderDateTime(),
        sorter: dateTimeSorter('userPlanProgramItemCreatedOn'),
      },
      {
        title: i18n.t(k.GENERIC__ACTION),
        dataIndex: 'status',
        key: 'status',
        render: (_: any, {userCompanyId, customProgramId}) => (
          <LearnInButton
            tag={ButtonTags.Footer}
            onClick={() => {
              removeUser(userCompanyId, customProgramId);
            }}>
            {i18n.t(k.CTA__REMOVE)}
          </LearnInButton>
        ),
      },
    ];
  }, [recommendedUsers]);

  return (
    <Grid.Container>
      <Divider />
      <>
        <Grid.Box half>
          <ModalTitle>{i18n.t(k.EMPLOYEE__PLURAL__ADDED)}</ModalTitle>
        </Grid.Box>
        <Grid.Box half>
          <EmployeeSearch>
            <AsyncSelect
              cacheOptions
              components={{
                Option: EmployeeOption,
              }}
              isMulti
              value={selectedUsersForAdding}
              loadOptions={loadOptions}
              name="Employee Search"
              placeholder={i18n.t(k.EMPLOYEE__SEARCH_TO_ADD)}
              styles={customAsyncSelectStyles}
              inputValue={inputValue}
              onInputChange={setInputValue}
              noOptionsMessage={({inputValue}) => {
                return inputValue
                  ? i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)
                  : i18n.t(k.EMPLOYEE__SEARCH_TO_ADD);
              }}
              onChange={onCompanyUserSearchChange}
            />
            <span>
              <LearnInButton
                tabIndex={0}
                onClick={onClickAddRecommendedUsers}
                disabled={!selectedUsersForAdding?.length}
                tag={ButtonTags.Secondary}>
                {i18n.t(k.GENERIC__ADD)}
              </LearnInButton>
            </span>
          </EmployeeSearch>
        </Grid.Box>
        <Grid.Box full>
          <LearnInTable
            dataSource={recommendedUsers}
            columns={customColumnsConfig || defaultPermittedUserTableCols}
            noDataStyle={{height: 'auto'}}
          />
        </Grid.Box>
      </>
    </Grid.Container>
  );
};
