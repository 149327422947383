import {useRef, useCallback, useEffect, useState} from 'react';
import RequestResourceApprovalDrawer from './RequestResourceApprovalDrawer';
import {useMutation, useQuery} from 'react-query';
import {
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import {
  getManagerUserRequestApprovalsRq,
  getRequestResourceApprovalRm,
  getResourceApprovalApplicationRq,
  getResourceApprovalApplicationsRq,
  getUserPlanResourcesRq,
} from '@store/apiEndpoints';
import {
  RequestResourceApprovalPayload,
  ResourceApprovalApplicationVM,
  ResourceRerequestApprovalApplicationVM,
} from '@models/serverModels';
import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  LearningResourceType,
} from '@generated/enums';
import {notify} from '@components/user/notifications';
import {formatResponseValue} from '@components/reusable/LearnInForm';
import {Form, FormInstance} from 'antd';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import {
  getExchangeRateBySelection,
  MONEY_DEFAULT,
  REQUEST_CURRENCY_ID,
} from '@utils/moneyUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  isQuestionACurrencyField,
  isQuestionASubscription,
  skipResponseFormatting,
} from '@utils/preApprovalRequest';
import {useGetRequestTypeOptions} from '../request/RequestMenuButtonAndDrawer/RequestMenuButtonAndDrawer.hooks';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface RequestResourceApprovalDrawerContainerProps {
  formInstance?: FormInstance;
  visible?: boolean;
  onCloseSuccess?: () => void;
  redesignsOnSubmit?: () => void;
  refreshParentData?: () => void;
  userPlanResourceId?: number;
  presetResourceType?: LearningResourceType;
  rerequestResourceType?: LearningResourceType;
  setIsSubmitButtonLoading?: (isLoading: boolean) => void;
  isSubmitButtonLoading?: boolean;
  renderFooter?: (handleClickSubmit: () => void) => React.ReactNode;
}

function RequestResourceApprovalDrawerContainer({
  formInstance,
  visible,
  onCloseSuccess,
  refreshParentData,
  userPlanResourceId,
  rerequestResourceType,
  presetResourceType,
  setIsSubmitButtonLoading,
  isSubmitButtonLoading,
  renderFooter,
}: RequestResourceApprovalDrawerContainerProps) {
  const [total, setTotal] = useState(null);
  const [resourceType, setResourceType] = useState<
    LearningResourceType | undefined
  >(presetResourceType);
  const [formQuestions, setFormQuestions] = useState([]);
  const {isFeatureFlagOn} = useFeatureFlags();
  const isRequestWithCurrencyOn = isFeatureFlagOn.RequestWithCurrency;
  const isHideHeaderOnResubmitOn = isFeatureFlagOn.HideHeaderOnResubmit;
  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: isRequestWithCurrencyOn,
  });
  const exchangeRatesList = getCurrencyExchangeQuery?.data;
  const [oldForm] = Form.useForm();
  const form = formInstance ?? oldForm;
  const [application, setApplication] = useState([]);

  useEffect(() => {
    if (!isNaN(resourceType)) {
      const filteredData = !!userPlanResourceId
        ? application
        : application?.find(
            (config) => String(resourceType) === String(config.resourceType)
          )?.questions;
      setFormQuestions(filteredData);
    }
  }, [application, resourceType, userPlanResourceId]);

  useEffect(() => form.resetFields(), [form, visible]);

  useEffect(() => {
    if (rerequestResourceType !== undefined) {
      setResourceType(rerequestResourceType);
    }
  }, [rerequestResourceType]);

  // This query gets the data for an application for a new approval request
  const resourceApprovalApplicationsRq = getResourceApprovalApplicationsRq();
  const resourceApprovalApplicationsQuery = useQuery<
    ResourceApprovalApplicationVM[]
  >(
    resourceApprovalApplicationsRq.queryKey,
    () => simpleQueryFn(resourceApprovalApplicationsRq.path),
    {
      enabled: visible && !userPlanResourceId,
      onError: () => notify.FetchResourceWithApprovalError(),
      onSuccess: (data) => setApplication(data),
    }
  );

  // This query gets the application data of an existing approval request
  const resourceApprovalApplicationRq =
    getResourceApprovalApplicationRq(userPlanResourceId);
  const resourceApprovalApplicationQuery =
    useQuery<ResourceRerequestApprovalApplicationVM>(
      resourceApprovalApplicationRq.queryKey,
      () => simpleQueryFn(resourceApprovalApplicationRq.path),
      {
        enabled: !!userPlanResourceId && visible,
        onError: () => null,
        onSuccess: (data) => setApplication(data?.application),
      }
    );

  const requestResourcesApprovalMutation = useMutation(
    (args: RequestResourceApprovalPayload) => {
      const requestResourcesApproval = getRequestResourceApprovalRm(args);
      return simpleMutationFn<null>(
        requestResourcesApproval.path,
        requestResourcesApproval.payload
      );
    },
    {
      onSuccess: async () => {
        notify.AddResourceWithApprovalSuccess();
        if (!!refreshParentData) refreshParentData();
        const rq = getManagerUserRequestApprovalsRq(
          ApprovalsDiscriminator.LearningResource,
          ApprovalStatus.Requested
        );
        await simpleInvalidateExactQueryFn(rq.queryKey);
        await simpleInvalidateExactQueryFn(getUserPlanResourcesRq().queryKey);
        setResourceType(undefined);
        onCloseSuccess?.();
      },
      onError: () => notify.AddResourceWithApprovalError(),
    }
  );

  const learningResourceOptions: LearningResourceType[] =
    resourceApprovalApplicationsQuery.data?.map(
      ({resourceType}) => resourceType
    );

  const frequencyDropdown = formQuestions?.find((question) =>
    isQuestionASubscription(question)
  );
  const mutationRef = useRef(requestResourcesApprovalMutation);
  useEffect(() => {
    mutationRef.current = requestResourcesApprovalMutation;
  }, [requestResourcesApprovalMutation]);

  const handleClickSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      const formFields = form.getFieldsValue();

      // If a currency is selected, use that exchange rate
      const selectedCurrency = formFields?.[REQUEST_CURRENCY_ID];
      const requestExchangeRate = getExchangeRateBySelection(
        selectedCurrency,
        exchangeRatesList,
        exchangeRate
      );

      const monthFrequencyIndex = formQuestions?.findIndex(
        (item) => item.id === frequencyDropdown?.id
      );
      if (monthFrequencyIndex !== -1 && !formFields[frequencyDropdown?.id])
        formFields[frequencyDropdown?.id] = '1';

      const currencyQuestion = formQuestions?.find((q) =>
        isQuestionACurrencyField(q)
      );
      if (currencyQuestion) {
        currencyQuestion.response = String(selectedCurrency);
      }

      const formApp = formQuestions?.map((formQuestion) => {
        return {
          id: formQuestion.id,
          ...formQuestion,
          response: String(
            skipResponseFormatting(formQuestion)
              ? formQuestion.response
              : formatResponseValue({
                  type: formQuestion.inputType,
                  response: formFields[formQuestion.id],
                  // subscriptions require this to be `total`, not the normal `formFields[formQuestion.id] || total`
                  total,
                  exchangeRate: requestExchangeRate,
                })
          ),
        };
      });

      const postRequestBody: RequestResourceApprovalPayload = {
        resourceType,
        applicationDetails: {
          status: ApprovalStatus.Requested,
          application: formApp,
        },
      };
      if (!!userPlanResourceId) {
        postRequestBody.userPlanResourceId = userPlanResourceId;
        postRequestBody.applicationDetails.id =
          resourceApprovalApplicationQuery.data?.id;
      }
      setIsSubmitButtonLoading?.(true);
      mutationRef.current.mutate(postRequestBody, {
        onError: (error) => {
          setIsSubmitButtonLoading?.(false);
          console.error(error);
        },
      });
    } catch (error) {
      setIsSubmitButtonLoading?.(false);
      console.error(error);
    }
  }, [
    exchangeRate,
    exchangeRatesList,
    form,
    formQuestions,
    frequencyDropdown?.id,
    resourceApprovalApplicationQuery.data?.id,
    resourceType,
    setIsSubmitButtonLoading,
    total,
    userPlanResourceId,
  ]);

  const isApplicationRejected =
    resourceApprovalApplicationQuery?.data?.status === ApprovalStatus.Rejected;

  const requestResourceTypeOptions = useGetRequestTypeOptions({
    showRequestResourceOption: true,
  });

  useEffect(() => {
    if (!isNaN(presetResourceType)) setResourceType(presetResourceType);
  }, [presetResourceType]);

  return (
    <div style={{paddingBottom: '80px'}}>
      <RequestResourceApprovalDrawer
        frequencyDropdown={frequencyDropdown}
        form={form}
        formQuestions={formQuestions || []}
        isSubmitButtonLoading={
          isSubmitButtonLoading || requestResourcesApprovalMutation.isLoading
        }
        learningResourceOptions={learningResourceOptions || []}
        onChangeResourceType={setResourceType}
        onClickSubmit={handleClickSubmit}
        onClose={onCloseSuccess}
        requestResourceTypeOptions={requestResourceTypeOptions}
        rejectedNotes={resourceApprovalApplicationQuery?.data?.rejectedNotes}
        rerequestResourceType={rerequestResourceType}
        resourceType={resourceType}
        setTotal={setTotal}
        total={total}
        exchangeRate={exchangeRate}
        visible={visible}
        usersCurrency={getUserQuery.data?.currency}
        isApplicationRejected={isApplicationRejected}
        hideHeader={
          isHideHeaderOnResubmitOn &&
          resourceApprovalApplicationQuery?.data?.status ===
            ApprovalStatus.Rejected
        }
      />
      {renderFooter?.(handleClickSubmit)}
    </div>
  );
}

export default RequestResourceApprovalDrawerContainer;
